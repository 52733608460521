import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CloudFunctionName } from "../AppConstants/CloudFunctionName";
import { DocflixLoader } from "../components";
import CustomPushNotification from "../components/CustomPushNotification/CustomPushNotification";
import ExclusiveForm from "../components/Signup/partials/ExclusiveForm/ExclusiveForm";
import ExploreModal from "../components/Signup/partials/ExploreModal/ExploreModal";
import PremiumProgress from "../components/Signup/partials/ExploreModal/PreimumProgress/PremiumProgress";
import OTPForm from "../components/Signup/partials/OTPForm/OTPForm";
import PersonalDetails from "../components/Signup/partials/PersonalDetails/PersonalDetails";
import ThemeSwitch from "../components/ThemeSwitch";
import { newAuthContext } from "../contexts/AuthContext/NewAuthContextProvider";
import { UserContext } from "../contexts/UserContext/UserContextProvider";
import { AuthManager, cloudFunction_as } from "../firebase/firebase";
import { isProd, showToast } from "../utils/common.utils";
import { WEBINAR_PAGE_ROUTE } from "../AppConstants/Routes";
import styles from "./layoutStyles.module.scss";

const AppLayout = ({ children }) => {
  const { pathname, search = "", hash = "" } = useLocation();
  const { user, userBasicDetails, loading, isUserOTPVerified, userMetadata } =
    useContext(UserContext);
  const { state, toggleSignInbtn } = useContext(newAuthContext);
  const [showLoader, setShowLoader] = useState(
    sessionStorage.getItem("showLoader") === "true" ? true : false
  );

  const {
    showOTPModal,
    premiumFormModal,
    exclusiveFormModal,
    premiumFormModalComplete,
    exclusiveFormModalComplete,
    exclusiveFormModalStart,
    premiumFormModalStart,
  } = state;

  useEffect(() => {
    if (user) {
      AuthManager.onMessageListener((payload) => {
        let allData = {
          id: payload.data.msg_id,
          title: payload.data.title,
          body: payload.data.body,
          icon: payload.data.icon,
          link: payload.data.link,
          topic: payload.data.topic,
          type: payload.data.type,
          eventId: payload.data.eventId,
          canRepeat: payload.data.canRepeat === "true" ? true : false,
          date: new Date(),
          opened: false,
          displayName: user.displayName,
          uid: user.uid,
          sendFrom: "foreground",
        };
        addNotification(allData, (res) => {
          if (res) {
          }
        });
        showToast(<CustomPushNotification payload={payload.data} />, {
          type: "default",
          position: "top-right",
          autoClose: 10000,
        });
      });
    }
  }, []);

  const addNotification = async (data, cb) => {
    try {
      const addNotificationRef = cloudFunction_as.httpsCallable(
        CloudFunctionName.ADD_NOTIFICATION_TO_REALTIME_DB
      );
      let res = await addNotificationRef(JSON.stringify(data));
      cb(res);
    } catch (error) {
      console.error(error);
      cb(null);
    }
  };

  // useEffect(() => {
  //   if (userMetadata && !userMetadata.otp_verified) {
  //     if (!showOTPModal) {
  //       toggleSignInbtn("showOTPModal", true);
  //     }
  //   }
  // }, [user, userMetadata]);

  // useEffect(() => {
  //   if (loading) {
  //     return;
  //   }
  //   if (user && !isUserOTPVerified) {
  //     if (!showOTPModal) {
  //       toggleSignInbtn("showOTPModal", true);
  //     }
  //   }
  // }, [user, loading]);

  return (
    <div>
      {/* <div className={styles.themeSwitch}>
        <ThemeSwitch />
      </div> */}
      {/* {isProd && !showLoader && <DocflixLoader />} */}

      {/* {showOTPModal && !pathname.startsWith(WEBINAR_PAGE_ROUTE) ? (
        <div className={`modalOverlayWithBlur ${styles["OTPContainer"]}`}>
          {userBasicDetails && (
            <div className={styles.box}>
              <OTPForm data={{ phoneNumber: userBasicDetails.phoneNumber }} />
            </div>
          )}
        </div>
      ) : ( */}
      <div>{children}</div>
      {/* )} */}
      {premiumFormModal && (
        <div className={`modalOverlayWithBlur ${styles["OTPContainer"]}`}>
          <div className={styles.box}>
            <PremiumProgress />
            <PersonalDetails />
          </div>
        </div>
      )}
      {exclusiveFormModal && (
        <div className={`modalOverlayWithBlur ${styles["OTPContainer"]}`}>
          <div className={styles.box}>
            <PremiumProgress />
            <ExclusiveForm />
          </div>
        </div>
      )}

      {(premiumFormModalStart ||
        premiumFormModalComplete ||
        exclusiveFormModalStart ||
        exclusiveFormModalComplete) && <ExploreModal />}
    </div>
  );
};

export default AppLayout;

import { createContext, useContext, useEffect, useReducer } from "react";
import { CloudFunctionName } from "../../AppConstants/CloudFunctionName";
import { instituteValues } from "../../AppConstants/Constants";
import { FirebaseErrorCodes } from "../../AppConstants/FirebaseErrorCodes";
import { ExclusiveType } from "../../AppConstants/TypeConstants";
import { AuthManager, cloudFunction_as } from "../../firebase/firebase";
import UserManager from "../../Managers/Users";
import { resetUserLoginHook } from "../../utils/UserLoginHook";
import { UserContext } from "../UserContext/UserContextProvider";

export const newAuthContext = createContext();

const DefaultFormDetails = {
  firstName: "",
  lastName: "",
  email: "",
  profession: "",
  speciality: "",
  areaOfInterest: [],
  city: {
    state: "",
    city: "",
    country: "",
    pincode: null,
    location: {
      lat: 20.5937,
      lng: 78.9629,
    },
  },
  phoneNumber: "",
  year: "",
  institute: "",
  regId: "",
  terms: false,
  marketing: true,
};

const initialState = {
  showSignInModal: false,
  showJoinModal: false,
  showSignUpModal: false,
  showOTPModal: false,
  showProfile: true,
  showOTPScreen: false,
  showPersonalDetailsScreen: false,
  premiumFormModalStart: false,
  premiumFormModal: false,
  premiumFormModalComplete: false,
  exclusiveFormModalStart: false,
  exclusiveFormModal: false,
  exclusiveFormModalComplete: false,
  loading: false,
  alreadyRegistered: false,
  formDetails: DefaultFormDetails,
  error: false,
  errors: false,
  isRegistered: false,
  isUserVerified: false,
};

const Action_Type = {
  handleToggleForm: "handleToggleForm",
  handleFormDetails: "handleFormDetails",
  handleInputChange: "handleInputChange",
  updateMultipleState: "updateMultipleState",
  handleInitialInput: "handleInitialInput",
  handleSignUpBtn: "handleSignUpBtn",
  handleError: "handleError",
  loading: "loading",
};

const reducer = (state, action) => {
  let { type, payload } = action;

  switch (type) {
    case Action_Type.handleInitialInput:
    case Action_Type.handleToggleForm:
    case Action_Type.handleError:
    case Action_Type.loading:
    case Action_Type.updateMultipleState:
    case Action_Type.handleSignUpBtn:
      return {
        ...state,
        ...payload,
      };
    case Action_Type.handleFormDetails:
      return {
        ...state,
        formDetails: {
          ...state.formDetails,
          ...payload.formDetails,
          error: false,
        },
      };
    case Action_Type.handleInputChange:
      return { ...state, ...payload, [payload.name]: payload.value };
    default:
      return { ...state };
  }
};

export default function NewAuthContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useContext(UserContext);

  const toggleSignInbtn = (type, bool) => {
    let obj = {
      [type]: bool,
    };
    if (type === "showSignInModal" && !bool) {
      // to reset userLoginHooks if any attached
      let formDetails = {
        phoneNumber: "",
      };
      Object.assign(obj, { formDetails: formDetails });

      resetUserLoginHook();
    }
    dispatchData(Action_Type.handleToggleForm, obj);
  };
  const handleLoading = (bool) => {
    let obj = {
      loading: bool,
    };
    dispatchData(Action_Type.loading, obj);
  };
  const handleFormDetails = (data) => {
    let obj = {
      formDetails: { ...data },
    };
    dispatchData(Action_Type.handleFormDetails, obj);
  };
  const updateMultipleState = (data) => {
    let obj = {
      ...data,
    };
    dispatchData(Action_Type.updateMultipleState, obj);
  };

  const dispatchData = (type, payload) => {
    dispatch({
      type: type,
      payload: payload,
    });
  };
  const toggleSignUpBtn = () => {
    let obj = {
      ...initialState,
      showSignUpModal: true,
    };
    dispatchData(Action_Type.handleSignUpBtn, obj);
  };
  const resetState = () => {
    dispatchData(Action_Type.handleSignUpBtn, initialState);
  };

  const closeSignUpModal = () => {
    let obj = {
      ...initialState,
      showSignUpModal: false,
    };
    dispatchData(Action_Type.handleSignUpBtn, obj);
    // to rest userLoginHooks if any attached
    resetUserLoginHook();
  };

  const handlePhoneNumber = (number) => {
    let obj = {
      formDetails: { ...state.formDetails, phoneNumber: number },
    };
    dispatchData(Action_Type.handleFormDetails, obj);
  };

  const toggleSignUpScreens = ({ type, value }) => {
    if (type === "showprofile") {
      let obj = {
        showOTPScreen: false,
        showProfile: value,
      };
      updateMultipleState(obj);
      return;
    }
    if (type === "showotp") {
      let obj = {
        showProfile: false,
        showOTPScreen: value,
      };
      updateMultipleState(obj);
      return;
    }
    if (type === "showpersonaldetails") {
      let obj = {
        showOTPScreen: false,
        showPersonalDetailsScreen: value,
      };
      updateMultipleState(obj);
      return;
    }
  };

  const handleLoginBtn = async (e, cb) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (
        state.formDetails.phoneNumber.trim().length === 0 ||
        state.formDetails.phoneNumber.trim().length !== 13
      ) {
        let obj = { errors: "Please enter valid number.", loading: false };
        dispatchData(Action_Type.handleError, obj);
        return;
      }
      let obj = { errors: false, error: false, loading: true };
      dispatchData(Action_Type.handleError, obj);

      let _phoneNumber = state.formDetails.phoneNumber.trim();
      let email = `${_phoneNumber}@docflix.com`;
      let pwd = _phoneNumber;

      await AuthManager.login(email, pwd).then((_user) => {

        if (cb) {
          cb({
            userId: _user.uid,
          });
        }
      });

      // handleLoading(false);
      obj.loading = false;
      // obj.showSignInModal = false;
      // dispatchData(Action_Type.handleError, obj);
    } catch (error) {
      console.error(error);
      if (error.code === FirebaseErrorCodes.AUTH_USER_NOT_FOUND) {
        let obj = { error: true, loading: false };
        dispatchData(Action_Type.handleError, obj);
       
        updateMultipleState( {
          showJoinModal: true,
          showSignInModal: false,
        });
      }
    }
  };

  const handleRegisterBtn = () => {
    handleLoading(true);
    const cloudRef = cloudFunction_as.httpsCallable(
      CloudFunctionName.USER_CREATION_DEV
    );
    let registeration_source = localStorage.getItem("registeration_source");

    cloudRef(
      JSON.stringify({
        email: state.formDetails.email,
        phoneNumber: state.formDetails.phoneNumber,
        firstName: state.formDetails.firstName,
        lastName: state.formDetails.lastName,
        profession: state.formDetails.profession,
        speciality: state.formDetails.speciality,
        areaOfInterest: [state.formDetails.areaOfInterest],
        current_plan: ExclusiveType.PREMIUM,
        ...state.formDetails.city,
        termsAndConditions: state.formDetails.terms,
        marketing: state.formDetails.marketing,
        date: new Date().getTime(),
        userMetaData: { registrationSource: registeration_source || "ott" },
      })
    )
      .then(async (res) => {
        localStorage.removeItem("registeration_source");

        let _phoneNumber = state.formDetails.phoneNumber.trim();
        let email = `${_phoneNumber}@docflix.com`;
        let pwd = _phoneNumber;
        await AuthManager.login(email, pwd);
        handleLoading(false);

        if (!res.data.userId) {
          window.alert("Please Try Again Later");
          handleLoading(false);
          return;
        }
      })
      .catch((err) => {
        console.error(err);
        window.errObj = err;
        if (err.code === FirebaseErrorCodes.USER_EXISTS_CLOUD_FUCTION) {
          updateMultipleState({ alreadyRegistered: true });
        }
        handleLoading(false);
      });
  };
  const updateProfile = () => {
    handleLoading(true);
    let errors = validatePremiumForm();

    if (!isValidForm(errors)) {
      dispatch({
        type: Action_Type.loading,
        payload: { loading: false },
      });
      return;
    }
    const cloudRef = cloudFunction_as.httpsCallable(
      CloudFunctionName.USER_PROFILE_UPDATE
    );
    let obj = {
      userId: user.uid,
      profession: state.formDetails.profession,
      speciality: state.formDetails.speciality,
      areaOfInterest: [state.formDetails.areaOfInterest],
      current_plan: ExclusiveType.PREMIUM,
      ...state.formDetails.city,
    };

    cloudRef(JSON.stringify(obj))
      .then(async (res) => {
        let obj = {
          premiumFormModal: false,
          premiumFormModalComplete: true,
          loading: false,
          errors: false,
        };
        updateMultipleState(obj);
      })
      .catch((err) => {
        console.error(err);
        let obj = { errors: false, loading: false };
        dispatchData(Action_Type.handleError, obj);
      });
  };

  const validateDoctor = async () => {
    handleLoading(true);
    let errors = validateExclusiveForm();

    if (!isValidForm(errors)) {
      dispatch({
        type: Action_Type.loading,
        payload: { loading: false },
      });
      return;
    }
    let smcId = instituteValues[state.formDetails.institute].smcId;
    let obj = {
      year: state.formDetails.year,
      regId: state.formDetails.regId,
      institute: state.formDetails.institute,
    };

    await UserManager._get
      .verifyDoctor(user.uid, obj, smcId, ExclusiveType.EXCLUSIVE)
      .then((res) => {
        let obj = {
          exclusiveFormModal: false,
          exclusiveFormModalComplete: true,
          loading: false,
          errors: false,
        };
        updateMultipleState(obj);
      })
      .catch((err) => {
        console.error(err);
        let obj = { errors: { regId: err.message }, loading: false };
        dispatchData(Action_Type.handleError, obj);
      });
  };

  const validateExclusiveForm = () => {
    let errors = {};
    errors.year =
      state.formDetails.year.trim().length > 0 ? "" : "Year is required";
    errors.regId =
      state.formDetails.regId.trim().length > 0
        ? ""
        : "Registration number is required";
    errors.institute =
      state.formDetails.institute.trim().length > 0
        ? ""
        : "State medical council is required";
    let obj = { errors: errors };
    dispatchData(Action_Type.handleError, obj);
    return errors;
  };
  const validatePremiumForm = () => {
    let errors = {};
    errors.profession =
      state.formDetails.profession.length > 0 ? "" : "Profession is required";
    errors.speciality =
      state.formDetails.speciality.length > 0
        ? ""
        : "Speciality number is required";
    errors.areaOfInterest =
      state.formDetails.areaOfInterest.length > 0
        ? ""
        : "Area of Interest is required";

    errors.city =
      state.formDetails.city["city"].length > 0 ? "" : "City is required";

    let obj = { errors: errors };
    dispatchData(Action_Type.handleError, obj);
    return errors;
  };

  const isValidForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const handleCloseAllForms = () => {
    updateMultipleState({
      exclusiveFormModal: false,
      premiumFormModal: false,
      exclusiveFormModalStart: false,
      premiumFormModalStart: false,
      premiumFormModalComplete: false,
      exclusiveFormModalComplete: false,
    });
  };

  // useEffect(() => {
  //   if (user) {
  //     resetState();
  //   }
  // }, [user]);

  return (
    <newAuthContext.Provider
      value={{
        toggleSignInbtn,
        handleFormDetails,
        handleLoading,
        state,
        dispatchData,
        handleLoginBtn,
        handlePhoneNumber,
        updateMultipleState,
        toggleSignUpBtn,
        toggleSignUpScreens,
        resetState,
        closeSignUpModal,
        handleRegisterBtn,
        updateProfile,
        validateDoctor,
        handleCloseAllForms,
        handleLoading
      }}
    >
      {props.children}
    </newAuthContext.Provider>
  );
}

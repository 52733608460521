const CustomPushNotification = ({ payload: { title, body, link, icon } }) => {
  const onClickHandle = () => {
    window.open(link, "_blank");
  };

  return (
    <div onClick={onClickHandle} style={{ display: "flex", gap: "1rem" }}>
      <div>
        <img src={icon} alt="" style={{ height: "3rem" }} />
      </div>
      <div>
        <div>{title}</div>
        <div>{body}</div>
      </div>
    </div>
  );
};

export default CustomPushNotification;

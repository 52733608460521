import { QA_COLLECTION } from '../../../AppConstants/CollectionConstants';
import { ASCENDING, DESCENDING, TIMESTAMP } from '../../../AppConstants/SortConstants';

import firebase, { firestore } from '../../../firebase/firebase';

let qAListenerRef = null;
const getQA = async (qaId) => {
	try {
		const docRef = firestore.collection(QA_COLLECTION).doc(qaId);
		const _data = await firestore.runTransaction(async (transaction) => {
			const doc = await transaction.get(docRef);
			if (!doc.exists) {
				const error = {
					code: 'NotValidId',
					message: 'No qaId Found'
				};

				throw error;
			}

			let _data = doc.data();

			return _data;
		});

		return _data;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const getWebinarQA = async (webinarId) => {
	try {
		let docRef = firestore.collection(QA_COLLECTION).where('webinarId', '==', webinarId);
		docRef = docRef.orderBy(TIMESTAMP, ASCENDING);

		const query = await docRef.get();
		if (query.empty) {
			return [];
		}

		let _data = [];
		query.docs.forEach((doc) => {
			_data.push(doc.data());
		});

		// sort the response with index
		_data.sort((a, b) => {
			return a.index - b.index;
		});

		return _data;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const getQALog = async (threadId) => {
	try {
		let docRef = firestore.collection(QA_COLLECTION).where('threadId', '==', threadId);
		docRef = docRef.orderBy(TIMESTAMP, DESCENDING);

		const query = await docRef.get();
		if (query.empty) {
			return [];
		}

		let _data = [];
		query.docs.forEach((doc) => {
			_data.push(doc.data());
		});

		// sort the response with index
		_data.sort((a, b) => {
			return a.index - b.index;
		});

		return _data;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const attachQAListener = (webinarId, callback = () => console.log('noFunFound')) => {
	const ref = firestore.collection(QA_COLLECTION).where('webinarId', '==', webinarId);
	qAListenerRef = ref.onSnapshot(
		(query) => {
			if (query.empty) {
				callback([]);
			}
			let _data = query.docs.map((doc) => doc.data());
			callback(_data);
		},
		(err) => {
			callback(null, err);
		}
	);
};

const removePollListener = () => {
	if (qAListenerRef) {
		qAListenerRef();
	}
};

const get = {
	getQA,
	getQALog,
	getWebinarQA,
	attachQAListener,
	removePollListener
};

export default get;

import { AGENDA_COLLECTION } from '../../../AppConstants/CollectionConstants';
import firebase, { firestore } from '../../../firebase/firebase';

const getAgenda = async (agendaId) => {
	try {
		let docRef = firestore.collection(AGENDA_COLLECTION).doc(agendaId);
		const _data = await firestore.runTransaction(async (transaction) => {
			const doc = await transaction.get(docRef);
			if (!doc.exists) {
				const error = {
					code: 'NotValidId',
					message: 'No agenda found'
				};
				throw error;
			}

			let _data = doc.data();

			return _data;
		});

		return _data;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const getWebinarAgenda = (webinar_id) => {
	return new Promise(async (res, rej) => {
		try {
			let docRef = firestore.collection(AGENDA_COLLECTION).where("webinarId", "==", webinar_id).orderBy("time", "asc");
			let docQuery = await docRef.get()
			let result = {
				mainData: [],
				order: []
			}
			if (!docQuery.empty) {
				result.mainData = docQuery.docs.map(doc => {
					result.order.push(doc.id)
					let _data = doc.data()
					return { ..._data,startTime: _data.time, time: _data.time?.seconds * 1000, }
				})

			}
			res(result)
		} catch (error) {
			rej(error)
		}
	})
}

const get = {
	getAgenda,
	getWebinarAgenda
};

export default get;

import styles from "./ExploreModal.module.scss";
import IntroducingPremium from "../../../../assets/images/Introducing Premium.png";
import ThankyouforchoosingExclusive from "../../../../assets/images/Thank you for choosing Exclusive.png";
import IntroducingExclusive from "../../../../assets/images/Introducing Exclusive.png";
import ThankyouforchoosingPremium from "../../../../assets/images/Thank you for choosing Premium.png";

import { newAuthContext } from "../../../../contexts/AuthContext/NewAuthContextProvider";
import { useContext } from "react";
import PremiumProgress from "./PreimumProgress/PremiumProgress";

const ExploreModal = () => {
  const { state, updateMultipleState, handleCloseAllForms } =
    useContext(newAuthContext);
  const {
    premiumFormModalComplete,
    exclusiveFormModalComplete,
    exclusiveFormModalStart,
    premiumFormModalStart,
  } = state;

  return (
    <div className={`modalOverlayWithBlur ${styles["container"]}`}>
      <div className={styles.box}>
        <div className={styles.main}>
          <PremiumProgress />
          {premiumFormModalStart && (
            <PremiumStart
              updateMultipleState={updateMultipleState}
              handleCloseAllForms={handleCloseAllForms}
            />
          )}
          {exclusiveFormModalStart && (
            <ExclusiveStart updateMultipleState={updateMultipleState} />
          )}
          {premiumFormModalComplete && (
            <PremiumEnd
              updateMultipleState={updateMultipleState}
              handleCloseAllForms={handleCloseAllForms}
            />
          )}
          {exclusiveFormModalComplete && (
            <ExclusiveEnd handleCloseAllForms={handleCloseAllForms} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ExploreModal;

const PremiumStart = ({ updateMultipleState, handleCloseAllForms }) => {
  const handlePremium = () => {
    updateMultipleState({
      premiumFormModalStart: false,
      premiumFormModal: true,
    });
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.img}>
        <img
          src={IntroducingPremium}
          alt="Welcome to Docflix Premium"
          height="100%"
          width="100%"
        />
      </div>
      <p>
        You discovered a premium feature! Upgrade your subscription to access
        the premium content.
      </p>
      <div className={`${styles.commonbtnContainer} ${styles.exclusiveBtns}`}>
        <button onClick={handlePremium} className={`btn ${styles.btn3}`}>
          Go Premium
        </button>
      </div>
    </div>
  );
};
const ExclusiveStart = ({ updateMultipleState }) => {
  const handleContinue = () => {
    updateMultipleState({
      exclusiveFormModalStart: false,
      exclusiveFormModal: true,
    });
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.img}>
        <img
          src={IntroducingExclusive}
          alt="Welcome to Docflix Premium"
          height="100%"
          width="100%"
        />
      </div>
      <p>
        You discovered a exclusive feature! Upgrade your subscription to access
        the Exclusive content.
      </p>
      <div className={`${styles.commonbtnContainer} ${styles.exclusiveBtns}`}>
        <button onClick={handleContinue} className={`btn ${styles.btn3}`}>
          Continue
        </button>
      </div>
    </div>
  );
};

const PremiumEnd = ({ updateMultipleState, handleCloseAllForms }) => {
  const handleExclusive = () => {
    updateMultipleState({
      premiumFormModalComplete: false,
      exclusiveFormModalStart: true,
    });
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.img}>
        <img
          src={ThankyouforchoosingPremium}
          alt="Welcome to Docflix Exclusive"
          height="100%"
          width="100%"
        />
      </div>
      <p>We look forward to enhance your watching experience.</p>
      <div className={`${styles.commonbtnContainer} ${styles.premiumBtns}`}>
        <button onClick={handleCloseAllForms} className={`btn ${styles.btn2}`}>
          Continue Watching
        </button>
        <button onClick={handleExclusive} className={`btn`}>
          Go Exclusive
        </button>
      </div>
    </div>
  );
};

const ExclusiveEnd = ({ handleCloseAllForms }) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.img}>
        <img
          src={ThankyouforchoosingExclusive}
          alt="Welcome to Docflix Premium"
          height="100%"
          width="100%"
        />
      </div>
      <p>We look forward to enhance your watching experience.</p>
      <div className={`${styles.commonbtnContainer} ${styles.exclusiveBtns}`}>
        <button onClick={handleCloseAllForms} className={`btn ${styles.btn2}`}>
          Continue Watching
        </button>
      </div>
    </div>
  );
};

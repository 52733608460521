//Imports
import {
	VIDEO_COLLECTION,
	LIKES_COLLECTION,
	SPEAKERS_COLLECTION,
	RATINGS_COLLECTION,
	WEBINAR_COLLECTION,
	AGENDA_COLLECTION,
	SERIES_COLLECTION,
	SEASON_COLLECTION
} from '../../../AppConstants/CollectionConstants';
import { SourceType, VideoType, LikeType, RatingType, ContentType } from '../../../AppConstants/TypeConstants';

// Firebase
import firebase, { firestore } from '../../../firebase/firebase';
const uniqid = require('uniqid');

const updateRating = async (targetId, userId, rating, targetType = ContentType.VIDEO) => {
	try {
		const COLLECTION = {
			[ContentType.VIDEO]: VIDEO_COLLECTION,
			[ContentType.WEBINAR]: WEBINAR_COLLECTION,
			[ContentType.AGENDA]: AGENDA_COLLECTION,
			[ContentType.SERIES]: SERIES_COLLECTION,
			[ContentType.SEASON]: SEASON_COLLECTION
		}

		let targetCollection = COLLECTION[targetType]
		const docRef = firestore.collection(targetCollection).doc(targetId);
		const RatingRef = firestore.collection(RATINGS_COLLECTION).doc(`${userId}+${targetId}`);

		const _rating = await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(docRef);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No VideoId Found'
				};
				throw err;
			}

			let ratingDoc = await transcation.get(RatingRef);
			if (!ratingDoc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No RatingId Found'
				};
				throw err;
			}

			transcation.update(RatingRef, {
				rating,
				updatetimestamp: firebase.firestore.FieldValue.serverTimestamp()
			});

			// Weird logic for rating
			const _doc = doc.data();
			const _rating = ratingDoc.data();

			const originalRating = _doc.rating * _doc.ratingsgiven;
			const userRating = _rating.rating;
			const updatedRating = originalRating - userRating + rating;
			const totalUsers = _doc.ratingsgiven;
			const avgRating = updatedRating / totalUsers;

			transcation.update(docRef, {
				rating: avgRating.toFixed(2)
			});
			return avgRating.toFixed(2);
		});
		return _rating;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const put = {
	updateRating
};

export default put;

import { useContext, useEffect, useReducer, useRef, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import styles from "./index.module.scss";
import closebtnImg from "../../../../assets/images/close.png";
import thankYouImg from "../../../../assets/images/thankyouImg.png";
import LoaderImg from "../../../../assets/images/Loader.gif";
import { AuthManager, cloudFunction_as } from "../../../../firebase/firebase";
import { CloudFunctionName } from "../../../../AppConstants/CloudFunctionName";
import { FirebaseErrorCodes } from "../../../../AppConstants/FirebaseErrorCodes";
import { AnalyticsContext } from "../../../../contexts/Analytics/AnalyticsContextProvider";
import { useLocation } from "react-router-dom";
import { newAuthContext } from "../../../../contexts/AuthContext/NewAuthContextProvider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { validateEmail } from "../../../../utils/common.utils";
import {
  static_page_event_id,
  whiteListCountries,
  CITY_DEBOUNCE,
  CITY_FETCH_MIN_CHAR,
} from "../../../../AppConstants/Constants";
import { UserContext } from "../../../../contexts/UserContext/UserContextProvider";
import OTPForm from "../../../../components/Signup/partials/OTPForm/OTPForm";
import {
  Profession_DATA,
  hideSpecialityForProfession,
} from "../../../../AppData/Profession";
import { SPECIALITY_DATA } from "../../../../AppData/Speciality";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import Joi from "joi";

const schema = Joi.object({
  firstName: Joi.string()
    .pattern(new RegExp("^[a-zA-Z ]+$")) // Only alphabets
    .min(2)
    .max(50)
    .required()
    .messages({
      "string.pattern.base": '"First Name" should contain only alphabets',
      "any.required": '"First Name" is required',
      "string.min": '"First Name" should have a minimum length of {#limit}',
      "string.max": '"First Name" should have a maximum length of {#limit}',
    })
    .label("First Name"),

  lastName: Joi.string()
    .pattern(new RegExp("^[a-zA-Z ]+$")) // Only alphabets
    .min(2)
    .max(50)
    .required()
    .messages({
      "string.pattern.base": '"Last Name" should contain only alphabets',
      "any.required": '"Last Name" is required',
      "string.min": '"Last Name" should have a minimum length of {#limit}',
      "string.max": '"Last Name" should have a maximum length of {#limit}',
    })
    .label("Last Name"),

  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: ["com", "net"] } })
    .required()
    .label("Email"),

  phoneNumber: Joi.string()
    .pattern(new RegExp("^[+]?[0-9]+$")) // Plus sign (+) followed by digits
    .required()
    .label("Mobile Number")
    .messages({
      "string.pattern.base": '"Mobile Number" should be a valid number',
    }),
    city: Joi.object({
      state: Joi.string().allow('', null).optional().empty(''),
      city: Joi.string().required().messages({
        "any.only": 'Enter a valid City',
      }),
      country: Joi.string().allow('', null).optional().empty(''),
      pincode: Joi.number().allow(null).optional(),
      location: Joi.object({
        lat: Joi.number().allow(null).optional(),
        lng: Joi.number().allow(null).optional(),
      }).optional(),
    }).required()
    .messages({
      "any.only": 'Enter a valid City',
    }),

  profession: Joi.string()
    .valid(...Profession_DATA) // Only allow values present in validProfessions array
    .required()
    .label("Profession")
    .messages({
      "any.only": 'Enter a valid profession',
    }),

  speciality: Joi.string()
  .valid(...SPECIALITY_DATA)
    .when("profession", {
      is: (profession) =>
        !hideSpecialityForProfession.includes(profession.trim()),
      then: Joi.required(),
      otherwise: Joi.optional(),
    })
    .label("Speciality")
    .messages({
      "any.only": 'Enter a valid Speciality',
    }),
});

const inputs = [
  {
    label: "Name",
    name: "firstName",
    className: "form-control",
    type: "text",
    placeholder: "First Name",
    required: true,
    disabled: false,
    readOnly: false,
  },
  {
    label: "Name",
    name: "lastName",
    className: "form-control",
    type: "text",
    placeholder: "Last Name",
    required: true,
    disabled: false,
    readOnly: false,
  },
  {
    label: "Email",
    name: "email",
    className: "form-control",
    type: "email",
    placeholder: "Enter Your Email Id",
    required: true,
    disabled: false,
    readOnly: false,
  },
  {
    label: "Mobile Number",
    name: "phoneNumber",
    className: "form-control",
    type: "text",
    placeholder: "Enter Mobile Number",
    required: true,
    disabled: false,
    readOnly: false,
  },
  {
    label: "City",
    name: "city",
    className: "form-control",
    type: "text",
    placeholder: "Enter City",
    required: true,
    disabled: false,
    readOnly: false,
  },
  {
    label: "Profession",
    name: "profession",
    className: "form-control",
    type: "select",
    placeholder: "Select your Profession",
    data: Profession_DATA,
    required: true,
    disabled: false,
    readOnly: false,
  },
  {
    label: "Speciality",
    name: "speciality",
    className: "form-control",
    type: "select",
    placeholder: "Select your Speciality",
    data: SPECIALITY_DATA,
    required: true,
    disabled: false,
    readOnly: false,
  },
];
const defaultErr = {
  firstName: "",
  lastName: "",
  email: "",
  city: "",
  phoneNumber: "",
  profession: "",
  speciality: "",
  defaultError: "",
};

const initialState = {
  isLoading: false,
  // firstName: "",
  // lastName: "",
  // email: "",
  // phoneNumber: "",
  isRegistered: false,
  city: "",
  isUserVerified: false,
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
  teamId: null,
  errors: {
    ...defaultErr,
  },
};

const Action_Type = {
  handleInputChange: "handleInputChange",
  handleError: "handleError",
  loading: "loading",
  handleUTM: "handleUTM",
};

const reducer = (state, action) => {
  let { type, payload } = action;

  switch (type) {
    case Action_Type.handleInputChange:
      return { ...state, [payload.name]: payload.value };
    case Action_Type.handleError:
    case Action_Type.loading:
    case Action_Type.handleUTM:
    case Action_Type.updateCity:
      return { ...state, ...payload };

    default:
      return { ...state };
  }
};

const ConverseJoin = ({ teamId }) => {
  const { addGANoUser } = useContext(AnalyticsContext);
  const { checkUserIsOTPVerifiedV2 } = useContext(UserContext);
  const history = useHistory();

  const handlePlaceSelect = async (address, placeId, suggestion) => {
    try {
      let resp = await geocodeByAddress(address);
      let addressObject = resp[0];

      let lat = addressObject.geometry?.location.lat();
      let lng = addressObject.geometry?.location.lng();
      if (!lat) {
        lat = 20.5937;
      }
      if (!lng) {
        lng = 78.9629;
      }

      let locationResult = {
        state: "",
        city: "",
        country: "",
        pincode: null,
        location: {
          lat,
          lng,
        },
      };

      addressObject.address_components.forEach((element) => {
        if (element.types.indexOf("locality") !== -1) {
          locationResult.city = element.long_name;
        } else if (
          element.types.indexOf("administrative_area_level_1") !== -1
        ) {
          locationResult.state = element.long_name;
        } else if (element.types.indexOf("country") !== -1) {
          locationResult.country = element.long_name;
        } else if (element.types.indexOf("postal_code") !== -1) {
          locationResult.pincode = element.long_name;
        }
      });

      if (!locationResult.city) {
        locationResult.city = locationResult.state;
      }
      dispatch({
        type: Action_Type.updateCity,
        payload: { city: address },
      });
      handleFormDetails({ city: locationResult });
    } catch (error) {
      console.error(error);
    }
  };

  const {
    toggleSignInbtn,
    handleFormDetails,
    handlePhoneNumber,
    handleLoginBtn,
    // handleLoading,
    updateMultipleState,
    state: analyticsState,
    handleLoading
  } = useContext(newAuthContext);
  const { error, formDetails, loading } = analyticsState;
  const { phoneNumber } = formDetails;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { pathname, search = "", hash = "" } = useLocation();

  useEffect(() => {
    let obj = {
      utm_source: new URLSearchParams(search).get("utm_source"),
      utm_medium: new URLSearchParams(search).get("utm_medium"),
      utm_campaign: new URLSearchParams(search).get("utm_campaign"),
      teamId: new URLSearchParams(search).get("teamId"),
      return: new URLSearchParams(search).get("return"),
    };

    dispatch({
      type: Action_Type.handleUTM,
      payload: obj,
    });
  }, [search]);

  useEffect(() => {
    let obj = {
      phoneNumber: phoneNumber,
    };

    dispatch({
      type: Action_Type.handleUTM,
      payload: obj,
    });
  }, [phoneNumber]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let obj = {
      [name]: value,
    };
    handleFormDetails(obj);
    // formDetails
    // dispatch({
    //   type: Action_Type.handleInputChange,
    //   payload: { name, value },
    // });
  };

  const handleJoinModalClose = () => {
    toggleSignInbtn("showJoinModal", false);
  };

  const handleThankYouClose = () => {
    toggleSignInbtn("showJoinModal", false);

    if (state.return) {
      // history.push(`video/${state.return}`);
      window.open(state.return, "_self");
    }
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      // console.log("first",);

      dispatch({
        type: Action_Type.loading,
        payload: { isLoading: true, errors: defaultErr },
      });
      await validateForm();

      if (!isValidForm(state.errors)) {
        dispatch({
          type: Action_Type.loading,
          payload: { isLoading: false },
        });
        return;
      }
      await handleRegisterBtn();
    } catch (error) {
      let errors = state.errors;
      if (error.code) {
        errors.description = error.message;
      }
      dispatch({
        type: Action_Type.loading,
        payload: { isLoading: false, errors: errors },
      });
    }
  };

  const handleRegisterBtn = async () => {
    const cloudRef = cloudFunction_as.httpsCallable(CloudFunctionName.USER_CREATION_EVENT);
    let obj = {
      email: formDetails.email,
      phoneNumber: formDetails.phoneNumber,
      firstName: formDetails.firstName,
      lastName: formDetails.lastName,
      profession: formDetails.profession,
      speciality: formDetails.speciality,
      ...formDetails.city,
      termsAndConditions: false,
      marketing: false,
      date: new Date().getTime(),
      utm_source: state.utm_source,
      utm_medium: state.utm_medium,
      utm_campaign: state.utm_campaign,

      userMetaData: {
        registrationSource: static_page_event_id,
      },
    };


    cloudRef(JSON.stringify(obj))
      .then(async (res) => {
        if (!res.data.userId) {
          dispatch({
            type: Action_Type.loading,
            payload: {
              isLoading: false,
              errors: { defaultError: "Please Try Again Later" },
            },
          });
          return;
        }

        // let thanksURL = `/thankyou?`;

        // let searchParam = new URLSearchParams(search).toString();

        // thanksURL = state.utm_source
        //   ? thanksURL.concat(`utm_source=${state.utm_source}&`)
        //   : "";
        // thanksURL = state.utm_medium
        //   ? thanksURL.concat(`utm_medium=${state.utm_medium}&`)
        //   : "";
        // thanksURL = state.utm_campaign
        //   ? thanksURL.concat(`utm_campaign=${state.utm_campaign}`)
        //   : "";
        // thanksURL = state.teamId
        //   ? thanksURL.concat(`teamId=${state.teamId}`)
        //   : "";
        // window.history.replaceState({}, null, thanksURL + searchParam);

        if (!res.data.webinar) {
          await handleLoginBtn(null, async (res) => {
            if (res) {
              addGANoUser(static_page_event_id, {
                teamId: teamId || null,
                utm_medium: state.utm_medium || null,
                utm_campaign: state.utm_campaign || null,
                ...res,
              });
            }
            if (res) {
              // check otp verified
              if (res.userId && window?.clientId) {
                let verifiedUser = await checkUserIsOTPVerifiedV2(
                  res.userId,
                  window?.clientId?.toString().replaceAll(".", "")
                );
                if (!verifiedUser) {
                  dispatch({
                    type: Action_Type.loading,
                    payload: {
                      isLoading: false,
                      errors: { defaultError: "" },
                      isUserVerified: true,
                    },
                  });
                } else {
                  webinarRegistration();
                }
              }
            }
          });
        } else {
          dispatch({
            type: Action_Type.loading,
            payload: {
              isLoading: false,
              errors: { defaultError: "User Already Registered" },
            },
          });
        }
      })
      .catch((err) => {
        console.error(err);

        window.errObj = err;
        if (err.code === FirebaseErrorCodes.USER_EXISTS_CLOUD_FUCTION) {
          dispatch({
            type: Action_Type.loading,
            payload: {
              isLoading: false,
              errors: { defaultError: err.message },
            },
          });
          return;
        }
        dispatch({
          type: Action_Type.loading,
          payload: { isLoading: false },
        });
      });
  };

  const webinarRegistration = () => {
    const WebinarCloudRef = cloudFunction_as.httpsCallable(
      "webinarRegisterExternal"
    );
    WebinarCloudRef(
      JSON.stringify({
        eventId: static_page_event_id,
        mankind: {
          teamId: teamId || null,
          empID: state.utm_source || null,
        },
        utm_medium: state.utm_medium || null,
        utm_campaign: state.utm_campaign || null,
        isExternalEvent: true,
      })
    )
      .then(async (res) => {
        dispatch({
          type: Action_Type.loading,
          payload: {
            isLoading: false,
            errors: { defaultError: "" },
            isRegistered: true,
            isUserVerified: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: Action_Type.loading,
          payload: {
            isLoading: false,
            errors: { defaultError: "Please Try Again Later" },
          },
        });
      });
  };

  const validateForm = async() => {
    let errors = state.errors;

    errors.defaultError = "";
    try {
      const value = await schema.validateAsync({ ...formDetails });
    } catch (error) {
      if (error.details) {

        Object.keys(errors).forEach((key) => {
          if (key === error.details[0].context.key) {
            errors[key] = error.details[0].message;
          } else {
            errors[key] = "";
          }
        });
      }
    }

    // errors.firstName =
    //   formDetails.firstName.length > 0 ? formDetails.lastName.length > 0 ? "" : "Please enter valid last name." : "Please enter valid first name.";
    // // errors.lastName =
    // //   formDetails.lastName.length > 0 ? "" : "Please enter valid last name.";
    // errors.email =
    //   (formDetails.email.length > 0 && validateEmail(formDetails.email)) ? "" : "Please enter valid email.";
    // errors.city =
    //   formDetails.city.city.length > 0 ? "" : "Please enter valid city.";
    // errors.profession =
    //   formDetails.profession.length > 0 ? "" : "Please select your profession.";

    // if (!hideSpecialityForProfession.includes(formDetails.profession.trim())) {
    //   errors.speciality =
    //     formDetails.speciality.trim().length > 0
    //       ? ""
    //       : "Please select speciality.";
    // }

    // errors.phoneNumber =
    //   !formDetails.phoneNumber ||
    //   !isValidPhoneNumber(formDetails.phoneNumber.trim())
    //     ? "Please enter valid mobile number."
    //     : "";
    dispatch({
      type: Action_Type.handleError,
      payload: { errors: { ...errors } },
    });
  };

  const isValidForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };
  // const handlePhoneNumber = (number) => {
  //   dispatch({
  //     type: Action_Type.handleInputChange,
  //     payload: { name: "phoneNumber", value: number },
  //   });
  // };

  const onCityError = (errCode) => {
    let error = "";
    switch (errCode) {
      case "ZERO_RESULTS":
        error = "No result found.";
        break;
      default:
        error = "Some internal error occurred.";
        break;
    }
    dispatch({
      type: Action_Type.handleError,
      payload: { errors: { city: error } },
    });
  };

  return (
    <>
      <div className={`modalOverlayWithBlur`} />

      {!state.isRegistered && !state.isUserVerified ? (
        <div className={`${styles["container"]}`}>
          <div className={styles["box"]}>
            <h1>REGISTER NOW</h1>
            <form onSubmit={handleSubmit} className={styles["signin-form"]}>
              <label
                htmlFor={inputs[0].name}
                style={{ marginBottom: "0.5rem" }}
              >
                {inputs[0].label}
              </label>
              <div style={{ display: "flex", gap: "1rem" }}>
                <input
                  id={inputs[0].name}
                  name={inputs[0].name}
                  className={inputs[0].className}
                  type={inputs[0].type}
                  placeholder={inputs[0].placeholder}
                  value={state[inputs[0].name]}
                  required={inputs[0].required}
                  disabled={inputs[0].disabled}
                  readOnly={inputs[0].readOnly}
                  onChange={(e) => handleInputChange(e)}
                />
                <input
                  id={inputs[1].name}
                  name={inputs[1].name}
                  className={inputs[1].className}
                  type={inputs[1].type}
                  placeholder={inputs[1].placeholder}
                  value={state[inputs[1].name]}
                  required={inputs[1].required}
                  disabled={inputs[1].disabled}
                  readOnly={inputs[1].readOnly}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              {inputs.map((input) => {
                if (input.name === "speciality") {
                  if (!formDetails.profession) {
                    return;
                  }
                  if (
                    hideSpecialityForProfession.includes(formDetails.profession)
                  ) {
                    return;
                  }
                }
                return (
                  <div
                    key={input.name}
                    className={"input-group"}
                    style={{ marginBottom: "0.8rem" }}
                  >
                    {input.name !== "firstName" &&
                      input.name !== "lastName" && (
                        <label htmlFor={input.name}>{input.label}</label>
                      )}
                    {input.name === "phoneNumber" ? (
                      <PhoneInput
                        defaultCountry={whiteListCountries[0]}
                        international
                        // country="IN"
                        addInternationalOption={false}
                        countryCallingCodeEditable={false}
                        limitMaxLength
                        countries={whiteListCountries}
                        withCountryCallingCode
                        className="form-control"
                        name="phoneNumber"
                        value={state[input.name]}
                        id="phone"
                        placeholder="Enter Mobile Number"
                        onChange={handlePhoneNumber}
                      />
                    ) : input.name === "city" ? (
                      <PlacesAutocomplete
                        onError={onCityError}
                        value={state[input.name]}
                        onChange={(value) => {
                          dispatch({
                            type: Action_Type.updateCity,
                            payload: { city: value },
                          });
                        }}
                        onSelect={handlePlaceSelect}
                        searchOptions={{
                          // sessionToken: sessionToken,
                          types: ["(cities)"],
                          fields: ["address_components"],
                          componentRestrictions: {
                            country: whiteListCountries,
                          },
                        }}
                        debounce={CITY_DEBOUNCE}
                        shouldFetchSuggestions={
                          state[input.name]?.length >= CITY_FETCH_MIN_CHAR
                        }
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div style={{ position: "relative" }}>
                            <input
                              className="form-control"
                              {...getInputProps({
                                placeholder: input.placeholder,
                                name: input.name,
                                id: input.name,
                                required: input.required,
                                disabled: input.disabled,
                                readOnly: input.readOnly,
                              })}
                            />
                            <div
                              style={{
                                position: "absolute",
                                zIndex: "10",
                                border: `${
                                  suggestions.length === 0
                                    ? "0px"
                                    : "1px solid rgba(255, 255, 255, 0.4)"
                                }`,
                                width: "100%",
                                borderRadius: "8px",
                                overflow: "hidden",
                              }}
                            >
                              {loading && <div>Loading...</div>}

                              {suggestions.map((suggestion, index) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#eb4547"
                                    : "rgba(44, 64, 103, 1)",
                                  color: "#ffff",
                                  padding: "0.5rem",
                                  cursor: "pointer",
                                };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                    key={index}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      key={suggestion.description}
                                      value={suggestion.description}
                                    >
                                      {suggestion.description}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    ) : input.name == "profession" ||
                      input.name == "speciality" ? (
                      <select
                        defaultValue={input.placeholder}
                        id={input.name}
                        name={input.name}
                        className={`${input.className} ${styles.dp}`}
                        type={input.type}
                        placeholder={input.placeholder}
                        required={input.required}
                        value={
                          formDetails[input.name]
                            ? formDetails[input.name]
                            : input.placeholder
                        }
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      >
                        <option value={""}>{input.placeholder}</option>
                        {input.data.map((val) => (
                          <option value={val} key={val}>
                            {val}
                          </option>
                        ))}
                      </select>
                    ) : input.name !== "firstName" &&
                      input.name !== "lastName" ? (
                      <input
                        id={input.name}
                        name={input.name}
                        className={input.className}
                        type={input.type}
                        placeholder={input.placeholder}
                        value={state[input.name]}
                        required={input.required}
                        disabled={input.disabled}
                        readOnly={input.readOnly}
                        onChange={(e) => handleInputChange(e)}
                      />
                    ) : null}
                    {state.errors[input.name] && (
                      <div className="error">{state.errors[input.name]}</div>
                    )}
                  </div>
                );
              })}
              {state.errors.defaultError && (
                <div className="error">{state.errors.defaultError}</div>
              )}
            </form>
              <button
                disabled={state.isLoading}
                className={`btn ${styles["submit-btn"]}`}
                onClick={handleSubmit}>
              
                {state.isLoading ? (
                  <img src={LoaderImg} alt="" height="100%" />
                ) : (
                  "JOIN"
                )}
              </button>
            <div onClick={handleJoinModalClose} className={styles["closeBtn"]}>
              <img src={closebtnImg} alt="close" />
            </div>
          </div>
        </div>
      ) : state.isUserVerified ? (
        <div className={`${styles["container"]}`}>
          <div className={styles["box"]}>
            <OTPForm
              data={{ phoneNumber: formDetails.phoneNumber }}
              verifyCB={webinarRegistration}
              showBack={false}
            />
          </div>
        </div>
      ) : (
        <div className={`${styles["container"]}`}>
          <div className={`${styles["box"]} ${styles["registeredContainer"]}`}  style={{
              padding: "9rem 2rem",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}>
            <div className={`${styles["text1"]}`} style={{ fontSize: "1.5rem" }}>
              Dear Dr.{" "}
              <span style={{ textTransform: "capitalize" }}>
                {formDetails.firstName}
              </span>
              <br />
              <span style={{ color: "#eb4547" }}>Thank you</span>
            </div>
            <div className={`${styles["textThankYou"]}`}>
              <img
                src={thankYouImg}
                alt="Thank You"
                height="100%"
                width="100%"
              />
            </div>
            <div onClick={handleThankYouClose} className={styles["closeBtn"]}>
              <img src={closebtnImg} alt="close" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConverseJoin;

//Imports
import axios from 'axios';
import {
	VIDEO_COLLECTION,
	LIKES_COLLECTION,
	SPEAKERS_COLLECTION,
	MEDIA_METADATA_COLLECTION,
	SEASON_COLLECTION
} from '../../AppConstants/CollectionConstants';
import { SourceType, VideoType, ContentType, ExclusiveType } from '../../AppConstants/TypeConstants';

// Firebase
import firebase, { firestore, cloudFunction_as } from '../../firebase/firebase';
import { CloudFunctionName } from '../../AppConstants/CloudFunctionName';
const fetchMuxData = cloudFunction_as.httpsCallable(CloudFunctionName.GET_MUXDATA_ASSETID);
const uniqid = require('uniqid');

const tokenURL = `https://asia-south1-docflix-d8cab.cloudfunctions.net/fetchJWTTokens`;
const headers = {
	'Content-Type': 'application/json;charset=UTF-8',
	'Access-Control-Allow-Origin': '*'
};

const createVideo = async ({
	title,
	description,
	tags,
	speakers,
	detailsId,
	featuredIndex,
	sourceType,
	sourceData,
	videoType,
	videoData,
	type,
	therapy,
	associationId,
	associationType,
	thumbnailUrl,
	speciality,
	misc,
	topics
}) => {
	try {
		const videoId = uniqid('video-');
		const metaDataId = uniqid('media-metadata-');

		// Formatting tags and speakers
		tags = tags ? tags.map((t) => t.toLowerCase()) : [];
		speakers = speakers ? speakers : [];
		therapy = therapy ? therapy.map((t) => t.toLowerCase()) : [];
		misc = misc ? misc.map((t) => t.toLowerCase()) : [];
		topics = topics ? topics.map((t) => t.toLowerCase()) : [];
		speciality = speciality ? speciality.map((t) => t.toLowerCase()) : [];

		// Setup Video Document
		let video = {
			title: title || null,
			description: description || null,
			id: videoId,
			speakers: speakers || [],
			tags: tags || [],
			therapy: therapy || [],
			misc: misc || [],
			topics: topics || [],
			metaTags: [ ...tags, ...therapy, ...misc, ...topics ], //contains tags and therapy
			associationType: associationType || null,
			associationId: associationId || null,
			speciality: speciality || [],
			type: type ? type : ExclusiveType.FREE,
			likes: 0,
			views: 0,
			detailsId: detailsId || null,
			rating: 0,
			ratingsgiven: 0,
			featuredIndex: featuredIndex ? featuredIndex : -1,

			thumbnailUrl: thumbnailUrl || null,
			timestamp: firebase.firestore.FieldValue.serverTimestamp()
		};

		// Source Type ::: mux | vimeo | youtube
		if (sourceType && sourceType === SourceType.SOURCE_MUX) {
			video = {
				sourceType,
				assetId: sourceData.assetId || 'hcz3mTKK4ZN01Zm6aorLPhBDgKpH500XDhaBlDa9slEUI',
				playbackid: sourceData.playbackid || '5HcbHqivB02MqZbopXiGkdViULEnUsaoTYzZMuglfy5Q',
				videoPolicy: sourceData.videoPolicy || 'public', // signed | public
				...video
			};

			if (video.playbackid && video.videoPolicy === 'signed') {
				let resp = await fetchTokens(video.playbackid);
				video = {
					tokens: resp,
					...video
				};
			}
		} else if (sourceType && sourceType === SourceType.SOURCE_VIMEO) {
			video = {
				sourceType,
				thumbnail:
					sourceData.thumbnail ||
					'https://storage.googleapis.com/cipla-impact.appspot.com/impact2021/video/1.jpg',
				videoUrl: sourceData.videoUrl || 'https://vimeo.com/648504020/595aa62994',
				...video
			};
		}

		// VideoType ::: standalone | series
		if (videoType && videoType === VideoType.VIDEO_STANDALONE) {
			video = { videoType, ...video };
		} else if (videoType && videoType === VideoType.VIDEO_SERIES && Object.keys(videoData).length) {
			if ((!videoData.seriesId, !videoData.seasonId, !videoData.episodeId))
				throw new Error('Unsufficient series data');
			video = {
				videoType,
				videoData: {
					seriesId: videoData.seriesId || 'series-123',
					seasonId: videoData.seasonId || 'season-123',
					episodeId: videoData.episodeId || 'episode-123'
				},
				...video
			};
		}

		// Setup Metadata Document
		let metaData = {
			id: metaDataId,
			targetId: videoId,
			contentType: type ? type : ExclusiveType.FREE,
			mediaType: ContentType.VIDEO,
			timestamp: firebase.firestore.FieldValue.serverTimestamp(),
			detailsId: detailsId || null,
			title: title || null,
			description: description || null
		};

		await firestore.runTransaction(async (transaction) => {
			if (videoType && videoType === VideoType.VIDEO_SERIES) {
				let seasonRef = firestore.collection(SEASON_COLLECTION).doc(videoData.seasonId);
				let doc = await transaction.get(seasonRef);
				let seasonData = doc.data();

				if (!doc.exists) {
					let err = {
						code: 'NotValidId',
						message: 'No season Found'
					};
					throw err;
				}

				const { episodeIndex, episodeId } = videoData;
				let episodelength = seasonData.episodes.length;
				if (episodeIndex !== null && episodeIndex !== undefined && episodeIndex < episodelength) {
					seasonData.episodes.splice(episodeIndex, 0, {
						episodeNumber: episodeIndex + 1,
						episodeId,
						episodeTitle: title,
						targetId: videoId
					});
				} else {
					seasonData.episodes.push({
						episodeNumber: episodelength + 1,
						episodeId,
						episodeTitle: title,
						targetId: videoId
					});
				}

				// Update season accordingly
				transaction.update(seasonRef, {
					episodes: seasonData.episodes
				});
			}

			transaction.set(firestore.collection(VIDEO_COLLECTION).doc(videoId), video);
			transaction.set(firestore.collection(MEDIA_METADATA_COLLECTION).doc(metaDataId), metaData);
		});

		await fetchMuxData(videoId);

		return videoId;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const fetchTokens = async (playbackId) => {
	try {
		const _options = {
			playbackId,
			expiry: `${99 * 365}d`
		};

		const resp = await axios.post(tokenURL, _options, { headers });
		console.log('Resp :: ', resp);
		const { status, data } = resp.data;

		if (status.code === 200 && data) {
			return data;
		} else {
			throw 'API Error';
		}
	} catch (error) {
		console.log('Error :: ', error);
		console.log('Error :: in fetch Tokens on video add ');
		throw error.message || 'Failed to fetch tokens';
	}
};

const post = {
	createVideo
};

export default post;

import { useContext, useEffect, useReducer } from "react";
import Input from "react-phone-number-input/input";
import styles from "./JoinModal.module.scss";
import closebtnImg from "../../../../assets/images/close.png";
import thankYouImg from "../../../../assets/images/landingPage/thankYou.png";
import LoaderImg from "../../../../assets/images/Loader.gif";
import { cloudFunction_as } from "../../../../firebase/firebase";
import { CloudFunctionName } from "../../../../AppConstants/CloudFunctionName";
import { FirebaseErrorCodes } from "../../../../AppConstants/FirebaseErrorCodes";
import { AnalyticsContext } from "../../../../contexts/Analytics/AnalyticsContextProvider";
import { useLocation } from "react-router-dom";
import { newAuthContext } from "../../../../contexts/AuthContext/NewAuthContextProvider";

const inputs = [
  {
    label: "Name",
    name: "firstName",
    className: "form-control",
    type: "text",
    placeholder: "First Name",
    required: true,
    disabled: false,
    readOnly: false,
  },
  {
    label: "Name",
    name: "lastName",
    className: "form-control",
    type: "text",
    placeholder: "Last Name",
    required: true,
    disabled: false,
    readOnly: false,
  },
  {
    label: "Email",
    name: "email",
    className: "form-control",
    type: "email",
    placeholder: "Enter Your Email Id",
    required: true,
    disabled: false,
    readOnly: false,
  },
  {
    label: "Mobile Number",
    name: "phoneNumber",
    className: "form-control",
    type: "text",
    placeholder: "Enter Mobile Number",
    required: true,
    disabled: false,
    readOnly: false,
  },
];
const defaultErr = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  defaultError: "",
};

const initialState = {
  isLoading: false,
  // firstName: "",
  // lastName: "",
  // email: "",
  // phoneNumber: "",
  isRegistered: false,
  utm_source: "",
  utm_medium: "",
  utm_campaign: "",
  errors: {
    ...defaultErr,
  },
};

const Action_Type = {
  handleInputChange: "handleInputChange",
  handleError: "handleError",
  loading: "loading",
  handleUTM: "handleUTM",
};

const reducer = (state, action) => {
  let { type, payload } = action;

  switch (type) {
    case Action_Type.handleInputChange:
      return { ...state, [payload.name]: payload.value };
    case Action_Type.handleError:
      return { ...state, ...payload };
    case Action_Type.loading:
      return { ...state, ...payload };
    case Action_Type.handleUTM:
      return { ...state, ...payload };
    default:
      return { ...state };
  }
};

const JoinModal = () => {
  const { addGAWithUserInfo } = useContext(AnalyticsContext);
  const {
    toggleSignInbtn,
    handleFormDetails,
    handlePhoneNumber,
    handleLoginBtn,
    // handleLoading,
    updateMultipleState,
    state: analyticsState,
  } = useContext(newAuthContext);
  const { error, formDetails, loading } = analyticsState;
  const { phoneNumber } = formDetails;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { pathname, search = "", hash = "" } = useLocation();

  useEffect(() => {
    let obj = {
      utm_source: new URLSearchParams(search).get("utm_source"),
      utm_medium: new URLSearchParams(search).get("utm_medium"),
      utm_campaign: new URLSearchParams(search).get("utm_campaign"),
    };

    dispatch({
      type: Action_Type.handleUTM,
      payload: obj,
    });
  }, [search]);

  useEffect(() => {
    let obj = {
      phoneNumber: phoneNumber,
    };

    dispatch({
      type: Action_Type.handleUTM,
      payload: obj,
    });
  }, [phoneNumber]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let obj = {
      [name]: value,
    };
    handleFormDetails(obj);
    // formDetails
    // dispatch({
    //   type: Action_Type.handleInputChange,
    //   payload: { name, value },
    // });
  };
  const handleJoinModalClose = () => {
    toggleSignInbtn("showJoinModal", false);
  };
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      dispatch({
        type: Action_Type.loading,
        payload: { isLoading: true, errors: defaultErr },
      });
      validateForm();

      if (!isValidForm(state.errors)) {
        dispatch({
          type: Action_Type.loading,
          payload: { isLoading: false },
        });
        return;
      }
      await handleRegisterBtn();
    } catch (error) {
      let errors = state.errors;
      if (error.code) {
        errors.description = error.message;
      }
      dispatch({
        type: Action_Type.loading,
        payload: { isLoading: false, errors: errors },
      });
    }
  };

  const handleRegisterBtn = () => {
    const cloudRef = cloudFunction_as.httpsCallable(
      CloudFunctionName.USER_CREATION
    );
    let registeration_source = localStorage.getItem("registeration_source");
    let obj = {
      email: formDetails.email,
      phoneNumber: formDetails.phoneNumber,
      firstName: formDetails.firstName,
      lastName: formDetails.lastName,
      profession: "",
      speciality: "",
      city: "",
      state: "",
      country: "",
      geoHash: null,
      latitude: null,
      location: null,
      longitude: null,
      pincode: null,
      termsAndConditions: false,
      marketing: false,
      date: new Date().getTime(),
      utm_source: state.utm_source,
      utm_medium: state.utm_medium,
      utm_campaign: state.utm_campaign,

      userMetaData: {
        registrationSource: registeration_source || "partiallyRegistered",
      },
    };

    cloudRef(JSON.stringify(obj))
      .then(async (res) => {
        if (!res.data.userId) {
          dispatch({
            type: Action_Type.loading,
            payload: {
              isLoading: false,
              errors: { defaultError: "Please Try Again Later" },
            },
          });
          return;
        }
        localStorage.removeItem("registeration_source");

        addGAWithUserInfo("join_btn", { ...obj, userId: res.data.userId });

        dispatch({
          type: Action_Type.loading,
          payload: {
            isLoading: false,
            errors: { defaultError: "" },
            // isRegistered: true,
          },
        });
        updateMultipleState({
          showJoinModal: false,
          showOTPModal: true,
          loading: false,
        });

        if (state.utm_source === "googleads") {
          addScript(
            "https://www.googletagmanager.com/gtag/js?id=UA-228483250-1",
            () => {
              console.log("loaded");
            }
          );
        }
        // let thanksURL = `/thankyou?`;
        // thanksURL = state.utm_source
        //   ? thanksURL.concat(`utm_source=${state.utm_source}&`)
        //   : "";
        // thanksURL = state.utm_medium
        //   ? thanksURL.concat(`utm_medium=${state.utm_medium}&`)
        //   : "";
        // thanksURL = state.utm_campaign
        //   ? thanksURL.concat(`utm_campaign=${state.utm_campaign}`)
        //   : "";
        // window.history.replaceState({}, null, thanksURL);
        await handleLoginBtn();
      })
      .catch((err) => {
        console.error(err);

        window.errObj = err;
        if (err.code === FirebaseErrorCodes.USER_EXISTS_CLOUD_FUCTION) {
          dispatch({
            type: Action_Type.loading,
            payload: {
              isLoading: false,
              errors: { defaultError: err.message },
            },
          });
          return;
        }
        dispatch({
          type: Action_Type.loading,
          payload: { isLoading: false },
        });
      });
  };

  const addScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    script.innerHTML = `
    window.dataLayer1 = window.dataLayer1 || [];
    function gtag() {
      dataLayer1.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "UA-228483250-1");
  `;
    document.getElementsByTagName("body")[0].appendChild(script);
  };

  const validateForm = () => {
    let errors = state.errors;

    errors.defaultError = "";

    errors.firstName =
      formDetails.firstName.length > 0 ? "" : "Please enter valid first name.";
    errors.lastName =
      formDetails.lastName.length > 0 ? "" : "Please enter valid last name.";
    errors.email =
      formDetails.email.length > 0 ? "" : "Please enter valid email.";
    errors.phoneNumber =
      formDetails.phoneNumber.length === 13
        ? ""
        : "Please enter valid phone number.";
    dispatch({
      type: Action_Type.handleError,
      payload: { errors: { ...errors } },
    });
  };

  const isValidForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };
  // const handlePhoneNumber = (number) => {
  //   dispatch({
  //     type: Action_Type.handleInputChange,
  //     payload: { name: "phoneNumber", value: number },
  //   });
  // };

  return (
    <>
      <div className={`modalOverlayWithBlur`} />

      {!state.isRegistered ? (
        <div className={`${styles["container"]}`}>
          <div className={styles["box"]}>
            <h1>REGISTER NOW</h1>
            <form onSubmit={handleSubmit} className={styles["signin-form"]}>
              <label
                htmlFor={inputs[0].name}
                style={{ marginBottom: "0.5rem" }}
              >
                {inputs[0].label}
              </label>
              <div style={{ display: "flex", gap: "1rem" }}>
                <input
                  id={inputs[0].name}
                  name={inputs[0].name}
                  className={inputs[0].className}
                  type={inputs[0].type}
                  placeholder={inputs[0].placeholder}
                  value={state[inputs[0].name]}
                  required={inputs[0].required}
                  disabled={inputs[0].disabled}
                  readOnly={inputs[0].readOnly}
                  onChange={(e) => handleInputChange(e)}
                />
                <input
                  id={inputs[1].name}
                  name={inputs[1].name}
                  className={inputs[1].className}
                  type={inputs[1].type}
                  placeholder={inputs[1].placeholder}
                  value={state[inputs[1].name]}
                  required={inputs[1].required}
                  disabled={inputs[1].disabled}
                  readOnly={inputs[1].readOnly}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              {inputs.map((input) => (
                <div
                  key={input.name}
                  className={"input-group"}
                  style={{ marginBottom: "0.8rem" }}
                >
                  {input.name !== "firstName" && input.name !== "lastName" && (
                    <label htmlFor={input.name}>{input.label}</label>
                  )}
                  {input.name === "phoneNumber" ? (
                    <Input
                      style={{ marginTop: "0.8rem" }}
                      country="IN"
                      international
                      withCountryCallingCode
                      className="form-control"
                      name="phoneNumber"
                      value={state[input.name]}
                      id="phone"
                      placeholder="Enter Mobile Number"
                      onChange={handlePhoneNumber}
                    />
                  ) : input.name !== "firstName" &&
                    input.name !== "lastName" ? (
                    <input
                      style={{ marginTop: "0.8rem" }}
                      id={input.name}
                      name={input.name}
                      className={input.className}
                      type={input.type}
                      placeholder={input.placeholder}
                      value={state[input.name]}
                      required={input.required}
                      disabled={input.disabled}
                      readOnly={input.readOnly}
                      onChange={(e) => handleInputChange(e)}
                    />
                  ) : null}
                  {state.errors[input.name] && (
                    <div className="error">{state.errors[input.name]}</div>
                  )}
                </div>
              ))}
              {state.errors.defaultError && (
                <div className="error">{state.errors.defaultError}</div>
              )}
              <button
                disabled={state.isLoading}
                className={`btn ${styles["submit-btn"]}`}
              >
                {state.isLoading ? (
                  <img src={LoaderImg} alt="" height="100%" />
                ) : (
                  "JOIN"
                )}
              </button>
            </form>
            <div onClick={handleJoinModalClose} className={styles["closeBtn"]}>
              <img src={closebtnImg} alt="close" />
            </div>
          </div>
        </div>
      ) : (
        <div className={`${styles["container"]}`}>
          <div className={`${styles["box"]} ${styles["registeredContainer"]}`}>
            <div className={`${styles["text1"]}`}>
              Dear Dr. {formDetails.firstName},
              <br />
              Thank you, you have been
              <br />
              registered successfully
            </div>
            <div className={`${styles["textThankYou"]}`}>
              <img
                src={thankYouImg}
                alt="Thank You"
                height="100%"
                width="100%"
              />
            </div>
            <div onClick={handleJoinModalClose} className={styles["closeBtn"]}>
              <img src={closebtnImg} alt="close" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JoinModal;

//Imports
import { DETAILS_COLLECTION } from '../../AppConstants/CollectionConstants';
// Firebase
import firebase, { firestore } from '../../firebase/firebase';

const updateDetail = async (detailId, { targetId, content, type }) => {
	try {
		const docRef = firestore.collection(DETAILS_COLLECTION).doc(detailId);

		await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(docRef);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No detailId found'
				};
				throw err;
			}

			const oldData = doc.data();
			const details = {
				targetId: targetId ? targetId : oldData.targetId,
				content: content ? content : oldData.content,
				type: type ? type : oldData.type,
				updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
			};

			transcation.update(docRef, details);
		});
		return detailId;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const put = {
	updateDetail
};

export default put;

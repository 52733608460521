export const VIDEO_COLLECTION = "videos";
export const LIKES_COLLECTION = "likes";
export const RATINGS_COLLECTION = "ratings";
export const TRANSCIPT_COLLECTION = "transcript";
export const MEDIA_METADATA_COLLECTION = "MediaMetaData";
export const DETAILS_COLLECTION = "details";
export const BANNER_COLLECTION = "banners";
export const BACKSTAGE_COLLECTION = "backstage";

export const SPEAKERS_COLLECTION = "speakers";
export const SPEAKER_FOLLOWER_COLLECTION = "SpeakerFollower";

export const USER_COLLECTION = "profiles";
export const USER_METADATA_COLLECTION = "UserMetaData";
export const USER_PREFERENCE_COLLECTION = "UserPreferences";
export const USER_MEDIA_METADATA_COLLECTION = "UserMediaMetaData";
export const USER_BOOKMARKS = "UserBookmarks";
export const USER_NOTES = "UserNotes";

export const SERIES_COLLECTION = "series";
export const SEASON_COLLECTION = "seasons";

export const DISLIKES_COLLECTION = "dislikes";

export const WEBINAR_COLLECTION = "webinar";
export const WEBINAR_ATTENDED_COLLECTION = "webinar_attended";
export const AGENDA_COLLECTION = "agendas";
export const POLLS_COLLECTION = "polls";
export const POLLS_RESPONSE_COLLECTION = "pollsresponse";
export const QA_COLLECTION = "qa";
export const QA_RESPONSE_COLLECTION = "qaresponse";
export const USER_FEEDBACK_COLLECTION = "userFeedback";
export const RESOURCES_COLLECTION = "resources";
export const RECENTLY_ADDED_DOCUMENT = "recentlyAdded";
export const LIVE_REACTION_COLLECTION = "liveReaction";

export const STATIC_WEBINAR_DOC = "staticWebinars";

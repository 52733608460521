import { createContext, useContext, useState } from "react";
import { USER_METADATA_COLLECTION } from "../../AppConstants/CollectionConstants";
import { DESCENDING, TIMESTAMP } from "../../AppConstants/SortConstants";
import { firestore } from "../../firebase/firebase";
import UserManager from "../../Managers/Users";
import { UserContext } from "../UserContext/UserContextProvider";
// import closeImg from "../../../../assets/images/close.png";
import eventSpeaker1 from "../../assets/images/networking/1.png";
import eventSpeaker2 from "../../assets/images/networking/2.png";
import eventSpeaker3 from "../../assets/images/networking/3.png";
import eventSpeaker4 from "../../assets/images/networking/4.png";
import { removeNotification } from "../../Managers/Chat";

export const networkContext = createContext();

const networkingList1 = [
  {
    name: "Dr.Raghu Satyanarayan",
    designation: "",
    city: "Noida",
    photoUrl: eventSpeaker1,
    common: 2,
    events: 8,
  },
  {
    name: "Dr.Adarsh Seth",
    designation: "",
    city: "Mumbai",
    photoUrl: eventSpeaker2,
    common: 4,
    events: 6,
  },
  {
    name: "Dr.Jay Shah",
    designation: "",
    city: "Kolkata",
    photoUrl: eventSpeaker3,
    common: 8,
    events: 3,
  },
  {
    name: "Dr. Rama K. Shetty",
    designation: "",
    city: "Delhi",
    photoUrl: eventSpeaker4,
    common: 5,
    events: 7,
  },
];
export default function NetworkContextProvider(props) {
  const {
    userMetadata,
    user,
    toggleChatSection: _toggleChatSection,
  } = useContext(UserContext);
  const [chatUser, setChatUser] = useState(null);
  const [roomId, setRoomId] = useState("");

  const [chatSectionStatus, setChatSectionStatus] = useState(false);

  const getSortedWebinars = async (
    webinarID,
    based_on = TIMESTAMP,
    order = DESCENDING,
    lastDocRef = null,
    limit = 5,
    ignoreLimit = false
  ) => {
    try {
      let docRef = firestore.collection(USER_METADATA_COLLECTION);

      docRef = docRef.where("webinars", "array-contains", webinarID);

      docRef = docRef.orderBy(based_on, order);
      if (!ignoreLimit) docRef = docRef.limit(limit);

      if (lastDocRef) docRef = docRef.startAfter(lastDocRef);
      const query = await docRef.get();

      if (query.empty) {
        return [];
      }

      // Updating last docRef
      lastDocRef = query.docs[query.docs.length - 1];

      let _data = [];
      query.docs.forEach((doc) => {
        _data.push(doc.data());
      });
      return { data: _data, lastDocRef };
    } catch (error) {
      console.log("Error :: ", error);
      throw error;
    }
  };

  function getRoomIdWithUser(currentUserId, otherUserId) {
    let id = "";
    if (currentUserId > otherUserId) {
      id = currentUserId + "+" + otherUserId;
    } else {
      id = otherUserId + "+" + currentUserId;
    }
    return id;
  }

  const getNetworkingData = async (
    webinarId,
    limit = 5,
    ignoreLimit = false
  ) => {
    // console.log("DSDsdssdsd", webinarId);
    let data = await getSortedWebinars(
      webinarId,
      TIMESTAMP,
      DESCENDING,
      null,
      limit,
      ignoreLimit
    );
    let commonCount = {};
    let users = await Promise.all(
      data.data.map((_data) => {
        let { userId } = _data;

        _data.webinars.forEach((_wid) => {
          if (userMetadata.webinars.indexOf(_wid) !== -1) {
            if (commonCount.hasOwnProperty(userId)) {
              commonCount[userId] += 1;
            } else {
              commonCount[userId] = 1;
            }
          }
        });
        return UserManager._get.getUser(userId);
      })
    );

    let _users = users.map((_uData) => ({
      ..._uData,
      commonCount: commonCount[_uData.userid],
      photoUrl:
        networkingList1[Math.floor(Math.random() * networkingList1.length)]
          .photoUrl,
    }));

    return _users;
    // setNetworkingList();
  };

  const toggleChatSection = (status) => {
    setChatSectionStatus(status);
  };

  const updateRoomId = (roomId) => {
    setRoomId(roomId);
  };

  const openChat = (webinarDetails_id, sendUserData) => {
    console.log("openChat", sendUserData);
    _toggleChatSection(true);
    setChatUser(sendUserData);
    let generatedRoomId = getRoomIdWithUser(user.uid, sendUserData.userid);
    updateRoomId(generatedRoomId);
    removeNotification(webinarDetails_id, user.uid, sendUserData.userid);
  };

  return (
    <networkContext.Provider
      value={{
        getNetworkingData,
        getRoomIdWithUser,
        roomId,
        updateRoomId,
        openChat,
        chatUser,
        setChatUser,
      }}
    >
      {props.children}
    </networkContext.Provider>
  );
}

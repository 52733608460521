export const RootRoute = "/";
export const HOME_ROUTE = "/home";
export const SEARCH_ROUTE = "/search";
export const VIDEO_PAGE_ROUTE = "/videos";
export const SPEAKER_INFO_ROUTE = "/speakerInfo/:speakerId";
export const VIDEO_DETAIL_ROUTE = "/video/:videoId";
export const SERIES_DETAIL_ROUTE = "/series/:seriesId";
export const WEBINAR_PAGE_ROUTE = "/webinar";
export const WEBINAR_DETAIL_ROUTE = "/webinar/:webinarID";
export const NOTES_ROUTE = "/notes";
export const SPEAKERS_ROUTE = "/speakers/:speakerID";
export const CONVERSE_EVENT = "converse/esh2022highlights";

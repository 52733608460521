import { createContext, useContext, useEffect, useState } from "react";
import { FirebaseErrorCodes } from "../../AppConstants/FirebaseErrorCodes";
import { AuthManager } from "../../firebase/firebase";
import { resetUserLoginHook } from "../../utils/UserLoginHook";
import { UserContext } from "../UserContext/UserContextProvider";

export const authContext = createContext();

const DefaultFormDetails = {
  fName: "",
  lName: "",
  email: "",
  profession: "",
  speciality: "",
  city: "",
  state: "",
  country: "",
  pincode: null,
  phoneNumber: "",
  terms: false,
  marketing: true,
};

export default function AuthContextProvider(props) {
  const { user } = useContext(UserContext);

  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showProfile, setShowProfile] = useState(true);
  const [showOTPScreen, setShowOTPScreen] = useState(false);
  const [showPersonalDetailsScreen, setShowPersonalDetailsScreen] =
    useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formDetails, setFormDetails] = useState(DefaultFormDetails);

  const [phoneNumber, setPhoneNumber] = useState("");

  const toggleSignInbtn = () => {
    if (showSignUpModal) {
      setShowSignUpModal(false);
    }
    setShowSignInModal(true);
  };
  const toggleSignUpBtn = () => {
    if (showSignInModal) {
      setShowSignInModal(false);
    }
    setShowSignUpModal(true);

    setShowSignInModal(false);
    setError(false);
    setLoading(false);
    setPhoneNumber("");
    setFormDetails(DefaultFormDetails);
    setShowProfile(true);
    setShowOTPScreen(false);
    setShowPersonalDetailsScreen(false);
  };

  const closeSignInModal = () => {
    setShowSignInModal(false);
    setError(false);
    setLoading(false);
    setPhoneNumber("");
    setFormDetails(DefaultFormDetails);
    setShowProfile(true);
    setShowSignUpModal(false);
    setShowOTPScreen(false);

    // to rest userLoginHooks if any attachec
    resetUserLoginHook();
  };
  const closeSignUpModal = () => {
    setShowSignUpModal(false);
    // to rest userLoginHooks if any attachec
    resetUserLoginHook();
  };

  const handlePhoneNumber = (number) => {
    setPhoneNumber(number);
  };

  const handleBtn = ({ type, value }) => {
    if (type === "showprofile") {
      setShowOTPScreen(false);
      setShowProfile(value);
      return;
    }
    if (type === "showotp") {
      setShowProfile(false);
      setShowOTPScreen(value);
      return;
    }
    if (type === "showpersonaldetails") {
      setShowOTPScreen(false);
      setShowPersonalDetailsScreen(value);
      return;
    }
  };
  const handleSignUpform = (value) => {
    if (error) {
      setError(false);
    }
    setFormDetails((prev) => ({ ...prev, ...value }));
  };

  const handleLoginBtn = async (e) => {
    try {
      e.preventDefault();
      if (phoneNumber.trim().length === 0 || phoneNumber.trim().length !== 13) {
        return;
      }
      setLoading(true);

      let _phoneNumber = phoneNumber.trim();
      let email = `${_phoneNumber}@docflix.com`;
      let pwd = _phoneNumber;

      await AuthManager.login(email, pwd);
      setLoading(false);
    } catch (error) {
      console.error(error);
      if (error.code === FirebaseErrorCodes.AUTH_USER_NOT_FOUND) {
        setError(true);
        setLoading(false);
      }
    }
  };

  const handleLoading = (bool) => {
    setLoading(bool);
  };

  useEffect(() => {
    if (user) {
      setShowSignUpModal(false);
      setShowSignInModal(false);
      setError(false);
      setLoading(false);
      setPhoneNumber("");
      setFormDetails(DefaultFormDetails);
      setShowProfile(true);
      setShowOTPScreen(false);
      setShowPersonalDetailsScreen(false);
    }
  }, [user]);

  return (
    <authContext.Provider
      value={{
        error,
        loading,
        phoneNumber,
        showSignUpModal,
        showSignInModal,
        showProfile,
        showOTPScreen,
        showPersonalDetailsScreen,
        formDetails,
        toggleSignInbtn,
        toggleSignUpBtn,
        closeSignInModal,
        closeSignUpModal,
        handlePhoneNumber,
        handleBtn,
        handleLoginBtn,
        handleSignUpform,
        handleLoading,
        setError,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
}

import fb from "../../assets/images/fb.png";
import twitter from "../../assets/images/twitter.png";
import linkedin from "../../assets/images/linkedin.png";
import insta from "../../assets/images/insta.png";
import fbDark from "../../assets/images/fbDark.png";
import twitterDark from "../../assets/images/twitterDark.png";
import linkedinDark from "../../assets/images/linkedinDark.png";
import instaDark from "../../assets/images/instaDark.png";
export const QUICK_LINKS = [
  {
    name: "Videos",
    url: "/videos",
  },
  {
    name: "Events",
    url: "/webinar",
  },
  {
    name: "Podcast",
    url: "",
  },
  {
    name: "News",
    url: "",
  },
];

export const TOP_CATEGORIES = [
  { name: "Cardiology", url: "" },
  { name: "Dermatology", url: "" },
  { name: "Medico Legal", url: "" },
  { name: "Anesthesiology", url: "" },
  { name: "Breast cancer", url: "" },
  { name: "Melanoma", url: "" },
  { name: "Leukemia", url: "" },
];

export const SUPPORT_LINKS = [
  { name: "Help Center", url: "" },
  { name: "FAQ", url: "" },
  { name: "Contact us", url: "" },
  { name: "Privacy", url: "" },
  { name: "Media Center", url: "" },
];

export const LINKS = [
  {
    name: "Quick Links",
    links: QUICK_LINKS,
  },
  {
    name: "Top Categories",
    links: TOP_CATEGORIES,
  },
  {
    name: "Support",
    links: SUPPORT_LINKS,
  },
];

export const SOCIAL_LINKS = [
  {
    img: fb,
    imgDark: fbDark,
    link: "https://www.facebook.com/Docflix-Pharma-114835064565351",
    target: "_blank",
    title: "Facebook",
  },
  {
    img: linkedin,
    imgDark: linkedinDark,
    link: "https://www.linkedin.com/company/docflix",
    target: "_blank",
    title: "Linkedin",
  },
  {
    img: insta,
    imgDark: instaDark,
    link: "https://www.instagram.com/docflix_mankind/?hl=en",
    target: "_blank",
    title: "Instagram",
  },
  {
    img: twitter,
    imgDark: twitterDark,
    link: "https://twitter.com/Docflix_Mankind",
    target: "_blank",
    title: "Twitter",
  },
];

import { useContext, useState } from "react";
import { useTheme } from "../../contexts/ThemeContext/ThemeContext";
import close from "../../assets/images/close.png";
import LoaderImg from "../../assets/images/Loader.gif";
import copy from "../../assets/images/copy.png";

import insta from "../../assets/images/insta.png";
import linkedin from "../../assets/images/linkedin.png";
import twitter from "../../assets/images/twitter.png";
import fb from "../../assets/images/fb.png";
import whatsapp from "../../assets/images/whatsapp.png";

import instaDark from "../../assets/images/instaDark.png";
import linkedinDark from "../../assets/images/linkedinDark.png";
import twitterDark from "../../assets/images/twitterDark.png";
import fbDark from "../../assets/images/fbDark.png";
import whatsappDark from "../../assets/images/whatsappDark.png";

import styles from "./InviteModal.module.scss";
import { UserContext } from "../../contexts/UserContext/UserContextProvider";
import { cloudFunction_as } from "../../firebase/firebase";
import { CloudFunctionName } from "../../AppConstants/CloudFunctionName";
import { showToast, validateEmail } from "../../utils/common.utils";
import { AnalyticsContext } from "../../contexts/Analytics/AnalyticsContextProvider";
import {
  INVITEYOURFRIEND_EVENT_EMAIL,
  INVITEYOURFRIEND_EVENT_FACEBOOK,
  INVITEYOURFRIEND_EVENT_LINKEDIN,
  INVITEYOURFRIEND_EVENT_TWITTER,
  INVITEYOURFRIEND_EVENT_WHATSAPP,
} from "../../AppConstants/AnalyticsEventName";
import ScoreManager from "../../Managers/ScoringManager/post";

const getVideoMessage = (details) => {
  return `Check out this Video on "${details.title}" on the docflix platform by registering on the link : `;
};

const getWebinarMessage = (details) => {
  return `Check out the "${details.title}" on the docflix platform by registering at the following link :`;
};

const getSeriesMessage = (details) => {
  if (details.seriesData) {
    return `Check out new series "${details.seriesData.title}" on the docflix platform by registering at the following link :`;
  } else {
    return `Check out new series "${details.title}" on the docflix platform by registering at the following link :`;
  }
};

const getMessage = (type, details) => {
  switch (type) {
    case "event":
      return getWebinarMessage(details);
    case "series":
      return getSeriesMessage(details);
    default:
      return getVideoMessage(details);
  }
};

const InviteModal = (props) => {
  const origin = window.location.origin;
  const { handleClose } = props;
  const { isDark } = useTheme();
  const { addGAWithUserInfo } = useContext(AnalyticsContext);
  const [textArea, setTextarea] = useState({});
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  let selectUrl = `${
    props.type === "event"
      ? `${origin}/webinar/${props.details.id}`
      : props.type === "series"
      ? window.location.href
      : `${origin}/video/${props.details.id}`
  }`;

  const [url, setUrl] = useState(selectUrl);
  const { user, userBasicDetails } = useContext(UserContext);

  const [urlShareWa, setUrlShare] = useState(
    encodeURIComponent(
      props.message
        ? props.message
        : `${getMessage(
            props.type,
            props.details
          )} ${selectUrl}?utm_medium=referral${`&`}utm_source=whatsapp&utm_content=${encodeURIComponent(
            user.phoneNumber
          )}/`
    )
  );
  // Check out the { Webinar Name} on the docflix platform by registering at the following link : (Webinar link)
  // Check out this Video on (Topic ) on the docflix platform by registering on the link - (Video Link).

  const [urlShareTwitter, setUrlShareTwitter] = useState(
    encodeURIComponent(
      props.message
        ? props.message
        : `${getMessage(
            props.type,
            props.details
          )} ${selectUrl}?utm_medium=referral&utm_source=twitter&utm_content=${encodeURIComponent(
            user.phoneNumber
          )}/`
    )
  );

  const linkedInURL = encodeURIComponent(
    `${selectUrl}&title=${props.details}&summary=${
      props.details.description
    }&source=${"Docflix"}?utm_medium=referral&utm_source=linkedin&utm_content=${encodeURIComponent(
      user.phoneNumber
    )}`
  );

  const mailService = cloudFunction_as.httpsCallable(
    CloudFunctionName.MAIL_SERVICE
  );

  const copyCodeToClipboard = () => {
    textArea.disabled = false;
    const el = textArea;
    el.select();
    document.execCommand("copy");
    showToast("Copied to clipboard!!");
    textArea.disabled = true;
    ScoreManager.pointsOnShare(user.uid);
  };

  const sendMail = () => {
    return new Promise((res, rej) => {
      setError(false);
      setLoading(true);
      if (
        email === "" &&
        email === user.email &&
        email === userBasicDetails.email
      ) {
        setError(true);
        setLoading(false);
        return;
      }
      if (!validateEmail(email)) {
        setError(true);
        setLoading(false);
        return;
      }

      mailService(
        JSON.stringify({
          type: props.type === "event" ? "eventInviteFriends" : "shareVideo",
          body: {
            name: user.displayName,
            senderMail: `${email.toLowerCase()}`,
            title: `${props.details.title}`,
            event: `${props.details.id}`,
            eventUrl: `${selectUrl}?utm_medium=referral&utm_source=email&utm_content=${encodeURIComponent(
              user.phoneNumber
            )}`,
          },
        })
      )
        .then((res) => {
          setEmail("");
          showToast("Mail sent successfully!!", { type: "success" });
          setLoading(false);
          addAnalytics(INVITEYOURFRIEND_EVENT_EMAIL);
        })
        .catch((err) => {
          // console.log(err);
          setError(true);
          showToast("Failed: Mail not sent.", { type: "error" });
          setLoading(false);
        });
    });
  };

  const addAnalytics = (name) => {
    addGAWithUserInfo(name, { eventId: props.details.id });
    ScoreManager.pointsOnShare(user.uid);
  };

  const socialIcons = [
    {
      title: "Facebook",
      analyticsConstant: INVITEYOURFRIEND_EVENT_FACEBOOK,
      img: fb,
      imgDark: fbDark,
      enable: true,
      href: `https://www.facebook.com/sharer/sharer.php?u=${url}?utm_medium=referral&utm_source=facebook&utm_content=${encodeURIComponent(
        user.phoneNumber
      )}`,
    },
    {
      title: "Linkedin",
      analyticsConstant: INVITEYOURFRIEND_EVENT_LINKEDIN,
      img: linkedin,
      imgDark: linkedinDark,
      enable: true,
      href: `https://www.linkedin.com/shareArticle?mini=true&url=${linkedInURL}`,
    },
    {
      title: "Instagram",
      analyticsConstant: INVITEYOURFRIEND_EVENT_FACEBOOK, //update later
      img: insta,
      imgDark: instaDark,
      enable: false,
      href: `https://www.facebook.com/sharer/sharer.php?u=${url}?utm_medium=referral&utm_source=facebook&utm_content=${encodeURIComponent(
        user.phoneNumber
      )}`,
    },
    {
      title: "Twitter",
      analyticsConstant: INVITEYOURFRIEND_EVENT_TWITTER,
      img: twitter,
      imgDark: twitterDark,
      enable: true,
      href: `https://twitter.com/intent/tweet?url=${selectUrl}&${urlShareTwitter}`,
    },
    {
      title: "WhatsApp",
      analyticsConstant: INVITEYOURFRIEND_EVENT_WHATSAPP,
      img: whatsapp,
      imgDark: whatsappDark,
      enable: true,
      href: `https://wa.me/?text=${urlShareWa}`,
    },
  ];

  return (
    <>
      <div onClick={handleClose} className="modalOverlayWithTint" />
      <div className={`${styles.container} ${props.customClass || ""}`}>
        <div className={styles.header}>
          <div className={styles.heading}>Invite</div>
          <div onClick={handleClose} className={styles.closeBtn}>
            <img src={close} alt="Close" />
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.inputContainer}>
            <input
              type="text"
              placeholder="Enter url"
              value={url}
              ref={(textarea) => {
                setTextarea(textarea);
              }}
              onChange={(e) => {
                setUrl(e.target.value);
              }}
              disabled={true}
            />
            <div className={styles.copyBtnContainer}>
              <img
                onClick={copyCodeToClipboard}
                className={styles.copyBtn}
                src={copy}
                alt=""
              />
              <span className={styles.tooltiptext}>Click here to copy</span>
            </div>
          </div>
          <div className={styles.shareContainer}>
            <div>Share via</div>
            <div className={styles.shareIcons}>
              {socialIcons.map(
                (icon, index) =>
                  icon.enable && (
                    <a
                      href={icon.href}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.icon}
                      key={icon.title}
                      title={icon.title}
                      onClick={() => {
                        addAnalytics(icon.analyticsConstant);
                      }}
                    >
                      <img
                        src={!isDark ? icon.imgDark : icon.img}
                        alt={icon.title}
                      />
                    </a>
                  )
              )}
            </div>
          </div>
          <div className={styles.break}>OR</div>
          <div className={styles.emailContainer}>
            <label htmlFor="sendEmail">Email ID</label>
            <div className={styles.inputContainer}>
              <input
                id="sendEmail"
                type="text"
                placeholder="Enter email ID"
                value={email}
                onFocus={() => error && setError(false)}
                onChange={(e) => {
                  e.preventDefault();
                  setEmail(e.target.value);
                }}
              />
              <button
                onClick={sendMail}
                disabled={isLoading ? true : false}
                className={`btn ${styles.sendBtn}`}
              >
                {isLoading ? (
                  <img
                    id="btnLoader"
                    src={LoaderImg}
                    height="100%"
                    alt="loading"
                  />
                ) : (
                  "Send"
                )}
              </button>
            </div>
            {error && (
              <div className={"error"}>
                <span>*Enter a valid email.</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteModal;

import React, { useContext, useEffect, useMemo } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import VideoDetails from "./components/VideoDetails";
// import SeriesDetails from "./components/SeriesDetails";
import AppLayout from "./layouts/AppLayout";
// import Upload from "./components/Uploads/upload";
import ScrollToTop from "./utils/ScrollToTop";
// import Analytics from "./components/Analytics";

// import {
//   HomeScene,
//   Notes,
//   VideoSearchPage,
//   VideosPage,
//   WebinarDetails,
//   WebinarPage,
//   SpeakerProfilePage,
//   LandingPage,
// } from "./scenes";
import { UserContext } from "./contexts/UserContext/UserContextProvider";
import { VIDEO_DETAIL_ROUTE } from "./AppConstants/Routes";
import Converse from "../src/scenes/Converse";
import { static_page_event_id } from "./AppConstants/Constants";

const Router = () => {
  const { user } = useContext(UserContext);

  return (
    <BrowserRouter>
      {/* Add session context for login/signup and user data */}
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`/`}
            render={() => {
              return <Redirect to={`/${static_page_event_id}`} />;
            }}
          />
          <RouteWrapper
            exact
            path={`/${static_page_event_id}`}
            // privateRoute={!user}
            layout={AppLayout}
            scene={Converse}
          />
          {/* <Route
            exact
            path={"/mankind"}
            render={() => {
              const search = window.location.search;
              return (
                <Redirect
                  to={
                    "/mankind/docflix?" + new URLSearchParams(search).toString()
                  }
                />
              );
            }}
          /> */}
          {/* <RouteWrapper
            exact
            path="/mankind/docflix"
            // privateRoute={!user}
            layout={AppLayout}
            scene={Converse}
            teamId="mankind"
          />
          <RouteWrapper
            exact
            path="/mankind/docflix/team"
            // privateRoute={!user}
            layout={AppLayout}
            scene={Converse}
            teamId="mankind"
          /> */}

          <Route
            exact
            path={`/mankind/${static_page_event_id}`}
            render={() => {
              const search = window.location.search;
              return (
                <Redirect
                  to={
                    `/mankind/${static_page_event_id}/docflix?` +
                    new URLSearchParams(search).toString()
                  }
                />
              );
            }}
          />
          <RouteWrapper
            exact
            path={`/mankind/${static_page_event_id}/docflix`}
            // privateRoute={!user}
            layout={AppLayout}
            scene={Converse}
            teamId="mankind"
          />
          <RouteWrapper
            exact
            path={`/mankind/${static_page_event_id}/docflix/team`}
            // privateRoute={!user}
            layout={AppLayout}
            scene={Converse}
            teamId="mankind"
          />

          {/* <Route
            exact
            path={RootRoute}
            render={() =>
              user ? <Redirect to={HOME_ROUTE} /> : <LandingPage />
            }
          /> */}

          {/* <RouteWrapper
            exact
            path={VIDEO_PAGE_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={VideosPage}
          />
          <RouteWrapper
            exact
            path={SEARCH_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={VideoSearchPage}
          />
          <RouteWrapper
            exact
            path={SPEAKER_INFO_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={SpeakerProfilePage}
          />
          <RouteWrapper
            exact
            path={VIDEO_DETAIL_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={VideoDetails}
          />
          <RouteWrapper
            exact
            path={SERIES_DETAIL_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={SeriesDetails}
          />
          <RouteWrapper
            exact
            path={WEBINAR_PAGE_ROUTE}
            // privateRoute={!user}
            layout={AppLayout}
            scene={WebinarPage}
          />
          <RouteWrapper
            exact
            path={WEBINAR_DETAIL_ROUTE}
            // privateRoute={!user}
            layout={AppLayout}
            scene={WebinarDetails}
          /> */}
          {/* This can be wrapper under videos kept outside for guest/private view */}
          {/* <RouteWrapper
            // exact
            path="/video/:videoId?season=:seasonId#:tabId"
            layout={AppLayout}
            scene={VideoDetails}
          />
          <RouteWrapper
            // exact
            path="/series/:seriesId?season=:seasonId#:tabId"
            layout={AppLayout}
            scene={VideoDetails}
          /> */}
          {/* <RouteWrapper
            // exact
            path="/analytics/:webinarID"
            privateRoute={!user}
            layout={AppLayout}
            scene={Analytics}
          />

          <RouteWrapper
            exact
            path="/uploads"
            // privateRoute={!user}
            layout={AppLayout}
            scene={Upload}
          />
          <RouteWrapper
            exact
            path={NOTES_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={Notes}
          /> */}
          <RouteWrapper
            exact
            path={VIDEO_DETAIL_ROUTE}
            privateRoute={!user}
            layout={AppLayout}
            scene={VideoDetails}
          />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

const RouteWrapper = ({
  scene: Scene,
  layout: Layout,
  privateRoute,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        privateRoute ? (
          <Redirect to={`/?return=${window.location.href}`} />
        ) : (
          <Layout {...props}>
            <Scene props={{ ...props, ...rest }} />
          </Layout>
        )
      }
    />
  );
};

export default Router;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {
  SpeakerContextProvider,
  VideoPlayerContextProvider,
  UserContextProvider,
  MediaMetadataContextProvider,
  RatingContextProvider,
  LikeContextProvider,
  AuthContextProvider,
  WebinarContextProvider,
  VideoContextProvider,
  ThemeProvider,
  AnalyticsContextProvider,
  NewAuthContextProvider,
  NetworkContextProvider,
} from "./contexts";

import "react-phone-number-input/style.css";
import "./styling/global.css";
import smoothscroll from "smoothscroll-polyfill";
import "./Managers/webinarTest";
// import { checksendOTP } from "./Managers/cf";
import ConstantDataContextProvider from "./contexts/ConstantContext/ConstantDataContextProvider";

smoothscroll.polyfill();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <UserContextProvider>
        <ConstantDataContextProvider>
          <AnalyticsContextProvider>
            <NewAuthContextProvider>
              <AuthContextProvider>
                <SpeakerContextProvider>
                  <WebinarContextProvider>
                    <NetworkContextProvider>
                      <VideoContextProvider>
                        <LikeContextProvider>
                          <RatingContextProvider>
                            <MediaMetadataContextProvider>
                              <VideoPlayerContextProvider>
                                <App />
                              </VideoPlayerContextProvider>
                            </MediaMetadataContextProvider>
                          </RatingContextProvider>
                        </LikeContextProvider>
                      </VideoContextProvider>
                    </NetworkContextProvider>
                  </WebinarContextProvider>
                </SpeakerContextProvider>
              </AuthContextProvider>
            </NewAuthContextProvider>
          </AnalyticsContextProvider>
        </ConstantDataContextProvider>
      </UserContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);

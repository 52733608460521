export const ScorePoints = {
	LIKE: 5,
	RATE: 5,
	SHARE: 5,
	VIDEO_COMPLETE: 10,
	WEBINAR_ATTENDED: 15,
	FEEDBACK: 20,
	NETWORKING: 20
};

export const Badges = {
	CAREGIVER: 50,
	DEFENDER: 100,
	GUARDIAN: 250,
	MASTER: 500,
	CHAMPION: 1000,
	WIZARD: 1500,
	ORACLE: 2500
};

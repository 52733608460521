import { useState, useRef, useEffect } from "react";
import styles from "./index.module.scss";
import brandLogo from "../../assets/images/D-Light.png";
import brandLogoLight from "../../assets/images/DLight.png";
import { useTheme } from "../../contexts/ThemeContext/ThemeContext";
import LinkMapper from "./LinkMapper";
import { LINKS, SOCIAL_LINKS } from "./links";
import parallax1 from "../../assets/images/parallax1.png";
import parallax2 from "../../assets/images/parallax2.png";
import parallax3 from "../../assets/images/parallax3.png";
import parallax4 from "../../assets/images/parallax4.png";
import Rellax from "rellax";
import { FooterNew } from "./FooterNew";

function ContactFooter({ zIndexZero = false }) {
  const { isDark } = useTheme();
  const [activeIndex, setActiveIndex] = useState(0);

  const parallax1Img = useRef(null);
  const parallax2Img = useRef(null);
  const parallax3Img = useRef(null);
  const parallax4Img = useRef(null);

  useEffect(() => {
    new Rellax(parallax1Img.current, {
      speed: 4,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
    new Rellax(parallax2Img.current, {
      speed: 3.25,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
    new Rellax(parallax3Img.current, {
      speed: 2.75,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
    new Rellax(parallax4Img.current, {
      speed: 2,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
  }, []);
  return (
    <div
      className={`${styles.contactFooter} ${
        zIndexZero ? styles.contactFooter_zIndexZero : ""
      }`}
    >
      {/*       
      <div className={styles.parallaxItem}>
        <div className={styles.plusIconDark} ref={parallax1Img}>
          <img src={parallax1} alt="" className={styles.overlayImg} />
        </div>
        <div className={styles.plusIconDark} ref={parallax2Img}>
          <img src={parallax2} alt="" className={styles.overlayImg} />
        </div>
        <div className={styles.plusIconDark} ref={parallax3Img}>
          <img src={parallax3} alt="" className={styles.overlayImg} />
        </div>
        <div className={styles.plusIconDark} ref={parallax4Img}>
          <img src={parallax4} alt="" className={styles.overlayImg} />
        </div>
      </div> */}

      <FooterNew />
      {/* <div className={styles.wrapper}>
       {/* <div className={styles.getInTouch}>
          <div>Get in touch</div>
          <div>
            For marketing, content and promotion please{" "}
            <a href="/">drop a line.</a>
          </div>
          </div>}
        <div className={styles.footerBottom}>
          <div className={styles.infoSide}>
            <div className={styles.brandName}>
              <img src={isDark ? brandLogo : brandLogoLight} alt="Docflix" />
              <span>Docflix</span>
            </div>
            <div className={styles.info}>
              Pharmacists can play a critical role in enhancing healthcare
              delivery in india
            </div>
            <div className={styles.socialLinks}>
              {SOCIAL_LINKS.map((slink, index) => {
                return (
                  <a
                  title={slink.title}
                  target={slink.target}
                    href={slink.link}
                    key={index}
                    className={styles.socialLink}
                  >
                    <img
                      src={isDark ? slink.img : slink.imgDark}
                      alt={String(slink.link)}
                    />
                  </a>
                );
              })}
            </div>
          </div>
          {LINKS.map((linkMain, index) => {
            return (
              <LinkMapper
                title={linkMain.name}
                links={linkMain.links}
                setActiveIndex={() =>
                  setActiveIndex(index === activeIndex ? -1 : index)
                }
                key={index}
                isOpen={index === activeIndex}
              />
            );
          })}
        </div>
        <div style={{ textAlign: "center" }}>
          &copy; 1996-{new Date().getFullYear()}, Mankind, Inc.
        </div>
      </div> */}
    </div>
  );
}
export default ContactFooter;

import React from "react";
import footerDocflixImg from "../../assets/images/landingPage/docflixLogo.png";
import footerMankindDocflix from "../../assets/images/landingPage/mankindLogo.png";
import { useTheme } from "../../contexts/ThemeContext/ThemeContext";
import styles from "./FooterNew.module.scss";
import { SOCIAL_LINKS } from "./links";

export const FooterNew = () => {
  const { isDark } = useTheme();
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.brandLogo}>
        <a href="#" className={styles.img1}>
          <img src={footerDocflixImg} alt="Docflix" />
        </a>
        <div className={styles.separater} />
        <a
          href="https://www.mankindpharma.com/"
          target="_blank"
          className={styles.img2}
          rel="noreferrer"
        >
          <img src={footerMankindDocflix} alt="Mankind" />
        </a>
      </div>
      <div className={styles.socialLinks}>
        {SOCIAL_LINKS.map((slink, index) => {
          return (
            <a
              title={slink.title}
              target={slink.target}
              href={slink.link}
              key={index}
              className={styles.socialLink}
            >
              <img
                src={isDark ? slink.img : slink.imgDark}
                alt={String(slink.link)}
              />
            </a>
          );
        })}
      </div>
      <div className={styles.desc}>
        <div>
          <span>&copy;</span>
          &nbsp;Mankind Pharma
        </div>
      </div>
      <a
        href="https://www.mankindpharma.com/privacy-policy"
        target="_blank"
        className={styles.privacy}
        rel="noreferrer"
      >
        Privacy Policy
      </a>
    </div>
  );
};

import { createContext, useState, useEffect, useContext } from 'react';
import UserMediaMetadataManager from '../../Managers/Users/UserMediaMetadata';
import { UserContext } from '../UserContext/UserContextProvider';

export const MediaMetadataContext = createContext([])

export default function MediaMetadataContextProvider(props) {
    const { user } = useContext(UserContext);
    const [data, setData] = useState({})

    useEffect(() => {
        if (!user) {
            return
        }
        const getMediaMetaData = async () => {
            let __data = await UserMediaMetadataManager._get.getUserAllMediaMetadata(user.uid);
            setData(__data)
        }
        getMediaMetaData()
    }, [user]);


    const updateMediaMetadata = async (targetId, lastWatchTimestamp, totalDuration, targetType,targetData) => {
        try {
            if (!user) { return }
            await UserMediaMetadataManager._post.createUserMediaMetadata({
                userId: user.uid,
                targetId,
                lastWatchTimestamp,
                totalDuration,
                targetType,
                targetData: targetData||null
            })
            setData(prev => {
                let i = { ...prev };
                i[targetId] = {
                    targetId, lastWatchTimestamp, totalDuration, targetType,targetData
                }
                return i
            })
        } catch (error) {
            console.error(error);
        }
    }

    return <MediaMetadataContext.Provider value={{ mediaMetadata: data, updateMediaMetadata }}>{props.children}</MediaMetadataContext.Provider>;
}

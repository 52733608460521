import styles from "./styles.module.scss";
import StarRatings from "react-star-ratings";
import plusImg from "../../../assets/images/plus.png";
import notesImg from "../../../assets/images/notes.png";
import likesImg from "../../../assets/images/likes.png";
import likedImg from "../../../assets/images/liked.png";
import share from "../../../assets/images/share.png";
import plusLightImg from "../../../assets/images/plusLight.png";
import notesLightImg from "../../../assets/images/notesLight.png";
import likesLightImg from "../../../assets/images/likesLight.png";
import likedLightImg from "../../../assets/images/likedLight.png";
import shareLight from "../../../assets/images/shareLight.png";
import startWatching from "../../../assets/images/startWatching.png";
import continueWatching from "../../../assets/images/continueWatching.png";
import { kFormatter, secondsToHms } from "../../../utils/common.utils";
import { useTheme } from "../../../contexts/ThemeContext/ThemeContext";
import {
  ContentType,
  LIKE_ACTION_TYPE,
  VideoType,
} from "../../../AppConstants/TypeConstants";
import { useContext, useMemo, useRef, useState } from "react";
import { LikeContext } from "../../../contexts/LikeContext/LikeContextProvider";
import { RatingContext } from "../../../contexts/RatingContext/RatingContextProvider";
import { UserContext } from "../../../contexts/UserContext/UserContextProvider";
import { AnalyticsContext } from "../../../contexts/Analytics/AnalyticsContextProvider";
import {
  VIDEO_LIKE_BTN_CLICK,
  VIDEO_RATING_CLICK,
} from "../../../AppConstants/AnalyticsEventName";
const INPUT_THROTLE_TIME = 1000;
const LIKE_ACTION_BTN_TYPE = {
  like: 1,
  dislike: 0,
};

function OverlayCardMob({
  startWatchingBtn,
  cardData,
  startingPoint,
  handleInviteBtn,
  handleNoteBtn,
  handleShare,
}) {
  const { addGAWithUserInfo } = useContext(AnalyticsContext);
  const { likeData, addLike, deleteLike } = useContext(LikeContext);
  const { ratingData, giveRating } = useContext(RatingContext);
  const { user } = useContext(UserContext);

  const ratingTimerRef = useRef(null);
  const likeTimerRef = useRef(null);

  const {
    type,
    category,
    title,
    parentCategory,
    description,
    likes,
    rating,
    seasons,
    videoType,
    duration,
    episode = undefined,
    season = undefined,
    tags,
  } = cardData;

  const hasStarted = (episode && season) ?? false;
  const { isDark } = useTheme();

  const videoLikeActionData = useMemo(() => {
    if (likeData[cardData.id]) {
      return likeData[cardData.id].actionType
        ? likeData[cardData.id].actionType
        : LIKE_ACTION_TYPE.like;
    } else {
      return null;
    }
  }, [likeData]);

  let isLiked = videoLikeActionData == LIKE_ACTION_TYPE.LIKE;
  let isDisliked = videoLikeActionData == LIKE_ACTION_TYPE.DISLIKE;

  const [__currentRating, setRating] = useState(
    ratingData[cardData.id] ? ratingData[cardData.id] : 0
  );

  const [_likes, setLike] = useState(likes);

  const performLikeAction = (btnType) => {
    if (likeTimerRef.current) {
      clearTimeout(likeTimerRef.current);
    }
    likeTimerRef.current = setTimeout(() => {
      switch (btnType) {
        case LIKE_ACTION_BTN_TYPE.like:
          if (!user) {
            return;
          }
          addGAWithUserInfo(VIDEO_LIKE_BTN_CLICK, { count: likes });
          if (isLiked) {
            setLike((prev) => prev - 1);
            deleteLike(ContentType.VIDEO, cardData.id);
          } else {
            setLike((prev) => prev + 1);
            addLike(ContentType.VIDEO, cardData.id);
          }
          break;
        case LIKE_ACTION_BTN_TYPE.dislike:
          // dislike
          if (isDisliked) {
            deleteLike(ContentType.VIDEO, cardData.id, true);
          } else {
            if (isLiked) {
              setLike((prev) => prev - 1);
            }
            addLike(ContentType.VIDEO, cardData.id, true);
          }
          break;
        default:
          return;
      }
    }, INPUT_THROTLE_TIME);
  };

  return (
    <div className={styles.overlayCardMob}>
      {/* <div className={`${styles.row}`}>
        {tags.slice(0, 3).map((entry, index) => {
          return (
            <div className={styles.tabs} key={index}>
              {entry}
            </div>
          );
        })}
      </div> */}
      <div className={`${styles.row} ${styles.infoRow}`}>
        <div>
          <div className={styles.title}>{title}</div>
          <div className={`${styles.row}`}>
            <div>{description}</div>
          </div>

          <div className={styles.subtitle}>{`${secondsToHms(
            duration
          )} mins`}</div>
        </div>
        {/* <div className={`${styles.row} ${styles.btnWrapper}`}>
          <img
            onClick={() => startWatchingBtn()}
            src={hasStarted ? continueWatching : startWatching}
            alt={hasStarted ? "Continue" : "Start"}
          />
        </div> */}
        {hasStarted ? (
          <div className={styles.progressInfo}>
            <div className={styles.durationBar}>
              <div
                className={styles.progressBar}
                style={{
                  width: `${(startingPoint / duration) * 100}%`,
                }}
              />
            </div>
            <div className={styles.playedInfo}>
              {/* {secondsToHms(startingPoint)} of {secondsToHms(duration)} */}
              {secondsToHms(startingPoint)} mins watched
            </div>
          </div>
        ) : null}
        <div className={styles.rightInfo}>
          {/* <div className={`${styles.row} ${styles.socialData}`}>
            <img
              src={isDark ? plusImg : plusLightImg}
              alt="add"
              className={styles.actionImg}
            />
            <div>Add to list</div>
          </div> */}
          <div className={`${styles.row} ${styles.socialData}`}>
            {isLiked ? (
              <img
                src={isDark ? likedImg : likedLightImg}
                alt="like"
                className={styles.actionImg}
                onClick={() => performLikeAction(LIKE_ACTION_BTN_TYPE.like)}
              />
            ) : (
              <img
                src={isDark ? likesImg : likesLightImg}
                alt="like"
                className={styles.actionImg}
                onClick={() => performLikeAction(LIKE_ACTION_BTN_TYPE.like)}
              />
            )}

            <div>{kFormatter(_likes)} Likes</div>
          </div>
          <div className={`${styles.row} ${styles.socialData}`}>
            <img
              className={`${styles.rot180} ${styles.actionImg}`}
              src={isDisliked ? likedImg : likesImg}
              alt={isDisliked ? "disliked" : "dislike"}
              onClick={() => performLikeAction(LIKE_ACTION_BTN_TYPE.dislike)}
            />
            <div> Dislike</div>
          </div>

          {user && (
            <div
              onClick={() => {
                handleInviteBtn();
                handleShare();
              }}
              className={`${styles.row} ${styles.socialData}`}
            >
              <img
                src={isDark ? share : shareLight}
                alt="share"
                className={styles.actionImg}
              />
              <div>Share</div>
            </div>
          )}

          {/* {user && (
            <div
              onClick={handleNoteBtn}
              className={`${styles.row} ${styles.socialData}`}
            >
              <img
                src={isDark ? notesImg : notesLightImg}
                alt="notes"
                className={styles.actionImg}
              />
              <div>Notes</div>
            </div>
          )} */}
        </div>
        <div
          style={{ alignSelf: "flex-start", marginTop: "0.625rem" }}
          className={`${styles.ratings}`}
        >
          <StarRatings
            rating={__currentRating}
            changeRating={(number) => {
              if (!user) {
                return;
              }
              setRating(number);
              if (ratingTimerRef.current) {
                clearTimeout(ratingTimerRef.current);
              }
              ratingTimerRef.current = setTimeout(() => {
                addGAWithUserInfo(VIDEO_RATING_CLICK, { rating: rating });
                giveRating(ContentType.VIDEO, cardData.id, number);
              }, INPUT_THROTLE_TIME);
            }}
            numberOfStars={5}
            name="rating"
            starRatedColor="#eb4547"
            starEmptyColor="transparent"
            starDimension="0.9375rem"
            svgIconViewBox="0 0 34 33"
            svgIconPath="M17.6892 26.802L25.5698 31.7948C26.5771 32.433 27.8278 31.4838 27.5289 30.3079L25.252 21.3513C25.1879 21.1018 25.1955 20.8393 25.2739 20.594C25.3523 20.3487 25.4984 20.1304 25.6954 19.9644L32.7621 14.0826C33.6907 13.3098 33.2114 11.7688 32.0184 11.6914L22.7897 11.0924C22.5411 11.0746 22.3027 10.9866 22.1022 10.8386C21.9017 10.6906 21.7474 10.4887 21.6572 10.2564L18.2153 1.58871C18.1217 1.34213 17.9554 1.12984 17.7383 0.980033C17.5212 0.830229 17.2637 0.75 17 0.75C16.7363 0.75 16.4788 0.830229 16.2617 0.980033C16.0446 1.12984 15.8783 1.34213 15.7847 1.58871L12.3428 10.2564C12.2526 10.4887 12.0983 10.6906 11.8978 10.8386C11.6973 10.9866 11.4589 11.0746 11.2103 11.0924L1.98157 11.6914C0.788588 11.7688 0.309328 13.3098 1.23787 14.0826L8.30464 19.9644C8.50158 20.1304 8.64766 20.3487 8.72609 20.594C8.80453 20.8393 8.81212 21.1018 8.74801 21.3513L6.63647 29.6576C6.27778 31.0686 7.77859 32.2076 8.98738 31.4418L16.3108 26.802C16.5167 26.6709 16.7558 26.6012 17 26.6012C17.2442 26.6012 17.4833 26.6709 17.6892 26.802V26.802Z"
          />
        </div>
        <div style={{ fontSize: "0.75rem" }}>Your Ratings</div>
      </div>
      {/* <div className={styles.ratings}>
        <div>Your Ratings</div>
        <StarRatings
          rating={__currentRating}
          changeRating={(number) => {
            if (!user) {
              return;
            }
            setRating(number);
            if (ratingTimerRef.current) {
              clearTimeout(ratingTimerRef.current);
            }
            ratingTimerRef.current = setTimeout(() => {
              giveRating(ContentType.VIDEO, cardData.id, number);
            }, INPUT_THROTLE_TIME);
          }}
          numberOfStars={5}
          name="rating"
          starRatedColor="#eb4547"
          starDimension="31px"
        />
      </div> */}
    </div>
  );
}

export default OverlayCardMob;

import { useContext, useEffect, useState } from "react";
import LoaderImg from "../../../../assets/images/Loader.gif";
import BackBtnImg from "../../../../assets/images/back.png";
import styles from "./OTPForm.module.scss";
import { AuthManager, cloudFunction_as } from "../../../../firebase/firebase";
import { CloudFunctionName } from "../../../../AppConstants/CloudFunctionName";
import { UserContext } from "../../../../contexts/UserContext/UserContextProvider";
import { newAuthContext } from "../../../../contexts/AuthContext/NewAuthContextProvider";

import { Link } from "react-router-dom";
import { windowOpenUrl } from "../../../../utils/common.utils";

const verifyOTP = cloudFunction_as.httpsCallable(CloudFunctionName.VERIFY_OTP);
const sendOTP = cloudFunction_as.httpsCallable(CloudFunctionName.SEND_OTP);

const Action_Type = {
  handleInputChange: "handleInputChange",
  handleError: "handleError",
  loading: "loading",
  handleUTM: "handleUTM",
};
const OTPForm = ({ old, data = {}, verifyCB = () => {}, showBack = true }) => {
  const {
    toggleSignInbtn,
    state,
    dispatchData,
    handleLoading,
    toggleSignUpScreens,
    handleFormDetails,
  } = useContext(newAuthContext);
  const { formDetails, loading } = state;
  const { marketing, phoneNumber, terms } = formDetails;

  let userNumber = old === undefined ? data.phoneNumber : phoneNumber;

  const { user } = useContext(UserContext);
  const [error, setError] = useState("");
  const [OTPSent, setOTPSent] = useState(old !== undefined ? true : false);
  const [OTP, setOTP] = useState({
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
  });
  const OTPDigits = OTP.digit1 + OTP.digit2 + OTP.digit3 + OTP.digit4;
  const otpbtndisable = OTPDigits.length < 4 || !terms || loading;

  useEffect(() => {
    if (old !== undefined) {
      setOTPSent(true);
    }
  }, [old]);

  const handleSendOTP = () => {
    handleLoading(true);
    setError(false);
    sendOTP(
      JSON.stringify({
        phone: userNumber,
        deviceId: window?.clientId?.toString().replaceAll(".", ""),
      })
    )
      .then((data) => {
        console.log(data);
        handleLoading(false);
        // if (data && data.data.split(" | ")[3] === "OTP sent.\n") {
        setOTPSent(true);
        // }
      })
      .catch((err) => {
        handleLoading(false);
        console.error(err);
      });
  };
  const handleVerifyOTP = async () => {
    let res = await verifyOTP(
      JSON.stringify({
        phone: userNumber,
        uid: user.uid,
        otp: OTPDigits,
        terms: terms,
        marketing: marketing,
        deviceId: window?.clientId?.toString().replaceAll(".", ""),
      })
    );
    return res;
  };

  const handleOTPchange = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    // check maxlength is 1
    if (value.length > 1) {
      return;
    }

    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 4) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=digit-${parseInt(fieldIndex, 10) + 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
          if (error && error.length > 0) {
            setError("");
          }
        }
      }
    }
    setOTP((prev) => ({
      ...prev,
      [`digit${fieldIndex}`]: value,
    }));
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    handleLoading(false);
    try {
      handleLoading(true);
      let res = await handleVerifyOTP();
      if (res && res.data.split(" | ")[3] === "OTP matched.\n") {
        handleLoading(false);
        if (old) {
          toggleSignUpScreens({ type: "showpersonaldetails", value: true });
        } else {
          toggleSignInbtn("showOTPModal", false);
        }
        verifyCB(user.uid);
      }
    } catch (error) {
      handleLoading(false);
      setError(error.message);
      console.error("error", error);
    }
  };

  const handleCancelBtn = () => {
    if(state.showSignInModal){
      dispatchData(Action_Type.loading,{
        ...state,
        isLoading: false,
        errors: { defaultError: "" },
        isRegistered: false,
        isUserVerified: false,
      })
      toggleSignInbtn("showSignInModal", false);
    }else{
      toggleSignInbtn("showOTPModal", false);
    }
    window.location.reload();
  };

  return (
    <form onSubmit={handleSubmit} className={styles["otp-form"]}>
      {old && (
        <div
          className={styles["back-btn"]}
          onClick={() =>
            toggleSignUpScreens({ type: "showprofile", value: true })
          }
        >
          <img src={BackBtnImg} alt="" />
          <span>Back</span>
        </div>
      )}
      {OTPSent ? (
        <div>
          <h1>Verify Mobile</h1>
          <p className={styles["number-text"]}>
            Please enter OTP for verifying your number
            <br />
            <span>{userNumber}</span>
          </p>
          <div className={styles["otp-container"]}>
            <input
              value={OTP.digit1}
              name="digit-1"
              onChange={handleOTPchange}
              type="number"
              min={0}
              max={9}
              autoFocus
            />
            <input
              value={OTP.digit2}
              onChange={handleOTPchange}
              type="number"
              name="digit-2"
              min={0}
              max={9}
            />
            <input
              value={OTP.digit3}
              onChange={handleOTPchange}
              name="digit-3"
              type="number"
              min={0}
              max={9}
            />
            <input
              value={OTP.digit4}
              onChange={handleOTPchange}
              name="digit-4"
              type="number"
              min={0}
              max={9}
            />
          </div>
          {/* <p className={styles["resend-otp"]}>
            Didn’t received OTP code? <span>Resend code</span>
          </p> */}

          <div className={styles["terms"]}>
            <label className={"custom-checkbox"}>
              <input
                type="checkbox"
                checked={terms}
                onChange={(e) => handleFormDetails({ terms: e.target.checked })}
              />
            </label>
            <p>
              I accept{" "}
              <Link to={"https://docflix.com/terms-of-use"} target="_blank">
                <span>Terms of use</span>{" "}
              </Link>{" "}
              and{" "}
              <span
                onClick={() =>
                  windowOpenUrl("https://www.mankindpharma.com/privacy-policy")
                }
              >
                Privacy Policy
              </span>
              .
            </p>
          </div>
          {/* <div className={styles["terms"]} style={{ margin: 0 }}>
            <label className={"custom-checkbox"}>
              <input
                type="checkbox"
                checked={marketing}
                onChange={(e) =>
                  handleFormDetails({ marketing: e.target.checked })
                }
              />
            </label>
            <p>I don’t wan0t to receive marketing messages from Docflix.</p>
          </div> */}
          {error && error.length > 0 && <div className="error">{error}</div>}
          <div className={styles.actionBtns}>
            <button
              disabled={otpbtndisable}
              className={`btn ${styles["next-btn"]}`}
              type="submit"
            >
              {loading ? (
                <img src={LoaderImg} alt="" height="100%" />
              ) : (
                "Submit"
              )}
            </button>
            <button
              disabled={loading}
              onClick={handleCancelBtn}
              type="button"
              className={`btn ${styles["cancel-btn"]}`}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div>
          <p className={styles["number-text"]}>
            We will send you the verification code to
            <br />
            <span>{userNumber}</span>
          </p>
          {error && error.length > 0 && <div className="error">{error}</div>}
          <div className={styles.actionBtns}>
            <button
              disabled={loading}
              onClick={handleSendOTP}
              className={`btn ${styles["send-otp-btn"]}`}
            >
              {loading ? (
                <img src={LoaderImg} alt="" height="100%" />
              ) : (
                "Send OTP"
              )}
            </button>
            {showBack && (
              <button
                disabled={loading}
                onClick={handleCancelBtn}
                className={`btn ${styles["cancel-btn"]}`}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      )}
    </form>
  );
};

export default OTPForm;

export const HomeStructureType = {
  LATEST_SHOWS: 0,
  RECENTLY_ADDED: 1,
  VIDEO_ROW: 2,
  WEBINAR_SECTION: 3,
  NEWS_SECTION: 4,
  OUR_SPEAKER:5,
  HOT_TOPIC: 6,
};

export const PREDEFINED_VIDEO_ROW_TYPE = {
  RECENTLY_ADDED: 0,
};

export const SourceType = {
  SOURCE_MUX: "mux",
  SOURCE_YOUTUBE: "youtube",
  SOURCE_VIMEO: "vimeo",
};
export const VideoType = {
  VIDEO_STANDALONE: "standalone",
  VIDEO_SERIES: "series",
};
export const SignedType = {
  SIGNED: "signed",
  PUBLIC: "public",
};
export const LikeType = {
  WEBINAR_LIKE: "webinar",
  VIDEO_LIKE: "video",
  SERIES_LIKE: "series",
  AGENDA_LIKE: "agenda",
};
export const RatingType = {
  WEBINAR_RATE: "webinar",
  VIDEO_RATE: "video",
  SERIES_RATE: "series",
};
export const ContentType = {
  VIDEO: "video",
  WEBINAR: "live-webinar",
  SERIES: "series",
  SEASON: "season",
  NEWS: "news",
  AGENDA: "agenda",
};
export const BannerType = {
  VIDEO: "video",
  SERIES: "series",
  EPISODE: "episode",
  WEBINAR: "webinar",
  CUSTOM: "custom",
};
export const BannerStyleType = {
  SIMPLE_BANNER: "s_banner",
  RHI_BANNER: "rhi_banner",
  LHI_BANNER: "lhi_banner",
  HOT_TOPIC: "hot-topic",
};
export const ExclusiveType = {
  PREMIUM: "premium",
  EXCLUSIVE: "exclusive",
  STANDARD: "standard",
  FREE: "free",
};

export const LIKE_ACTION_TYPE = {
  LIKE: "like",
  DISLIKE: "dislike",
};

export const QA_TYPE = {
  MAINTHREAD: "mainthread",
  SUBTHREAD: "subthread",
  COMMENT: "comment",
};
export const Webinar_status = {
  DISABLED: "disabled",
  REGISTERATION: "registration",
  ONGOING: "ongoing",
  ENDED: "ended",
};
export const LIVESTREAM_status = {
  IDLE: "idle",
  CONNECTED: "connected",
  DISCONNECTED: "disconnected",
  ACTIVE: "active",
};

export const ResouceType = {
  Image: "image",
  Video: "video",
  Link: "link",
  Pdf: "pdf",
};

export const URLADMIN = {
  admin: "true",
  previewEvent: "event",
  previewRegistration: "registration",
};

import React, { createContext, useState, useContext, useEffect } from 'react'
import { ASSETS_BASE_URL } from '../../AppConstants/Constants';
import { TAGS_DATA } from '../../AppData/TagsData';
import { BackstageManager } from '../../Managers/Backstage';
import { UserContext } from '../UserContext/UserContextProvider';

export const ConstantDataContext = createContext();


const hotTopicsData = [
    {
        id: 1,
        title: "Cardio Unflip",
        season: "10",
        episode: "20",
        views: "1.2K",
        image: `${ASSETS_BASE_URL}/images/shows/cardioSmall.png`,
        imageMid: `${ASSETS_BASE_URL}/images/shows/cardioSmall.png`,
        videoID: "video-kyjvry5k",
        tags: ["Con-Verse", "Dyslipedmia", "IHD"],
    },
    {
        id: 2,
        title: "Cardio Unflip",
        season: "2",
        episode: "10",
        views: "2.2K",
        image: `${ASSETS_BASE_URL}/images/shows/digitalSmall.png`,
        imageMid: `${ASSETS_BASE_URL}/images/shows/digitalSmall.png`,
        videoID: "video-kyjvry5m",
        tags: ["Con-Verse", "Roadinar", "IHD"],
    },
    {
        id: 3,
        title: "Simplified",
        season: "3",
        episode: "30",
        views: "2K",
        image: `${ASSETS_BASE_URL}/images/shows/legendSmall.png`,
        imageMid: `${ASSETS_BASE_URL}/images/shows/legendSmall.png`,
        videoID: "video-kyjvry5o",
        tags: ["Con-Verse", "Diabetes", "IHD"],
    },
    {
        id: 4,
        title: "Out Of Office",
        season: "4",
        episode: "20",
        views: "3K",
        image: `${ASSETS_BASE_URL}/images/shows/medicoSmall.png`,
        imageMid: `${ASSETS_BASE_URL}/images/shows/medicoSmall.png`,
        videoID: "video-kyjvry5q",
        tags: ["Con-Verse", "Roadinar", "IHD"],
    },
    {
        id: 5,
        title: "The Medico - Legal",
        season: "4",
        episode: "20",
        views: "3K",
        image: `${ASSETS_BASE_URL}/images/shows/officeSmall.png`,
        imageMid: `${ASSETS_BASE_URL}/images/shows/officeSmall.png`,
        videoID: "video-kyjvry5q",
        tags: ["Con-Verse", "Diabetes", "IHD"],
    },
    {
        id: 6,
        title: "Unshaken",
        season: "4",
        episode: "20",
        views: "3K",
        image: `${ASSETS_BASE_URL}/images/shows/simplifiedSmall.png`,
        imageMid: `${ASSETS_BASE_URL}/images/shows/simplifiedSmall.png`,
        videoID: "video-kyjvry5q",
        tags: ["Con-Verse", "Roadinar", "Diabetes"],
    },
    {
        id: 7,
        title: "The Guideline Zodiac",
        season: "4",
        episode: "20",
        views: "3K",
        image: `${ASSETS_BASE_URL}/images/shows/stitchSmall.png`,
        imageMid: `${ASSETS_BASE_URL}/images/shows/stitchSmall.png`,
        videoID: "video-kyjvry5q",
        tags: ["Diabetes", "Roadinar", "IHD"],
    },
    {
        id: 8,
        title: "The Guideline Zodiac",
        season: "4",
        episode: "20",
        views: "3K",
        image: `${ASSETS_BASE_URL}/images/shows/unshakenSmall.png`,
        imageMid: `${ASSETS_BASE_URL}/images/shows/unshakenSmall.png`,
        videoID: "video-kyjvry5q",
        tags: ["Diabetes", "Roadinar", "IHD"],
    },
    {
        id: 9,
        title: "The Guideline Zodiac",
        season: "4",
        episode: "20",
        views: "3K",
        image: `${ASSETS_BASE_URL}/images/shows/vantageSmall.png`,
        imageMid: `${ASSETS_BASE_URL}/images/shows/vantageSmall.png`,
        videoID: "video-kyjvry5q",
        tags: ["Diabetes", "Roadinar", "IHD"],
    },
    {
        id: 10,
        title: "The Guideline Zodiac",
        season: "4",
        episode: "20",
        views: "3K",
        image: `${ASSETS_BASE_URL}/images/shows/zodiacSmall.png`,
        imageMid: `${ASSETS_BASE_URL}/images/shows/zodiacSmall.png`,
        videoID: "video-kyjvry5q",
        tags: ["Diabetes", "Roadinar", "IHD"],
    },
];

const Speciality = [
    {
        id: 1,
        title: "Asthma",
        season: "10",
        episode: "20",
        views: "1.2K",
        image: `${ASSETS_BASE_URL}/images/speciality/Asthma.png`,
        imageMid: `${ASSETS_BASE_URL}/images/speciality/Asthma.png`,
        videoID: "video-kyjvry5k",
        tags: ["Con-Verse", "Dyslipedmia", "IHD"],
    },
    {
        id: 2,
        title: "Cardiology",
        season: "2",
        episode: "10",
        views: "2.2K",
        image: `${ASSETS_BASE_URL}/images/speciality/Cardiology.png`,
        imageMid: `${ASSETS_BASE_URL}/images/speciality/Cardiology.png`,
        videoID: "video-kyjvry5m",
        tags: ["Con-Verse", "Roadinar", "IHD"],
    },
    {
        id: 3,
        title: "COPD",
        season: "3",
        episode: "30",
        views: "2K",
        image: `${ASSETS_BASE_URL}/images/speciality/Copd.png`,
        imageMid: `${ASSETS_BASE_URL}/images/speciality/Copd.png`,
        videoID: "video-kyjvry5o",
        tags: ["Con-Verse", "Diabetes", "IHD"],
    },
    {
        id: 4,
        title: "Covid 19",
        season: "4",
        episode: "20",
        views: "3K",
        image: `${ASSETS_BASE_URL}/images/speciality/Covid19.png`,
        imageMid: `${ASSETS_BASE_URL}/images/speciality/Covid19.png`,
        videoID: "video-kyjvry5q",
        tags: ["Con-Verse", "Roadinar", "IHD"],
    },
    {
        id: 5,
        title: "Pulmonology",
        season: "4",
        episode: "20",
        views: "3K",
        image: `${ASSETS_BASE_URL}/images/speciality/Pulmonology.png`,
        imageMid: `${ASSETS_BASE_URL}/images/speciality/Pulmonology.png`,
        videoID: "video-kyjvry5q",
        tags: ["Con-Verse", "Diabetes", "IHD"],
    },
    {
        id: 6,
        title: "Radiology",
        season: "4",
        episode: "20",
        views: "3K",
        image: `${ASSETS_BASE_URL}/images/speciality/Radiology.png`,
        imageMid: `${ASSETS_BASE_URL}/images/speciality/Radiology.png`,
        videoID: "video-kyjvry5q",
        tags: ["Con-Verse", "Roadinar", "Diabetes"],
    },
];

const SpeakerPage_Default = {
    bestShows: [],
    speciality: []
}

export default function ConstantDataContextProvider(props) {
    const [TagsData, setTagsData] = useState(TAGS_DATA);
    const [speakerPage, setSpeakerPage] = useState(SpeakerPage_Default);
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (user) {
            BackstageManager.getTagsData().then((_data) => {
                if (!_data) {
                    setTagsData(TagsData);
                    return
                }
                // console.log(_data)
                let _obj_data = {}
                _data.sort((a, b) => a.order - b.order)
                _data.forEach(element => {
                    _obj_data[element.sectionId] = element;
                });
                setTagsData(_obj_data);
            }).catch(err => {
                console.error(err);
                setTagsData(TagsData);
            })

            BackstageManager.getSpeakerPageData().then((_data) => {
                if (!_data) {
                    setSpeakerPage(SpeakerPage_Default);
                    return
                }
                setSpeakerPage(_data);
            }).catch(err => {
                console.error(err);
                setSpeakerPage(SpeakerPage_Default);
            })
        }
    }, [user])

    return (
        <ConstantDataContext.Provider value={{
            TagsData,
            speakerPage
        }}>
            {props.children}
        </ConstantDataContext.Provider>
    )
}

export const VIDEO_FETCH_LIMIT = 10;
export const DIMENSIONS = ["operating_system", "region", "playback_id"];

export const PLATFORM = {
  ANDROID_APP: "android_app",
  IOS_APP: "ios_app",
  WEB_APP: "web_app",
};

export const instituteValues = {
  "Andhra Pradesh Medical Council": {
    smcId: "1",
    name: "Andhra Pradesh Medical Council",
  },
  "Arunachal Pradesh Medical Council": {
    smcId: "2",
    name: "Arunachal Pradesh Medical Council",
  },
  "Assam Medical Council": { smcId: "3", name: "Assam Medical Council" },
  "Bhopal Medical Council": { smcId: "28", name: "Bhopal Medical Council" },
  "Bihar Medical Council": { smcId: "4", name: "Bihar Medical Council" },
  "Bombay Medical Council": { smcId: "29", name: "Bombay Medical Council" },
  "Chandigarh Medical Council": {
    smcId: "30",
    name: "Chandigarh Medical Council",
  },
  "Chattisgarh Medical Council": {
    smcId: "5",
    name: "Chattisgarh Medical Council",
  },
  "Delhi Medical Council": { smcId: "6", name: "Delhi Medical Council" },
  "Goa Medical Council": { smcId: "7", name: "Goa Medical Council" },
  "Gujarat Medical Council": { smcId: "8", name: "Gujarat Medical Council" },
  "Haryana Medical Council": { smcId: "9", name: "Haryana Medical Council" },
  "Himachal Pradesh Medical Council": {
    smcId: "10",
    name: "Himachal Pradesh Medical Council",
  },
  "Hyderabad Medical Council": {
    smcId: "45",
    name: "Hyderabad Medical Council",
  },
  "Jammu & Kashmir Medical Council": {
    smcId: "11",
    name: "Jammu & Kashmir Medical Council",
  },
  "Jharkhand Medical Council": {
    smcId: "12",
    name: "Jharkhand Medical Council",
  },
  "Karnataka Medical Council": {
    smcId: "13",
    name: "Karnataka Medical Council",
  },
  "Madhya Pradesh Medical Council": {
    smcId: "15",
    name: "Madhya Pradesh Medical Council",
  },
  "Madras Medical Council": { smcId: "36", name: "Madras Medical Council" },
  "Mahakoshal Medical Council": {
    smcId: "35",
    name: "Mahakoshal Medical Council",
  },
  "Maharashtra Medical Council": {
    smcId: "16",
    name: "Maharashtra Medical Council",
  },
  "Manipur Medical Council": { smcId: "26", name: "Manipur Medical Council" },
  "Medical Council of India": { smcId: "46", name: "Medical Council of India" },
  "Medical Council of Tanganyika": {
    smcId: "47",
    name: "Medical Council of Tanganyika",
  },
  "Mizoram Medical Council": { smcId: "42", name: "Mizoram Medical Council" },
  "Mysore Medical Council": { smcId: "37", name: "Mysore Medical Council" },
  "Nagaland Medical Council": { smcId: "41", name: "Nagaland Medical Council" },
  "Orissa Council of Medical Registration": {
    smcId: "17",
    name: "Orissa Council of Medical Registration",
  },
  "Pondicherry Medical Council": {
    smcId: "38",
    name: "Pondicherry Medical Council",
  },
  "Punjab Medical Council": { smcId: "18", name: "Punjab Medical Council" },
  "Rajasthan Medical Council": {
    smcId: "19",
    name: "Rajasthan Medical Council",
  },
  "Sikkim Medical Council": { smcId: "20", name: "Sikkim Medical Council" },
  "Tamil Nadu Medical Council": {
    smcId: "21",
    name: "Tamil Nadu Medical Council",
  },
  "Telangana State Medical Council": {
    smcId: "43",
    name: "Telangana State Medical Council",
  },
  "Travancore Cochin Medical Council, Trivandrum": {
    smcId: "50",
    name: "Travancore Cochin Medical Council, Trivandrum",
  },
  "Tripura State Medical Council": {
    smcId: "22",
    name: "Tripura State Medical Council",
  },
  "Uttar Pradesh Medical Council": {
    smcId: "23",
    name: "Uttar Pradesh Medical Council",
  },
  "Uttarakhand Medical Council": {
    smcId: "24",
    name: "Uttarakhand Medical Council",
  },
  "Vidharba Medical Council": { smcId: "40", name: "Vidharba Medical Council" },
  "West Bengal Medical Council": {
    smcId: "25",
    name: "West Bengal Medical Council",
  },
};
export const years = [
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
  "2009",
  "2008",
  "2007",
  "2006",
  "2005",
  "2004",
  "2003",
  "2002",
  "2001",
  "2000",
  "1999",
  "1998",
  "1997",
  "1996",
  "1995",
  "1994",
  "1993",
  "1992",
  "1991",
  "1990",
  "1989",
  "1988",
  "1987",
  "1986",
  "1985",
  "1984",
  "1983",
  "1982",
  "1981",
  "1980",
  "1979",
  "1978",
  "1977",
  "1976",
  "1975",
  "1974",
  "1973",
  "1972",
  "1971",
  "1970",
  "1969",
  "1968",
  "1967",
  "1966",
  "1965",
  "1964",
  "1963",
  "1962",
  "1961",
  "1960",
  "1959",
  "1958",
  "1957",
  "1956",
  "1955",
  "1954",
  "1953",
  "1952",
  "1951",
  "1950",
  "1949",
  "1948",
  "1947",
  "1946",
  "1945",
  "1944",
  "1943",
  "1942",
  "1941",
  "1940",
  "1939",
  "1938",
  "1937",
  "1936",
  "1935",
  "1934",
  "1933",
  "1932",
  "1931",
];
export const ASSETS_BASE_URL =
  "https://storage.googleapis.com/docflix-d8cab.appspot.com/docflix-app/assets";

export const static_page_event_id = "esh2024";

export const whiteListCountries = ["IN", "NP"];
export const CITY_DEBOUNCE = 1000;
export const CITY_FETCH_MIN_CHAR = 2;
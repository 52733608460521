import dummyImg from "../../assets/images/dummyImg.png";
import dummyImg2 from "../../assets/images/dummyImg2.png";
import dummyImg3 from "../../assets/images/dummyImg3.png";
import dummyImg4 from "../../assets/images/dummyImg4.png";
import { isMobileDevice } from "../../utils/getPlatformInfo";

const VIDEOS_DUMMY_DATA = [
  {
    title: "Patient growth through online reviews",
    image: dummyImg,
    durationInSecs: 7000,
    playedSecs: 3000,
  },
  {
    title: "Patient growth through online reviews",
    image: dummyImg2,
    durationInSecs: 7000,
    playedSecs: 6500,
  },
  {
    title: "Patient growth through online reviews",
    image: dummyImg3,
    durationInSecs: 7000,
    playedSecs: 1000,
  },
  {
    title: "Patient growth through online reviews",
    image: dummyImg4,
    durationInSecs: 7000,
    playedSecs: 4000,
  },

  // {
  //   title: "Patient growth through online reviews",
  //   image: dummyImg3,
  //   durationInSecs: 7000,
  //   playedSecs: 3000,
  // },
  // {
  //   title: "Patient growth through online reviews",
  //   image: dummyImg,
  //   durationInSecs: 7000,
  //   playedSecs: 3000,
  // },
  // {
  //   title: "Patient growth through online reviews",
  //   image: dummyImg3,
  //   durationInSecs: 7000,
  //   playedSecs: 3000,
  // },
  // {
  //   title: "Patient growth through online reviews",
  //   image: dummyImg4,
  //   durationInSecs: 7000,
  //   playedSecs: 3000,
  // },
  // {
  //   title: "Patient growth through online reviews",
  //   image: dummyImg3,
  //   durationInSecs: 7000,
  //   playedSecs: 3000,
  // },
  // {
  //   title: "Patient growth through online reviews",
  //   image: dummyImg,
  //   durationInSecs: 7000,
  //   playedSecs: 3000,
  // },
  // {
  //   title: "Patient growth through online reviews",
  //   image: dummyImg3,
  //   durationInSecs: 7000,
  //   playedSecs: 3000,
  // },
  // {
  //   title: "Patient growth through online reviews",
  //   image: dummyImg4,
  //   durationInSecs: 7000,
  //   playedSecs: 3000,
  // },
  // {
  //   title: "Patient growth through online reviews",
  //   image: dummyImg3,
  //   durationInSecs: 7000,
  //   playedSecs: 3000,
  // },
  // {
  //   title: "Patient growth through online reviews",
  //   image: dummyImg,
  //   durationInSecs: 7000,
  //   playedSecs: 3000,
  // },
];


export const VIDEOS_DUMMY = isMobileDevice ? VIDEOS_DUMMY_DATA.slice(0, 2) : VIDEOS_DUMMY_DATA;
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/analytics";
import "firebase/functions";
import "firebase/messaging";
import { CloudFunctionName } from "../AppConstants/CloudFunctionName";
import { PLATFORM } from "../AppConstants/Constants";

const firebaseConfig = {
  apiKey: "AIzaSyDJQzMG9m7njE8-q9Om_hA6gMSFkXC2Q0g",
  authDomain: "docflix-d8cab.firebaseapp.com",
  databaseURL:
    "https://docflix-d8cab-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "docflix-d8cab",
  storageBucket: "docflix-d8cab.appspot.com",
  messagingSenderId: "547415420764",
  appId: "1:547415420764:web:c1fb2e7d7ec59502641b99",
  measurementId: "G-0M93BBG0CL",
};

firebase.initializeApp(firebaseConfig);
export const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export default firebase;
export const firestore = firebase.firestore();
export const database = firebase.database();
export const analytics = firebase.analytics();
export const functions = firebase.functions();

export const cloudFunction_as = firebase.app().functions("asia-south1");
// cloudFunction_as.useEmulator('localhost', 5001);

export const auth = firebase.auth();

const login = (email, password) => {
  return new Promise(async (res, rej) => {
    try {
      const _res = await auth.signInWithEmailAndPassword(email, password);
      // console.log(user);
      res(_res.user);
    } catch (error) {
      rej(error);
    }
  });
};
window.login = login;

const logout = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
};

const checkUserExists = (userData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let user = {
        email: userData.email,
        phonenumber: userData.phoneNumber,
      };

      const checkUserExistsRef = cloudFunction_as.httpsCallable(
        CloudFunctionName.USER_EXISTS
      );

      let res = await checkUserExistsRef(JSON.stringify(user));

      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
const askForPermissionToReceiveNotifications = async (user) => {
  try {
    if (messaging) {
      // await messaging.requestPermission();
      const token = await messaging.getToken({
        vapidKey:
          "BHzdBxsWiKgmbmYU6q6432vVqUebuD4l_yJ90TFoiNs3dDWGvyel1SmsJmoETZes-vmZHl1-P_RoXVmZuV-n-CU",
      });
      if (token) {
        let userData = {
          uid: user.uid,
          token: token,
          platform: PLATFORM.WEB_APP,
        };
        const cloudRef = cloudFunction_as.httpsCallable(
          CloudFunctionName.USER_TOKEN_UPDATE
        );
        cloudRef(JSON.stringify(userData))
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      console.log("Your token is:", token);
      return token;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
};
const onMessageListener = (cb) => {
  try {
    if (messaging) {
      // messaging.onMessage((payload) => {
      //   cb(payload);
      // });
    }
  } catch (error) {
    console.error(error);
    cb(null);
  }
};

export const sendNotificationToTopic = (data) => {
  const cloudRef = cloudFunction_as.httpsCallable("sendNotificationToTopic");
  cloudRef(JSON.stringify(data))
    .then((res) => {
      console.log(res);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const subscribeAllTokens = (data) => {
  const cloudRef = cloudFunction_as.httpsCallable("subscribeAllTokens");
  cloudRef(JSON.stringify(data))
    .then((res) => {
      console.log(res);
    })
    .catch((error) => {
      console.log(error);
    });
};

window.sendNotificationToTopic = sendNotificationToTopic;
window.subscribeAllTokens = subscribeAllTokens;

export const AuthManager = {
  login,
  logout,
  checkUserExists,
  askForPermissionToReceiveNotifications,
  onMessageListener,
};

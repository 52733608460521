import { Link } from "react-router-dom";
import { isMobileDevice } from "../../utils/getPlatformInfo";
import styles from "./LinkMapper.module.scss";

function LinkMapper({ title = "", links = [], setActiveIndex, isOpen }) {
  return (
    <div
      className={`${styles.mapperContainer} ${
        isMobileDevice ? styles.toggleWrapper : null
      }`}
    >
      <div
        className={styles.header}
        onClick={() => {
          setActiveIndex();
        }}
      >
        <div>{title}</div>
        {isMobileDevice ? (
          <div
            onClick={() => {
              setActiveIndex();
            }}
          >
            <div
              className={styles.arrow}
              style={{
                transform: `rotate(${isOpen ? "45deg" : "225deg"})`,
                transition: "all .2s ease-in",
              }}
            />
          </div>
        ) : null}
      </div>
      <div
        className={styles.linksWrapper}
        style={{
          height: isOpen || !isMobileDevice ? links.length * 30 : "0px",
          overflow: isOpen || !isMobileDevice ? "unset" : "hidden",
          marginTop: isOpen || !isMobileDevice ? "12px" : "0",
          transition: "all .4s ease-in",
        }}
      >
        {links.map((link, index) => {
          return (
            <Link
              to={link.url}
              key={index}
              data-aos="fade-right"
              data-aos-delay={index * 50}
              data-aos-offset="-100"
            >
              {link.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default LinkMapper;

import { memo, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { useTheme } from "../../contexts/ThemeContext/ThemeContext";
// import NavHeader from "../NavHeader";
import styles from "./index.module.scss";
import videoPlaceholder from "../../assets/images/videoPlaceholder.png";
import LoaderImg from "../../assets/images/videoLoader.gif";
import close from "../../assets/images/close.png";
import OverlayCard from "./partials/OverlayCard";
import { VideosData } from "./videoData";
import TabComponent from "../TabsComponent";
import MyDropdown from "../Tags/Dropdown";
import EpisodesTab from "./partials/EpisodesTab";
import ContactFooter from "../ContactFooter";
import SpeakersCards from "./partials/SpeakersCards";
import DetailsTab from "./partials/DetailsTab";
import NewsAndTranscript from "./partials/NewsAndTranscript";
import OverlayCardMob from "./partials/OverlayCardMob";
import NotesManager from "../../Managers/Users/Notes";
import SpeakerManager from "../../Managers/Speakers";
import VideoManager from "../../Managers/Video";
import DetailManager from "../../Managers/Details";
import {
  ContentType,
  ExclusiveType,
  VideoType,
} from "../../AppConstants/TypeConstants";
import { videoContext } from "../../contexts/VideoContext/VideoContextProvider";
import MuxVideoPlayer from "../MuxVideoPlayer/MuxVideoPlayer";
import { MediaMetadataContext } from "../../contexts/MediaMetaDataContext/MediaMetadataContextProvider";
import { UserContext } from "../../contexts/UserContext/UserContextProvider";
import { Box, ContainerBox, Line, Shimmer } from "../ShimmerEffect";
import { isLandscape, isMobileDevice } from "../../utils/getPlatformInfo";
// import { useScrollHandler } from "../../hooks/useScroll";
import { VideoPlayerContext } from "../../contexts/VideoPlayerContext/VideoPlayerContextProvider";
import { VIDEO_THEME } from "../../AppConstants/ThemeConstant";
import InviteModal from "../InviteModal/InviteModal";
import {
  INVITEYOURFRIEND_EVENT_BTN,
  VIDEO_NOTE_BTN_CLICK,
  VIDEO_SHARE_BTN_CLICK,
  VIDEO_TABS_CLICK,
} from "../../AppConstants/AnalyticsEventName";
import { AnalyticsContext } from "../../contexts/Analytics/AnalyticsContextProvider";
import { newAuthContext } from "../../contexts/AuthContext/NewAuthContextProvider";
import { ASCENDING, TIMESTAMP } from "../../AppConstants/SortConstants";

const VideoDetails = () => {
  const { user, userMetadata } = useContext(UserContext);
  const { updateMultipleState } = useContext(newAuthContext);
  const muxVideoRef = useRef("");
  // const scrollValue = useScrollHandler(0);
  const [allNotes, setAllNotes] = useState([]);

  // const history = useHistory();

  // const { videoId, season = 0 } = history.query;
  const { mediaMetadata, updateMediaMetadata } =
    useContext(MediaMetadataContext);
  const { isFullScreen } = useContext(VideoPlayerContext);
  const { addGAWithUserInfo } = useContext(AnalyticsContext);
  const { getVideosByTag, rowVideos } = useContext(videoContext);

  const { videoId } = useParams();
  const { pathname, search = "", hash = "" } = useLocation();
  const history = useHistory();
  let season = parseInt(search.split("?season=")[1]) || 0;

  const { isDark } = useTheme();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

  const tabId = () => {
    switch (hash) {
      case "#speakers":
        return 0;
      case "#details":
        return 1;
      case "#related videos":
        return 2;
      default:
        return 0;
    }
  };
  const tab = tabId();

  const [showPlayer, setShowPlayer] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const [seasonId, setSeasonId] = useState(
    parseInt(search.split("?season=")[1]) || 0
  );
  const [selectedSeason, setSelectedSeason] = useState(seasonId - 1);

  const [videoDetails, setVideoDetails] = useState(null);
  const [videoData, setVideoData] = useState(null);

  const [videoSpeakers, setVideoSpeakers] = useState(null);
  const [isvideoLoading, setIsVideoLoading] = useState(true);

  const [selectedTab, setSelectedTab] = useState(
    parseInt(search.split("?season=")[1]) ? 0 : 0
  );

  const [videoRecentVideo, setVideoRecentVideo] = useState([]);
  const [videoToggle, toggleVideoToggle] = useState(false);

  const [currentVideoShare, setCurrentVideoShare] = useState(videoData);

  useEffect(() => {
    setCurrentVideoShare(videoData);
  }, [videoData]);

  useEffect(() => {
    if (videoToggle) {
      toggleVideoToggle(false);
    }
  }, [videoToggle]);

  useEffect(() => {
    if (rowVideos && videoData) {
      let idMain = videoData.tags.length > 0 ? videoData.tags[0] : "";
      getRelatedVideos(idMain).then((res) => {
        let fileterData = res.filter((item) => {
          return item.id !== videoId;
        });

        setVideoRecentVideo(fileterData);
      });
    }
  }, [videoData, rowVideos]);

  useEffect(() => {
    if (videoData && videoData.tags) {
      let camelCaseTag =
        videoData.tags[0][0].toUpperCase() + videoData.tags[0].slice(1);
      // console.log("camelCaseTag:", camelCaseTag);
      if (camelCaseTag === "Con-verse") {
        camelCaseTag = "Con-Verse";
      }

      let fetchParameters = {
        sectionId: camelCaseTag,
        tag: [videoData.tags[0]],
      };

      getVideosByTag(fetchParameters);
    }
  }, [videoData]);

  useEffect(() => {
    if (videoId) {
      toggleVideoToggle(true);
      fetchData();
      fetchAllNotes();
    }
    return () => {
      NotesManager._get.removeNotesListener();
    };
  }, [videoId]);

  useEffect(() => {
    if (tab) {
      setSelectedTab(tab);
    }
  }, [tab]);

  const getRelatedVideos = async (tag) => {
    let arr = [];
    if (tag) {
      let res = await VideoManager._get.getSortedVideos(
        [tag],
        TIMESTAMP,
        ASCENDING
      );
      arr = res.data;
    }
    return arr;
  };

  useEffect(() => {
    if (showInviteModal) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.position = "unset";
    }
    if (isSidePanelOpen && isMobileDevice) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.position = "unset";
    }
  }, [showInviteModal, isSidePanelOpen]);

  const fetchData = async () => {
    try {
      let video = await VideoManager._get.getVideoById(videoId);

      if (!video) {
        history.replace("/");
      }

      if (
        video.type === ExclusiveType.PREMIUM ||
        video.type === ExclusiveType.EXCLUSIVE
      ) {
        if (video.type === ExclusiveType.PREMIUM) {
          handlePremiumVideoClick();
        } else if (video.type === ExclusiveType.EXCLUSIVE) {
          handleExclusiveVideoClick();
        }
      }

      let _speakers = [];
      let details = null;

      // video speakers
      if (video.speakers && video.speakers.length > 0) {
        _speakers = await Promise.all(
          video.speakers.map(async (speaker) => {
            let res = await SpeakerManager._get.getSpeakerById(speaker);
            if (res) {
              return res;
            }
          })
        );
      }

      // video details
      if (video.detailsId) {
        let res = await DetailManager._get.getDetailById(video.detailsId);
        if (res) {
          details = res;
        }
      }
      // video tokens
      // if (video.sourceType === SourceType.SOURCE_MUX) {
      //   if (video.videoPolicy && video.videoPolicy === SignedType.SIGNED) {
      //     let res = await fetchVideoTokens(video.playbackid);
      //     video["tokens"] = res.data;
      //   }
      // }

      document.title = `${video.title} | Videos | Docflix`;

      setVideoDetails(details);

      const { playbackid = "", tokens = {} } = video || {};

      const populateData = () => {
        setVideoData(video);
        setVideoSpeakers(_speakers);

        // history.replace({
        //   pathname: `/video/${videoId}`,
        //   hash: "speakers",
        // });
      };

      let img = new Image();
      img.onload = populateData;
      img.onerror = populateData;
      img.src = `https://image.mux.com/${playbackid}/thumbnail.jpg${
        tokens && tokens.thumbnailToken && tokens.thumbnailToken !== ""
          ? `?token=${tokens.thumbnailToken}`
          : ``
      }`;
    } catch (error) {
      history.replace("/");
    }
  };

  const handlePremiumVideoClick = () => {
    if (
      userMetadata.current_plan &&
      (userMetadata.current_plan === ExclusiveType.PREMIUM ||
        userMetadata.current_plan === ExclusiveType.EXCLUSIVE)
    ) {
    } else {
      return updateMultipleState({ premiumFormModalStart: true });
    }
  };
  const handleExclusiveVideoClick = () => {
    // first check for premium
    if (
      userMetadata.current_plan &&
      userMetadata.current_plan === ExclusiveType.FREE
    ) {
      return updateMultipleState({ premiumFormModalStart: true });
    } else if (
      userMetadata.current_plan &&
      userMetadata.current_plan !== ExclusiveType.EXCLUSIVE
    ) {
      updateMultipleState({ exclusiveFormModalStart: true });
    }
  };

  const fetchAllNotes = async () => {
    if (user) {
      NotesManager._get.attachNotesListener(videoId, user.uid, (res, err) => {
        if (err) {
          return;
        }
        setAllNotes(res);
      });
    }
  };

  const TABS = [
    {
      label: "Speakers",
      enabled: true,
      childComponent: () => {
        return videoSpeakers && <SpeakersCards speakersList={videoSpeakers} />;
      },
    },
    {
      label: "Details",
      enabled: true,
      childComponent: () => {
        return videoDetails && <DetailsTab detailsData={videoDetails} />;
      },
    },

    {
      label: "Related Videos",
      enabled: true,
      childComponent: () => {
        return (
          <>
            <EpisodesTab
              episodes={videoRecentVideo}
              handleInviteBtn={handleInviteBtn}
              setCurrentVideoShare={setCurrentVideoShare}
            />
          </>
        );
      },
    },
  ];

  const startWatchingBtn = () => {
    if (muxVideoRef.current) {
      if (muxVideoRef.current.paused) {
        muxVideoRef.current.play();
        setShowPlayer(true);
      } else {
        muxVideoRef.current.pause();
        setShowPlayer(false);
      }
    }
  };

  const closeStartWatchingBtn = (videoDur, videoWatched, type) => {
    if (videoDur && videoWatched) {
      updateMediaMetadata(
        videoData.id,
        videoWatched,
        videoDur,
        ContentType.VIDEO
      );
    }
    if (type === "backbtn") {
      startWatchingBtn();
    }
  };
  const handleNoteBtn = () => {
    addGAWithUserInfo(VIDEO_NOTE_BTN_CLICK);
    if (!isSidePanelOpen && !muxVideoRef.current.paused) {
      muxVideoRef.current.pause();
    }
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleInviteBtn = () => {
    addClickAnalytics(VIDEO_SHARE_BTN_CLICK);
    setShowInviteModal(true);
  };
  const handleInviteBtnClose = () => {
    setShowInviteModal(false);
  };

  const addClickAnalytics = (eventName) => {
    addGAWithUserInfo(eventName, { eventId: videoData.id });
  };
  const handleVideoLoading = (bool) => {
    setIsVideoLoading(bool);
  };
  const { playbackid = "", tokens = {} } = videoData || {};
  return (
    <div className={styles.videoPage}>
      <div
        className={
          isDark ? styles.headerBannerWrapper : styles.headerBannerWrapperLight
        }
      >
        {/* <NavHeader scrollValue={30} defaultBg={"#000"} themeConstant /> */}
      </div>

      <div
        className={`${styles.playerSection} ${
          isFullScreen && styles.playerSectionFullScreen
        }`}
      >
        <div className={styles.videoSide} style={{ zIndex: isFullScreen && 9 }}>
          {videoData && !videoToggle && (
            <MuxVideoPlayer
              showPlayer={showPlayer}
              isSidePanelOpen={isSidePanelOpen}
              handleNoteBtn={handleNoteBtn}
              startingPoint={
                mediaMetadata[videoData.id]
                  ? mediaMetadata[videoData.id].lastWatchTimestamp
                  : 0
              }
              closeStartWatchingBtn={closeStartWatchingBtn}
              uid={user && user.uid ? user.uid : null}
              videoData={videoData}
              muxVideoRef={muxVideoRef}
              themeType={VIDEO_THEME}
              handleVideoLoading={handleVideoLoading}
              allNotes={allNotes}
            />
          )}
          {isvideoLoading && (
            <div className="videoLoader">
              <img loading="lazy" src={LoaderImg} alt="" height="100%" />
            </div>
          )}
          <Shimmer
            loading={videoData === null}
            loadingComponent={
              <ContainerBox shimmer={isMobileDevice}>
                {isMobileDevice ? (
                  <div className={styles.playerSection} />
                ) : (
                  <div className={styles.loaderWrapper}>
                    <div>
                      <Line
                        count={2}
                        sameWidth={false}
                        width={400}
                        height={20}
                      />
                      <br />
                      <br />
                      <Box height={60} width={200} />
                    </div>
                    <Line count={2} sameWidth={false} width={500} height={20} />
                  </div>
                )}
              </ContainerBox>
            }
            renderComponent={
              <>
                <img
                  loading="lazy"
                  src={`https://image.mux.com/${playbackid}/thumbnail.jpg${
                    tokens &&
                    tokens.thumbnailToken &&
                    tokens.thumbnailToken !== ""
                      ? `?token=${tokens.thumbnailToken}`
                      : ``
                  }`}
                  alt={"videoPlaceholder"}
                />
                <div
                  className={styles.overlayBG}
                  onClick={startWatchingBtn}
                ></div>
                <div className={styles.overlayData}>
                  {videoData && !videoToggle && (
                    <OverlayCard
                      startWatchingBtn={startWatchingBtn}
                      cardData={videoData}
                      startingPoint={
                        mediaMetadata[videoData.id]
                          ? mediaMetadata[videoData.id].lastWatchTimestamp
                          : 0
                      }
                      handleInviteBtn={handleInviteBtn}
                      handleShare={() => setCurrentVideoShare(videoData)}
                    />
                  )}
                </div>
              </>
            }
          />
          {!isMobileDevice && (
            <span
              style={{
                position: "absolute",
                bottom: "-5rem",
                left: "10%",
                fontSize: "2.2rem",
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              About the video:
            </span>
          )}
        </div>
        <div
          className={`${styles.notesTranscriptSide} ${
            isSidePanelOpen && styles.notesTranscriptSideMobile
          } `}
          style={{
            width:
              isSidePanelOpen && !isMobileDevice
                ? "40rem"
                : isSidePanelOpen && isLandscape() && "30rem",
            zIndex: isSidePanelOpen && isFullScreen && 9,
          }}
        >
          {isSidePanelOpen && (
            // <><div className={styles.notesOverlay} ></div>
            <NewsAndTranscript
              muxVideoRef={muxVideoRef}
              videoId={videoId}
              allNotes={allNotes}
            />
            // </>
          )}
          {isSidePanelOpen && (
            <div onClick={handleNoteBtn} className={styles.notesClose}>
              <img loading="lazy" src={close} alt="Close" />
            </div>
          )}
        </div>
      </div>

      <div className={styles.overlayDataMob}>
        <Shimmer
          loading={videoData === null}
          loadingComponent={
            <div className={styles.loaderWrapper}>
              <div>
                <Line count={2} sameWidth={false} width={400} height={20} />
                <br />
                <br />
                <Box height={60} width={200} />
                <br />
                <br />
                <Line count={6} sameWidth={false} width={500} height={20} />
              </div>
            </div>
          }
          renderComponent={
            videoData &&
            !videoToggle && (
              <OverlayCardMob
                startWatchingBtn={startWatchingBtn}
                cardData={videoData}
                startingPoint={
                  mediaMetadata[videoData.id]
                    ? mediaMetadata[videoData.id].lastWatchTimestamp
                    : 0
                }
                handleInviteBtn={handleInviteBtn}
                handleNoteBtn={handleNoteBtn}
                handleShare={() => setCurrentVideoShare(videoData)}
              />
            )
          }
        />
      </div>

      <div className={styles.contentWrapper}>
        <Shimmer
          loading={videoData === null}
          loadingComponent={
            <div
              style={{
                padding: "0 10%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginBottom: 50,
                }}
              >
                <Box height={30} width={200} marginRight={100} />
                <Box height={30} width={200} marginRight={100} />
                <Box height={30} width={200} marginRight={100} />
              </div>
              {selectedTab === 1 ? (
                !isMobileDevice ? (
                  <>
                    <Box height={480} width={330} marginRight={24} />
                    <Box height={480} width={330} />
                  </>
                ) : (
                  <Box height={200} width={500} />
                )
              ) : (
                <Line count={5} height={10} width={500} />
              )}
            </div>
          }
          renderComponent={
            <TabComponent
              tabFontSize="40px"
              tabs={TABS}
              onChangeTab={(index) => {
                // videoData.videoType === VideoType.VIDEO_SERIES
                //   ? history.push({
                //       pathname: `/video/${videoId}`,
                //       search: `?season=${parseInt(selectedSeason) + 1}`,
                //       hash: `#${TABS[index].label.toLowerCase()}`,
                //     }):
                history.push({
                  pathname: `/video/${videoId}`,
                  hash: `#${TABS[index].label.toLowerCase()}`,
                });
                addGAWithUserInfo(VIDEO_TABS_CLICK, {
                  label: TABS[index].label,
                });
                setSelectedTab(index);
              }}
              activeTab={selectedTab}
            />
          }
        />
      </div>

      {showInviteModal && (
        <InviteModal
          type="ott"
          details={currentVideoShare}
          handleClose={handleInviteBtnClose}
          customClass={"inviteModal"}
        />
      )}

      <div className={`${styles.sectionWrapper} ${styles.footerWrapper}`}>
        <ContactFooter zIndexZero />
      </div>
    </div>
  );
};

export default memo(VideoDetails);

import { useContext, useEffect, useState, useRef } from "react";
import RecentlyAddedCard from "./RecentlyAddedCard";
import { RECENT_ADDED } from "./RecentlyAddedCard/dummyData";
import styles from "./index.module.scss";
import useTileScroller from "../../hooks/useTileScroller";
import SliderControls from "../SliderControls";
import { videoContext } from "../../contexts/VideoContext/VideoContextProvider";
import { PREDEFINED_VIDEO_ROW_TYPE } from "../../AppConstants/TypeConstants";
// import lightX from "../../assets/images/lightX.png";
// import Rellax from "rellax";
import Rellax from "rellax";
import parallax1 from "../../assets/images/parallax1.png";
import parallax2 from "../../assets/images/parallax2.png";
import parallax3 from "../../assets/images/parallax3.png";
import parallax4 from "../../assets/images/parallax4.png";

function RecentlyAdded() {
  const { rowVideos, getPredefinedVideos } = useContext(videoContext);
  const [rowData, setRowData] = useState([]);

  const parallax1Img = useRef(null);
  const parallax2Img = useRef(null);
  const parallax3Img = useRef(null);
  const parallax4Img = useRef(null);

  useEffect(() => {
    new Rellax(parallax1Img.current, {
      speed: 4,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
    new Rellax(parallax2Img.current, {
      speed: -4,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
    new Rellax(parallax3Img.current, {
      speed: -3,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
    new Rellax(parallax4Img.current, {
      speed: -2,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
  }, []);

  const [tiles, setTiles] = useState(RECENT_ADDED);

  const { currentIndex, updateIndex } = useTileScroller({
    // noOfTiles: rowData.length || 5,
    onEndReached: () => {},
    elementId: "recentSection",
    mobileTileWidth: 300,
    webTileWidth: 100,
    tileGapWeb: -100,
    webTileId: "recentCard",
    autoScrollToCard: false,
    indexing: true,
  });

  useEffect(() => {
    getPredefinedVideos(PREDEFINED_VIDEO_ROW_TYPE.RECENTLY_ADDED);
  }, [getPredefinedVideos]);

  useEffect(() => {
    if (rowVideos && rowVideos[PREDEFINED_VIDEO_ROW_TYPE.RECENTLY_ADDED]) {
      let vid =
        rowVideos[PREDEFINED_VIDEO_ROW_TYPE.RECENTLY_ADDED].videos || null;
      if (vid) {
        setRowData(vid);
      }
    }
  }, [rowVideos]);

  // const lightXRef = useRef(null);
  // useEffect(() => {
  //   new Rellax(lightXRef.current, {
  //     speed: 3,
  //     center: true,
  //     wrapper: null,
  //     round: true,
  //     vertical: true,
  //     horizontal: false,
  //   });
  // }, []);
  return (
    <>
      {/* <div className={styles.plusIconLight} ref={parallax1Img}>
        <img src={parallax1} alt="" className={styles.overlayImg} />
      </div>
      <div className={styles.plusIconLight} ref={parallax2Img}>
        <img src={parallax2} alt="" className={styles.overlayImg} />
      </div>
      <div className={styles.plusIconLight} ref={parallax3Img}>
        <img src={parallax3} alt="" className={styles.overlayImg} />
      </div>
      <div className={styles.plusIconLight} ref={parallax4Img}>
        <img src={parallax4} alt="" className={styles.overlayImg} />
      </div> */}
      <div className={styles.recentWrapper} id="recentSection">
        {rowData.map((recent, index) => {
          return (
            <RecentlyAddedCard
              data={recent}
              key={index}
              isActive={currentIndex === index}
              isPrev={index < currentIndex}
              cardIndex={index}
              id="recentCard"
            />
          );
        })}
      </div>
      <SliderControls
        onPrev={() => {
          updateIndex(currentIndex - 1);
        }}
        onNext={() => {
          // document.getElementById(`recentCard${currentIndex}`).style.display =
          //   "none";
          // RECENT_ADDED.push(RECENT_ADDED[currentIndex]);
          // const sliced = RECENT_ADDED.slice(1);
          // setTiles(sliced);
          // console.warn("RECENT_ADDED", RECENT_ADDED, sliced, tiles);
          updateIndex(currentIndex + 1);
        }}
        prevActive={currentIndex > 0}
        nextActive={currentIndex < rowData.length - 1}
        // nextActive={currentIndex < RECENT_ADDED.length - 1}
        customClass={styles.controls}
      />
    </>
  );
}

export default RecentlyAdded;

// export const WATCHTRAILER_ANALYTICS_EVENT = "watch_Trailer";
export const ADDTOCALENDAR_ANALYTICS_EVENT = "addToCalendar";
export const INVITEYOURFRIEND_EVENT_EMAIL = "inviteYourFriend_email";
export const INVITEYOURFRIEND_EVENT_FACEBOOK = "inviteYourFriend_facebook";
export const INVITEYOURFRIEND_EVENT_TWITTER = "inviteYourFriend_twitter";
export const INVITEYOURFRIEND_EVENT_WHATSAPP = "inviteYourFriend_whatsapp";
export const INVITEYOURFRIEND_EVENT_LINKEDIN = "inviteYourFriend_linkedin";
export const INVITEYOURFRIEND_EVENT_BTN = "inviteYourFriend_btn";
// export const INVITEYOURFRIEND_EVENT_MOBILE_NATIVE_SHARE =
//   "inviteYourFriend_mobileNative";
// export const TIMELINE_LIKE_EVENT = "timeline_like_V2";
// export const SPEAKER_PROFILE_CLICK_EVENT = "speakerProfileClick";
// export const TIMELINE_RATING_EVENT = "timeline_Rating";
// export const TEASER_EVENT = "teaser_play";
// export const KNOW_YOUR_SPEAKER_CLICK_EVENT = "knowyourspeaker_click";
export const TAG_CLICKED = "tag_click";
export const VIDEO_CLICK = "video_click";
export const VIDEO_LIKE_BTN_CLICK = "video_like_btn_click";
export const VIDEO_DISLIKE_BTN_CLICK = "video_dislike_btn_click";
export const VIDEO_SHARE_BTN_CLICK = "video_share_btn_click";
export const VIDEO_NOTE_BTN_CLICK = "video_note_btn_click";
export const VIDEO_RATING_CLICK = "video_rating_click";
export const VIDEO_TABS_CLICK = "video_tabs_click";
export const WEBINAR_TABS_CLICK = "webinar_tabs_click";
export const WEBINAR_PAGE_TABS_CLICK = "webinar_page_tabs_click";
export const WEBINAR_PAGE_ATTEND_CLICK = "webinar_page_attend_click";
export const WEBINAR_PAGE_SHARE_CLICK = "webinar_page_share_click";
export const WEBINAR_SHARE_CLICK = "webinar_share_click";
export const HOME_LATEST_SHOWS_CLICK = "home_latest_shows_click";
export const HOME_HOT_TOPICS_CLICK = "home_hot_topics_click";
export const HOME_PODCAST_CLICK = "home_podcast_click";
export const DRAWER_OPEN_CLICK = "drawer_open_click";
export const SEARCH_BTN_CLICK = "search_btn_click";
export const SEARCH_INPUT_CLICK = "search_input_click";
export const SPEAKER_CLICK = "speaker_click";
export const HOME_UPCOMING_WEBINAR_ATTEND_CLICK =
  "home_upcoming_webinar_attend_click";
export const WEBINAR_PAGE_CAROUSEL_ATTEND_CLICK =
  "webinar_page_carousel_attend_click";
export const HOME_UPCOMING_WEBINAR_SHARE_CLICK =
  "home_upcoming_webinar_share_click";
export const WEBINAR_PAGE_CAROUSEL_SHARE_CLICK =
  "webinar_page_carousel_share_click";
export const RECENTLY_ADDED_BANNER_CLICK = "recently_added_banner_click";
// export const VIDEO_KEYFRAME_CLICK = "video_keyframe_click";
// export const VIDEO_TIMESPENT = "video_timespent";
export const CERTIFICATE_CLICK = "certificate_click";
export const DOWNLOAD_CERTIFICATE = "certificate_download";
export const FEEDBACK_CLICK = "feedback_click";
export const FEEDBACK_SUBMIT = "feedback_submit";
export const RESOURCES_CLICK = "resources_click";
export const AGENDA_LIKE_BTN_CLICK = "agenda_like_btn_click";
export const AGENDA_UNLIKE_BTN_CLICK = "agenda_unlike_btn_click";
export const AGENDA_RATING_BTN_CLICK = "agenda_rating_btn_click";
export const WEBINAR_CHAT_BTN_CLICK = "webinar_chat_btn_click";
// export const ENGAGEMENT_CLICK = "engagment_click";
export const QNA_INTERATCION = "qna_interaction";
export const POLL_INTERACTION = "poll_interaction";
export const LIVE_INTERACTION_CURIOUS_CLICK = "live_interaction_curious_click";
export const LIVE_INTERACTION_AMAZING_CLICK = "live_interaction_amazing_click";
export const LIVE_INTERACTION_THINKING_CLICK =
  "live_interaction_thinking_click";
export const BANNER_WATCH_NOW_BTN_CLICK = "banner_watch_now_btn_click";
// export const SESSION_ATTENDED = "session_attended";
// export const NOTIFICATION_RECEIVED = "notification_received";
// export const NOTIFICATION_INTERACTED = "notification_interacted";

// export const NEWS_READMORE_CLICK = "news_read_more_click";
// export const NEWS_EXPLORE_CLICK = "news_explore_click";
// export const NEWS_EXPLORE_BANNER_CLICK = "news_explore_banner_click";
// export const NEWS_SHARE_CLICK = "news_share_click";

// export const INSTALL_PWA_ANALYTICS_EVENT = "pwa_install_click";

// export const OLD_USER_REGISTER_EVENT = "old_user_register_event";

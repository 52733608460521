import React, { useContext, useEffect, useState } from "react";
import converseStyle from "../ConverseJoin/index.module.scss";
// import Input from "react-phone-number-input/input";
import modalImg from "../../../../assets/images/welcome.png";
import closebtnImg from "../../../../assets/images/close.png";
import { AnalyticsContext } from "../../../../contexts/Analytics/AnalyticsContextProvider";
import { static_page_event_id } from "../../../../AppConstants/Constants";

const utm_mediums = ["whatsapp", "mail", "sms"];

const TeamModal = ({
  teamPhoneNumber,
  setTeamPhoneNumber,
  setShowTeamModal,
  setIsTeamUrl,
  teamId,
}) => {
  const { addGANoUser } = useContext(AnalyticsContext);
  const [error, setError] = useState("");

  const handlePhoneNumber = (number) => {
    setTeamPhoneNumber(number);
  };

  useEffect(() => {
    if (
      teamPhoneNumber?.trim().length === 0 ||
      teamPhoneNumber?.trim().length !== 8
    ) {
      setError("Please enter valid employee ID.");
      return;
    } else {
      setError("");
    }

    return () => {
      setError("");
    };
  }, [teamPhoneNumber]);

  const handleContinue = () => {
    let url = window.location.href;
    if (url) {
      let newURL = url.split("/team")[0];
      newURL = `${newURL}?utm_source=${encodeURIComponent(teamPhoneNumber)}`;
      let obj = {
        normal: newURL,
      };

      utm_mediums.map((m) => {
        obj[m] = newURL + "&utm_medium=" + m;
      });

      sessionStorage.setItem("teamNo", JSON.stringify(obj));
      addGANoUser(`${static_page_event_id}_generate_glink`, {
        newURL: newURL,
        empID: teamPhoneNumber,
        teamId: teamId,
      });

      setIsTeamUrl(true);
      closeModal();
    }
  };

  const closeModal = () => {
    setShowTeamModal(false);
  };

  return (
    <>
      <div className={`modalOverlayWithBlur`} />
      <div className={`${converseStyle["container"]}`}>
        <div
          className={`d-flex ${converseStyle["box"]}`}
          style={{ flexDirection: "column" }}
        >
          <img
            src={modalImg}
            alt=""
            style={{ padding: "2rem 0px", marginTop: "-2rem" }}
          />
          <p
            style={{ lineHeight: 1.4, fontSize: "2rem", marginBottom: "2rem" }}
          >
            Dear Mankind Employee, Please enter
            <br />
            {/* your phone number */}
            your employee ID
          </p>
          <input
            style={{ borderRadius: "3rem" }}
            type="number"
            value={teamPhoneNumber}
            onChange={(e) => setTeamPhoneNumber(e.target.value)}
            className="form-control"
            placeholder="Enter employee ID (max 8 digits)"
          />
          {/* <Input
            style={{ borderRadius: "3rem" }}
            country="IN"
            international
            withCountryCallingCode
            className="form-control"
            name="phoneNumber"
            value={teamPhoneNumber}
            id="phone"
            placeholder="Enter Mobile Number"
            onChange={handlePhoneNumber}
          /> */}
          {error && (
            <div
              style={{ marginBottom: "1rem", marginLeft: "1.5rem" }}
              className="error"
            >
              {error}
            </div>
          )}

          <button
            disabled={error}
            onClick={handleContinue}
            style={{ alignSelf: "center" }}
            className="btn"
          >
            CONTINUE
          </button>
          <div onClick={closeModal} className={converseStyle["closeBtn"]}>
            <img src={closebtnImg} alt="close" />
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamModal;

import dummyImg from "../assets/images/dummyImg.png";
import converse from "../assets/images/tagsImage/Converse.jpg";
import diabetesNewsNucleus from "../assets/images/tagsImage/Diabetes News Nucleus.jpeg";
import diabetes from "../assets/images/tagsImage/Diabetes.jpeg";
import dyslipidemia from "../assets/images/tagsImage/Dyslipidemia.jpeg";
import elsevier from "../assets/images/tagsImage/elsevier.jpg";
import heartFailure from "../assets/images/tagsImage/Heart Failure.jpeg";
import hypertension from "../assets/images/tagsImage/Hypertension.jpeg";
import IHD from "../assets/images/tagsImage/IHD.jpeg";
import roadinar from "../assets/images/tagsImage/Roadinar.jpg";
import wiley from "../assets/images/tagsImage/Wiley.jpg";

export const TAGS_DATA = {
  "cross therapy": {
    "clickable": true,
    "icon": "https://storage.googleapis.com/docflix-d8cab.appspot.com/docflix-app/assets/images/tagsIcon/Cross%20therapy.png",
    "label": "Cross Therapy",
    "order": 7,
    "sectionId": "cross therapy",
    "tag": "cross therapy",
    "enable": false
  },
  "documentary": {
    "label": "Documentary",
    "tag": "documentary",
    "sectionId": "documentary",
    "order": 6,
    "clickable": false,
    "icon": "https://storage.googleapis.com/docflix-d8cab.appspot.com/docflix-app/assets/images/tagsIcon/documentary.png",
    "enable": true
  },
  "guidelines": {
    "enable": true,
    "order": 3,
    "clickable": false,
    "label": "Guidelines",
    "tag": "guidelines",
    "icon": "https://storage.googleapis.com/docflix-d8cab.appspot.com/docflix-app/assets/images/tagsIcon/Cross%20therapy.png",
    "sectionId": "guidelines"
  },
  "medico-legal": {
    "order": 5,
    "icon": "https://storage.googleapis.com/docflix-d8cab.appspot.com/docflix-app/assets/images/tagsIcon/Medico%20legal.png",
    "enable": true,
    "tag": "medico-legal",
    "label": "Medico-Legal",
    "sectionId": "medico-legal",
    "clickable": false
  },
  "non clinical": {
    "enable": true,
    "sectionId": "non clinical",
    "clickable": true,
    "tag": "non clinical",
    "order": 4,
    "icon": "https://storage.googleapis.com/docflix-d8cab.appspot.com/docflix-app/assets/images/tagsIcon/Non%20clinical.png",
    "label": "Non Clinical"
  },
  "ready recokner": {
    "order": 2,
    "tag": "ready recokner",
    "icon": "https://storage.googleapis.com/docflix-d8cab.appspot.com/docflix-app/assets/images/tagsIcon/ready%20rocnor.png",
    "enable": true,
    "sectionId": "ready recokner",
    "clickable": true,
    "label": "Ready Recokner"
  },
  "trending now": {
    "clickable": true,
    "enable": true,
    "sectionId": "trending now",
    "icon": "https://storage.googleapis.com/docflix-d8cab.appspot.com/docflix-app/assets/images/tagsIcon/Trending%20now.png",
    "order": 1,
    "tag": "trending now",
    "label": "Trending Now"
  }
}

// export const TAGS_DATA = {
//   "Heart failure": {
//     icon: heartFailure,
//     sectionId: "Heart failure",
//     label: "Heart failure",
//   },
//   "Con-Verse": {
//     icon: converse,
//     sectionId: "Con-Verse",
//     label: "Con-Verse",
//   },
//   Roadinar: {
//     icon: roadinar,
//     sectionId: "Roadinar",
//     label: "Roadinar",
//   },
//   "covid-19": {
//     icon: dummyImg,
//     sectionId: "Covid-19",
//     label: "Covid-19",
//   },
//   Hypertension: {
//     icon: hypertension,
//     sectionId: "Hypertension",
//     label: "Hypertension",
//   },
//   Wiley: {
//     icon: wiley,
//     sectionId: "Wiley",
//     label: "Wiley",
//   },
//   Elsevier: {
//     icon: elsevier,
//     sectionId: "Elsevier",
//     label: "Elsevier",
//   },
//   Guidelines: {
//     icon: dummyImg,
//     sectionId: "Guidelines",
//     label: "Guidelines",
//   },
//   "Ready Recokner": {
//     icon: dummyImg,
//     sectionId: "Ready Recokner",
//     label: "Ready Recokner",
//   },
//   "Trending Now": {
//     icon: dummyImg,
//     sectionId: "Trending Now",
//     label: "Trending Now",
//   },
//   "Non Clinical": {
//     icon: dummyImg,
//     sectionId: "Non Clinical",
//     label: "Non Clinical",
//   },
// };
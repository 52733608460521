import {
  AGENDA_COLLECTION,
  BACKSTAGE_COLLECTION,
  STATIC_WEBINAR_DOC,
  WEBINAR_COLLECTION,
} from "../../AppConstants/CollectionConstants";
import {
  static_page_event_id,
  VIDEO_FETCH_LIMIT,
} from "../../AppConstants/Constants";
import { DESCENDING, TIMESTAMP } from "../../AppConstants/SortConstants";
import { firestore } from "../../firebase/firebase";

let static_Webinar_video_Ref = null;

const getWebinar = async (webinarId) => {
  try {
    let docRef = firestore.collection(WEBINAR_COLLECTION).doc(webinarId);
    const _data = await firestore.runTransaction(async (transaction) => {
      const doc = await transaction.get(docRef);
      if (!doc.exists) {
        const error = {
          code: "NotValidId",
          message: "No webinar found",
        };
        throw error;
      }

      let _data = doc.data();

      return _data;
    });

    return _data;
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const fullWebinar = async (webinarId) => {
  try {
    let docRef = firestore.collection(WEBINAR_COLLECTION).doc(webinarId);
    const _data = await firestore.runTransaction(async (transaction) => {
      const doc = await transaction.get(docRef);
      if (!doc.exists) {
        const error = {
          code: "NotValidId",
          message: "No webinar found",
        };
        throw error;
      }

      let _data = doc.data();

      if (_data.agendas && _data.agendas.length) {
        let promiseArr = [];
        for (let id of _data.agendas) {
          promiseArr.push(
            transaction.get(firestore.collection(AGENDA_COLLECTION).doc(id))
          );
        }

        _data.agendaData = await Promise.all(promiseArr);
        _data.agendaData = _data.agendaData.map((doc) => doc.data());
      }

      return _data;
    });

    return _data;
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const getSortedWebinars = async (
  tags = [],
  based_on = TIMESTAMP,
  order = DESCENDING,
  lastDocRef = null,
  limit = VIDEO_FETCH_LIMIT,
  ignoreLimit = false
) => {
  try {
    // console.log('WIP');
    let docRef = firestore.collection(WEBINAR_COLLECTION);
    if (tags && tags.length) {
      tags = tags.map((t) => t.toLowerCase());
      docRef = docRef.where("tags", "array-contains-any", tags);
    }

    docRef = docRef.orderBy(based_on, order);
    if (!ignoreLimit) docRef = docRef.limit(limit);

    if (lastDocRef) docRef = docRef.startAfter(lastDocRef);
    const query = await docRef.get();

    if (query.empty) {
      return [];
    }

    // Updating last docRef
    lastDocRef = query.docs[query.docs.length - 1];

    let _data = [];
    query.docs.forEach((doc) => {
      _data.push(doc.data());
    });
    return { data: _data, lastDocRef };
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const attachStaticWebinarVideoListener = (
  callback = () => console.log("noFunFound")
) => {
  const ref = firestore
    .collection(BACKSTAGE_COLLECTION)
    .doc(STATIC_WEBINAR_DOC);
  static_Webinar_video_Ref = ref.onSnapshot(
    (query) => {
      if (!query.exists) {
        callback(null);
      }
      let _data = query.data()[static_page_event_id];
      callback(_data);
    },
    (err) => {
      callback(null, err);
    }
  );
};

const removeStaticWebinarVideoListener = () => {
  if (static_Webinar_video_Ref) {
    static_Webinar_video_Ref();
  }
};

const get = {
  getSortedWebinars,
  getWebinar,
  fullWebinar,
  attachStaticWebinarVideoListener,
  removeStaticWebinarVideoListener,
};

export default get;

export const AREA_OF_INTEREST = [
  "Acute Coronary Syndromes (ACS)",
  "Arrhythmia & EP",
  "Atrial Fibrillation",
  "CV Imaging",
  "CVD Prevention",
  "Heart Failure",
  "Hypertension",
  "Interventional Cardiology & Surgery",
  "Lipid & Metabolic",
  "Stroke/ Cerebrovascular Disease",
];

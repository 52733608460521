//Imports
import { SPEAKERS_COLLECTION } from "../../AppConstants/CollectionConstants";
import { ASCENDING, DESCENDING, TIMESTAMP } from "../../AppConstants/SortConstants";
import { ContentType } from "../../AppConstants/TypeConstants";
// Firebase
import firebase, { firestore } from "../../firebase/firebase";

const getSpeakerById = async (speakerId) => {
  try {
    const docRef = firestore.collection(SPEAKERS_COLLECTION).doc(speakerId);
    const _data = await firestore.runTransaction(async (transcation) => {
      let doc = await transcation.get(docRef);
      if (!doc.exists) {
        let err = {
          code: "NotValidId",
          message: "No details found",
        };
        throw err;
      }

      return doc.data();
    });
    return _data;
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const getAllSpeakers = async ({
  based_on = TIMESTAMP,
  order = DESCENDING,
  lastDocRef = null,
  limit = 10,
  ignoreLimit = false,
  data = [],
}) => {
  try {
    let docRef = firestore.collection(SPEAKERS_COLLECTION);

    docRef = docRef.orderBy(based_on, order);

    if (!ignoreLimit) {
      docRef = docRef.limit(limit);
    }

    // Pagination
    if (lastDocRef) {
      docRef = docRef.startAfter(lastDocRef);
    }
    const query = await docRef.get();

    // Updating last docRef
    lastDocRef = query.docs[query.docs.length - 1];

    if (query.empty) {
      return [];
    }

    let _data = data;
    query.docs.forEach((doc) => {
      _data.push(doc.data());
    });
    return { data: _data, lastDocRef };
  } catch (error) {
    console.error("ERROR IS AT OUR SPEAKER", { error });
  }
};

const get = {
  getSpeakerById,
  getAllSpeakers,
};

export default get;

import style from "./index.module.css";

export function AddToCalendarBlueBtn({ children, onClick }) {
  const handleClick = (e) => {
    if (window.AddToCalendarAnalytic) {
      window.AddToCalendarAnalytic();
    }
    onClick(e);
  };

  return (
    <button
      id="addToCal"
      className={`btn ${style.bannerBox__btn}`}
      onClick={handleClick}
    >
      {children}
    </button>
  );
}

import moment from "moment";
import { useContext, useMemo } from "react";
import AddToCalendarHOC from "react-add-to-calendar-hoc";
import { ADDTOCALENDAR_ANALYTICS_EVENT } from "../../AppConstants/AnalyticsEventName";
import { AnalyticsContext } from "../../contexts/Analytics/AnalyticsContextProvider";
import { AddToCalendarBlueBtn } from "./AddToCalendarBtn";
import AddToCalendarDropDown from "./AddToCalendarDropDown";

import style from "./index.module.css";

const AddToCalendarComp = AddToCalendarHOC(
  AddToCalendarBlueBtn,
  AddToCalendarDropDown
);

export default function AddToCalendar({ eventDetails }) {
  const { addGAWithUserInfo } = useContext(AnalyticsContext);
  const origin = window.location.origin;
  const event = useMemo(() => {
    const startDatetime = moment(eventDetails.startTime.seconds * 1000).utc();
    const endDatetime = moment(eventDetails.endTime.seconds * 1000).utc();
    const duration = endDatetime.diff(startDatetime, "hours");
    return {
      description: eventDetails.description,
      duration,
      endDatetime: endDatetime.format("YYYYMMDDTHHmmssZ"),
      location: `${origin}/webinar/${eventDetails.id}/`,
      startDatetime: startDatetime.format("YYYYMMDDTHHmmssZ"),
      title: eventDetails.title,
    };
  }, [eventDetails]);

  window.AddToCalendarAnalytic = (optionName = "") => {
    if (optionName === "") {
      addGAWithUserInfo(ADDTOCALENDAR_ANALYTICS_EVENT, {
        eventId: eventDetails.id,
      });
    } else {
      addGAWithUserInfo(ADDTOCALENDAR_ANALYTICS_EVENT + "_" + optionName, {
        eventId: eventDetails.id,
      });
    }
  };

  return (
    <AddToCalendarComp
      className={`${style.addToCalendar}`}
      linkProps={{
        className: "linkStyles",
      }}
      event={event}
    />
  );
}

import { useEffect, useState } from "react";
import { isMobileDevice } from "../utils/getPlatformInfo";

function useTileScroller({
  // noOfTiles,
  // onEndReached = () => {},
  elementId = "",
  mobileTileWidth = 337,
  webTileWidth = 837, // can be removed after giving webTileId to tile/card
  tileGapWeb = 32,
  autoScrollToCard = true,
  webTileId = "",
  indexing = false,
  tileGapMob = 0,
  tileGapWebScroll = 32,
  allowScroll = true,
  initalIndex = 0,
}) {
  const [currentIndex, setCurrentIndex] = useState(initalIndex);

  const updateIndex = (index) => {
    const myElement = document.getElementById(elementId);
    let tileCard;
    if (indexing) {
      tileCard = document.getElementById(`${webTileId}${index}`);
    } else {
      tileCard = document.getElementById(webTileId);
    }
    const modifiedWidth = tileCard?.offsetWidth || webTileWidth;
    const scrollOffset = isMobileDevice
      ? (tileCard?.offsetWidth || mobileTileWidth) + tileGapMob
      : modifiedWidth + tileGapWeb;
    if (myElement) {
      myElement.scroll({
        left: scrollOffset * index,
        behavior: "smooth",
      });
    }
    setTimeout(() => {
      if (index < 0) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(index);
      }
    }, 100);
  };

  useEffect(() => {
    let timer = null; // wait for user to stop scrolling
    const onScroll = () => {
      let tileCard;
      let tileWidth;
      if (indexing) {
        tileCard = document.getElementById(`${webTileId}${currentIndex}`);
        tileWidth = tileCard?.offsetWidth;
      } else {
        tileWidth = isMobileDevice
          ? mobileTileWidth - tileGapMob
          : webTileWidth - tileGapWebScroll;
      }
      const myElement = document.getElementById(elementId);
      const windowScroll = myElement.scrollLeft;
      const activeIndex = Math.round(windowScroll / tileWidth);
      if (windowScroll === 0) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(activeIndex);
      }
      if (timer !== null) {
        clearTimeout(timer);
      }
      if (autoScrollToCard) {
        timer = setTimeout(function () {
          const modifiedWidth = tileWidth || webTileWidth;
          const scrollOffset = isMobileDevice
            ? mobileTileWidth
            : modifiedWidth + tileGapWeb;
          if (
            Math.abs(
              windowScroll - scrollOffset * Math.round(windowScroll / tileWidth)
            ) > 10
          ) {
            myElement.scrollTo({
              left: scrollOffset * Math.round(windowScroll / tileWidth),
              behavior: "smooth",
            });
          }
        }, 150);
      }
    };
    allowScroll &&
      document.getElementById(elementId).addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  // const endReachedFunction = useCallback(() => {
  //   if (currentIndex >= noOfTiles - 1) {
  //     // @TODO Akash Remove warn
  //     console.warn("end is near");
  //     onEndReached();
  //   }
  // }, [currentIndex, noOfTiles, onEndReached]);

  // useEffect(() => {
  //   endReachedFunction();
  // }, [endReachedFunction]);

  return {
    currentIndex,
    updateIndex,
  };
}

export default useTileScroller;

import React, { useContext, useEffect, useRef, useState } from "react";
import { AREA_OF_INTEREST } from "../../../../AppData/AreaOfInterest";
import { Profession_DATA } from "../../../../AppData/Profession";
import { SPECIALITY_DATA } from "../../../../AppData/Speciality";
import LoaderImg from "../../../../assets/images/Loader.gif";
import lockIconImg from "../../../../assets/images/lockIcon.png";

import { newAuthContext } from "../../../../contexts/AuthContext/NewAuthContextProvider";
import styles from "./PersonalDetails.module.scss";

// const sessionToken = new window.google.maps.places.AutocompleteSessionToken();
let autoComplete;
// const url = "https://maps.googleapis.com/maps/api/js?key=AIzaSyArOoD3H8IiCZNK87pZ9JH3WcQ1oyMqWbg&libraries=places"

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const PersonalDetails = ({ old = undefined }) => {
  const { state, handleFormDetails, handleRegisterBtn, updateProfile } =
    useContext(newAuthContext);

  const { errors, formDetails, loading } = state;
  const { firstName, profession, speciality, areaOfInterest } = formDetails;
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    // AIzaSyArOoD3H8IiCZNK87pZ9JH3WcQ1oyMqWbg  old
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyBd1dhvUFsSXLntV6og1jljFxEl1S_6oBA&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["(cities)"] }
    );
    // autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  };

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const value = addressObject.formatted_address;

    let lat = addressObject.geometry?.location.lat();
    let lng = addressObject.geometry?.location.lng();
    if (!lat) {
      lat = 20.5937;
    }
    if (!lng) {
      lng = 78.9629;
    }
    // console.log("updateQuery");
    // console.log(addressObject.address_components);

    // let address_components = res.data.results[0].address_components;
    let locationResult = {
      state: "",
      city: "",
      country: "",
      pincode: null,
      location: {
        lat,
        lng,
      },
    };

    addressObject.address_components.forEach((element) => {
      if (element.types.indexOf("locality") !== -1) {
        locationResult.city = element.long_name;
      } else if (element.types.indexOf("administrative_area_level_1") !== -1) {
        locationResult.state = element.long_name;
      } else if (element.types.indexOf("country") !== -1) {
        locationResult.country = element.long_name;
      } else if (element.types.indexOf("postal_code") !== -1) {
        locationResult.pincode = element.long_name;
      }
    });
    updateQuery(value);
    if (!locationResult.city) {
      locationResult.city = locationResult.state;
    }

    handleFormDetails({ city: locationResult });
  }

  return (
    <div className={styles["personalDetail-form"]}>
      {old && (
        <>
          <h1>Almost There</h1>
          <p>
            Hey {firstName}, please share a few more professional details about
            you to register on Docflix.
          </p>
        </>
      )}
      <div className={styles["profiledetails"]}>
        <div className={styles["input-group"]}>
          <label htmlFor="profession">Profession</label>
          <select
            id="profession"
            name="profession"
            className="form-control"
            defaultValue="Select Your Profession"
            value={profession}
            onChange={(e) => handleFormDetails({ profession: e.target.value })}
            required
          >
            <option value="" hidden>
              Select Your Profession
            </option>
            {Profession_DATA.map((p) => (
              <option key={p} value={p}>
                {p}
              </option>
            ))}
          </select>
          {errors.profession && (
            <div className="error">{errors.profession}</div>
          )}
        </div>

        <div className={styles["input-group"]}>
          <label htmlFor="speciality">Speciality</label>
          <select
            id="speciality"
            name="speciality"
            className="form-control"
            defaultValue="Select Your Speciality"
            value={speciality}
            onChange={(e) => handleFormDetails({ speciality: e.target.value })}
            required
          >
            <option value="" hidden>
              Select Your Speciality
            </option>
            {SPECIALITY_DATA.map((p) => (
              <option key={p} value={p}>
                {p}
              </option>
            ))}
          </select>
          {errors.speciality && (
            <div className="error">{errors.speciality}</div>
          )}
        </div>

        <div className={styles["input-group"]}>
          <label htmlFor="areaOfInterest">Area of Interest</label>
          <select
            id="areaOfInterest"
            name="areaOfInterest"
            className="form-control"
            defaultValue={"Select Your Area of Interest"}
            value={areaOfInterest}
            onChange={(e) =>
              handleFormDetails({ areaOfInterest: e.target.value })
            }
            required
          >
            <option value="" hidden>
              Select Your Area of Interest
            </option>
            {AREA_OF_INTEREST.map((p) => (
              <option key={p} value={p}>
                {p}
              </option>
            ))}
          </select>
          {errors.areaOfInterest && (
            <div className="error">{errors.areaOfInterest}</div>
          )}
        </div>

        <div className={styles["input-group"]}>
          <label htmlFor="city">City</label>
          <input
            style={{ color: "#888888" }}
            className="form-control"
            ref={autoCompleteRef}
            onChange={(event) => setQuery(event.target.value)}
            placeholder="Enter a City"
            value={query}
            required
          />
          {/* <PlacesAutocomplete
          onError={onError}
          value={formDetails.city}
          onChange={(value) => {
            handleSignUpform({ city: value });
          }}
          onSelect={handleSelect}
          searchOptions={{
            sessionToken: sessionToken,
            types: ["(cities)"],
          }}
          debounce={800}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div style={{ position: "relative" }}>
              <input
                style={{ marginBottom: "0.5rem" }}
                name="city"
                type="text"
                value={formDetails.address}
                onChange={(e) => {
                  if (error) {
                    setError(false);
                  }
                  handleSignUpform({ address: e.target.value });
                }}
                required
                id="city"
                className="form-control"
                {...getInputProps({ placeholder: "Type City" })}
              />
              <div
                style={{
                  position: "absolute",
                  zIndex: "10",
                  border: `${
                    suggestions.length === 0
                      ? "0px"
                      : "1px solid rgba(255, 255, 255, 0.4)"
                  }`,
                  width: "100%",
                }}
                className="dropdown"
              >

                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active
                      ? "#41b6e6"
                      : "rgba(44, 64, 103, 1)",
                    color: "#ffff",
                    cursor: "pointer",
                  };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        style,
                      })}
                    >
                      <option
                        style={{ width: "100%" }}
                        key={suggestion.description}
                        value={suggestion.description}
                      >
                        {suggestion.description}
                      </option>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete> */}
          {errors.city && <div className="error">{errors.city}</div>}
        </div>

        {old ? (
          <button
            onClick={handleRegisterBtn}
            disabled={loading}
            className={`btn ${styles["register-btn"]}`}
            style={{ marginTop: "5px" }}
          >
            {loading ? (
              <img src={LoaderImg} alt="" height="100%" />
            ) : (
              "Register"
            )}
          </button>
        ) : (
          <div className={styles.premiumBtn}>
            <div>
              <img src={lockIconImg} alt="" />
              <div>
                Feel safe with us, your information is not shared with anyone.
                For more read our&nbsp;
                <span>Privacy Policy</span>
              </div>
            </div>
            <button
              onClick={updateProfile}
              disabled={loading}
              className={`btn ${styles["register-btn"]}`}
              style={{ marginTop: "5px" }}
            >
              {loading ? (
                <img src={LoaderImg} alt="" height="100%" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonalDetails;

// import brandLogoLight from "../../assets/images/D-Light.png";

import styles from "./index.module.scss";
import { useContext, useEffect, useState } from "react";
import landingPageBg from "../../assets/images/converseBg.jpg";
import landingPageBgMob from "../../assets/images/converseBgMob.png";

import { isMobileDevice } from "../../utils/getPlatformInfo";
import esh from "../../assets/images/aha_titleMob.png";
import eshDesktop from "../../assets/images/aha_title.png";
import footerDocflixImg from "../../assets/images/landingPage/docflixLogo.png";
import footerMankindDocflix from "../../assets/images/landingPage/mankindLogo.png";
import DocImage from "../../assets/images/aha_doc.png";
import DocImage1 from "../../assets/images/doc1.png";
import DocImage2 from "../../assets/images/doc_2.png";
import DocImage3 from "../../assets/images/doc_3.png";

import shareIcon from "../../assets/images/shareIcon.png";

import { AnalyticsContext } from "../../contexts/Analytics/AnalyticsContextProvider";

import { newAuthContext } from "../../contexts/AuthContext/NewAuthContextProvider";
import OTPForm from "../../components/Signup/partials/OTPForm/OTPForm";
import Login from "../../components/Login/Login";
import converse from "../../assets/images/converseTitle.png";
import ConverseJoin from "./partials/ConverseJoin";
import { useLocation } from "react-router-dom";
import TeamModal from "./partials/TeamModal";
import { showToast } from "../../utils/common.utils";

import shareImg from "../../assets/images/share.png";
import whatsappImg from "../../assets/images/whatsapp.png";
import MailImg from "../../assets/images/notes.png";
import smsImg from "../../assets/images/feedback.png";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext/UserContextProvider";
import { webinarContext } from "../../contexts/WebinarContext/WebinarContextProvider";
import { FooterNew } from "../../components/ContactFooter/FooterNew";

const mediumBtns = [
  { id: "normal", image: shareImg, title: "Get Link" },
  // { id: "whatsapp", image: whatsappImg, title: "Whatsapp" },
  // { id: "mail", image: MailImg, title: "Mail" },
  // { id: "sms", image: smsImg, title: "Sms" },
];

const Converse = (props) => {
  const { addGANoUser } = useContext(AnalyticsContext);
  const { toggleSignInbtn, state } = useContext(newAuthContext);

  const { user, userBasicDetails, isUserOTPVerified } = useContext(UserContext);
  const { static_webinarVideoId } = useContext(webinarContext);

  const { pathname, search = "", hash = "" } = useLocation();

  const teamPage = pathname.includes("/team");
  const { showOTPModal, showSignInModal, showJoinModal, formDetails } = state;
  let history = useHistory();

  const [returnURL, setReturnURL] = useState(null);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [teamPhoneNumber, setTeamPhoneNumber] = useState("");
  const [isTeamUrl, setIsTeamUrl] = useState(false);
  // JSON.parse(sessionStorage.getItem("teamNo"))
  const handleJoinModalOpen = () => {
    addGANoUser("join_the_waitlist_btn");
    toggleSignInbtn("showSignInModal", true);
  };

  // useEffect(() => {
  //   if (user && !isUserOTPVerified) {
  //     if (!showOTPModal) {
  //       toggleSignInbtn("showOTPModal", true);
  //     }
  //   }
  // }, [user]);

  useEffect(() => {
    let obj = {
      return: new URLSearchParams(search).get("return"),
    };
    setReturnURL(obj.return);
  }, [search]);

  const handleShareBtn = (id) => {
    copyShareURL(id);
  };

  const copyShareURL = (id) => {
    navigator.clipboard.writeText(
      JSON.parse(sessionStorage.getItem("teamNo"))[id]
    );
    showToast("Link copied successfully!!");
  };
  const handleCreateLinkBtn = () => {
    setShowTeamModal(true);
  };

  const number =
    userBasicDetails?.phoneNumber || formDetails?.phoneNumber || false;

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <div className={styles.containerOverlay}>
            <div className={styles.brandLogo}>
              <img src={footerDocflixImg} alt="Docflix" />
              <img src={footerMankindDocflix} alt="Docflix" />
            </div>
            <img className={styles.converseImg} src={converse} alt="title" />
            {/* <img
              className={`${styles.converseImg} ${styles.converseImg2}`}
              src={require("../../assets/images/futureLogo.png")}
              alt=""
            /> */}
            <div className={styles.detail}>
              {/* <div className={styles.mainTitle}>OTT for doctors where</div> */}
              <div className={styles.heading}>
                <img
                  className={styles.eshHighlights}
                  src={eshDesktop}
                  alt="ESH 2024"
                />

                <div className={styles.date}>31st May - 3rd June 2024</div>
                <div className={styles.doctorDetails}>
                  <img
                    style={{ width: "10rem" }}
                    src={DocImage}
                    alt="DocImage"
                  />
                  <div>
                    <div className={styles.docDescription}>Presented by</div>
                    Dr. Jagdish Hiremath
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "1.5rem",
                        maxWidth: "100%",
                        marginTop: "0.3rem",
                      }}
                    >
                      MBBS, MD, DM, DNB
                      <br />
                      Director, Cath. Lab.
                      <br />
                      Ruby Hall Clinic <br />
                      Pune, Maharashtra, India
                    </div>
                  </div>
                </div>
                {/* <div className={styles.remainingDoctors}>
                  <div className={styles.left}>
                    <h3>National Speaker</h3>
                    <div className={styles.doctor}>
                      <img src={DocImage1} alt="DocImage" />
                      <div>
                        Dr. Uday M. Jadhav
                        <div className={styles.desc}>
                          Consultant Cardiologist
                          <br />
                          MGM Hospital, <br />
                          Navi Mumbai, India
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.right}>
                    <h3>International Speaker</h3>
                    <div className={styles.speakers}>
                      <div className={styles.doctor}>
                        <img src={DocImage2} alt="DocImage" />
                        <div>
                          Dr. Robert Mentz
                          <div className={styles.desc}>
                            MD,
                            <br />
                            Chief, Heart Failure Section at
                            <br />
                            Duke University Medical Center
                            <br />
                            Editor-in-chief, JCF
                            <br />
                            Trialist and Outcome Researcher
                          </div>
                        </div>
                      </div>
                      <div className={styles.doctor}>
                        <img src={DocImage3} alt="DocImage" />
                        <div>
                          Dr. Deepak L. Bhatt
                          <div className={styles.desc}>
                            MD, MPH, FACC, FAHA, MSCAI, FESC
                            <br />
                            Director of Mount Sinai Heart and the
                            <br />
                            Dr. Valentin Fuster Professor of Cardiovascular
                            Medicine,
                            <br />
                            Icahn School of Medicine at Mount Sinai Health
                            System
                            <br />
                            New York, United States
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              {teamPage ? (
                <div className="d-flex">
                  {isTeamUrl ? (
                    <>
                      {/* <button
                        className={`btn`}
                        style={{ flexDirection: "row" }}
                        onClick={handleShareBtn}
                      >
                        <img
                          src={shareIcon}
                          alt=""
                          style={{ height: "2rem", marginRight: "1rem" }}
                        />
                        <span>Share with doctor</span>
                      </button> */}
                      <div className={styles.mainTitle}>
                        {/* Click the following icons to copy share link */}
                        {/* <br /> */}
                        <div
                          className={"d-flex"}
                          style={{ gap: "2rem", paddingTop: "1rem" }}
                        >
                          {mediumBtns.map(({ title, id, image }) => (
                            <div
                              key={id}
                              className={`btn d-flex ${styles.customShareBtn}`}
                              onClick={() => handleShareBtn(id)}
                            >
                              <img src={image} alt={id} />
                              {title}
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <button className={`btn`} onClick={handleCreateLinkBtn}>
                      Create Link
                    </button>
                  )}
                </div>
              ) : (
                <div className="d-flex">
                  <button className={`btn`} onClick={handleJoinModalOpen}>
                    REGISTER NOW
                  </button>
                  {/* {returnURL && ( */}
                  {static_webinarVideoId && (
                    <button
                      className={`btn`}
                      style={{ marginLeft: "2rem" }}
                      onClick={() => {
                        if (user) {
                          // window.open(returnURL, "_self");
                          history.push(`/video/${static_webinarVideoId}`);
                        } else {
                          toggleSignInbtn("showSignInModal", true);
                        }
                      }}
                    >
                      ENTER NOW
                    </button>
                  )}
                  {/* )} */}
                </div>
              )}
              {/* <div
                className="d-flex"
                style={{
                  gap: "0.5rem",
                  marginTop: "1rem",
                }}
              >
                <img src={require("../../assets/images/logo1.jpg")} />
                <img src={require("../../assets/images/logo2.jpg")} />
              </div> */}
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={`${styles.detail} ${styles.detailMobile}`}>
            {/* <div className={styles.mainTitle}>OTT for doctors where</div>s */}
            <div className={styles.heading}>
              <img
                className={styles.eshHighlightsMobile}
                src={esh}
                alt="ESH 2024"
              />
              <div className={styles.date}>31st May - 3rd June 2024</div>
              <div
                style={{ fontWeight: "700" }}
                className={styles.doctorDetails}
              >
                <img style={{ width: "8rem" }} src={DocImage} alt="DocImage" />
                <div>
                  <div className={styles.docDescription}>Presented by</div>Dr.
                  Jagdish Hiremath
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "0.8rem",
                      marginTop: "0.25rem",
                    }}
                  >
                    MBBS, MD, DM, DNB
                    <br />
                    Director, Cath. Lab.
                    <br />
                    Ruby Hall Clinic <br />
                    Pune, Maharashtra, India
                  </div>
                </div>
              </div>
              {/* <div className={styles.remainingDoctors}>
                <h4>National Speaker</h4>
                <div className={styles.doctor}>
                  <img src={DocImage1} alt="DocImage" />
                  <div>
                    Dr. Uday M. Jadhav
                    <div className={styles.desc}>
                      Consultant Cardiologist
                      <br />
                      MGM Hospital, <br />
                      Navi Mumbai, India
                    </div>
                  </div>
                </div>
                <h4>International Speaker</h4>
                <div className={styles.doctor}>
                  <img src={DocImage2} alt="DocImage" />
                  <div>
                    Dr. Robert Mentz
                    <div className={styles.desc}>
                      MD,
                      <br />
                      Chief, Heart Failure Section at
                      <br />
                      Duke University Medical Center
                      <br />
                      Editor-in-chief, JCF
                      <br />
                      Trialist and Outcome Researcher
                    </div>
                  </div>
                </div>
                <div className={styles.doctor}>
                  <img src={DocImage3} alt="DocImage" />
                  <div>
                    Dr. Deepak L. Bhatt
                    <div className={styles.desc}>
                      MD, MPH, FACC, FAHA, MSCAI, FESC
                      <br />
                      Director of Mount Sinai Heart and the
                      <br />
                      Dr. Valentin Fuster Professor of Cardiovascular Medicine,
                      <br />
                      Icahn School of Medicine at Mount Sinai Health System
                      <br />
                      New York, United States
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {teamPage ? (
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                {isTeamUrl ? (
                  <>
                    {/* <button
                      className={`btn`}
                      style={{ padding: "1rem", flexDirection: "row" }}
                      onClick={handleShareBtn}
                    >
                      <img
                        src={shareIcon}
                        alt=""
                        style={{ height: "2rem", marginRight: "1rem" }}
                      />
                      <span>Share with doctor</span>
                    </button> */}
                    <div className={styles.mainTitle}>
                      {/* Click the following icons to copy share link */}
                      {/* <br /> */}
                      <div
                        className={"d-flex"}
                        style={{
                          gap: "2rem",
                          paddingTop: "1rem",
                          flexWrap: "wrap",
                        }}
                      >
                        {mediumBtns.map(({ title, id, image }) => (
                          <div
                            key={id}
                            className={`btn d-flex ${styles.customShareBtn}`}
                            onClick={() => handleShareBtn(id)}
                          >
                            <img src={image} alt={id} />
                            {title}
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <button
                    className={`btn`}
                    style={{ padding: "1rem" }}
                    onClick={handleCreateLinkBtn}
                  >
                    Create Link
                  </button>
                )}
              </div>
            ) : (
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <button
                  className={`btn`}
                  style={{ padding: "1rem" }}
                  onClick={handleJoinModalOpen}
                >
                  REGISTER NOW
                </button>
                {/* {returnURL && ( */}
                {static_webinarVideoId && (
                  <button
                    className={`btn`}
                    style={{ padding: "1rem" }}
                    onClick={() => {
                      if (user) {
                        // window.open(returnURL, "_self");
                        history.push(`/video/${static_webinarVideoId}`);
                      } else {
                        toggleSignInbtn("showSignInModal", true);
                      }
                    }}
                  >
                    ENTER NOW
                  </button>
                )}
              </div>
            )}
            {/* )} */}
            {/* <div
              className="d-flex"
              style={{
                gap: "0.5rem",
                marginTop: "1rem",
              }}
            >
              <img src={require("../../assets/images/logo1.jpg")} />
              <img src={require("../../assets/images/logo2.jpg")} />
            </div> */}
          </div>
        </div>
      </div>
      <FooterNew />

      {showTeamModal && (
        <TeamModal
          setShowTeamModal={setShowTeamModal}
          teamPhoneNumber={teamPhoneNumber}
          setTeamPhoneNumber={setTeamPhoneNumber}
          setIsTeamUrl={setIsTeamUrl}
          teamId={props?.props?.teamId ? props.props.teamId : null}
        />
      )}

      {showSignInModal && <Login teamId={props?.props?.teamId ? props.props.teamId : null} />}
      {!teamPage && showOTPModal && number && (
        <div className={`modalOverlayWithBlur ${styles["OTPContainer"]}`}>
          <div className={styles.box}>
            <OTPForm data={{ phoneNumber: number }} />
          </div>
        </div>
      )}
      {showJoinModal && (
        <ConverseJoin
          teamId={props?.props?.teamId ? props.props.teamId : null}
        />
      )}
    </>
  );
};

export default Converse;

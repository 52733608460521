import { useTheme } from "../../contexts/ThemeContext/ThemeContext";
import Switch from "react-switch";
import sunIcon from "../../assets/images/sun.png";
import moonIcon from "../../assets/images/moonLight1.png";
import moonLightIcon from "../../assets/images/moonLight.png";
import sunDarkIcon from "../../assets/images/sunDark.png";
import styles from "./index.module.scss";

import DocflixLightIcon from "../../assets/images/D-Light.png";
import DocflixDarkIcon from "../../assets/images/DLight.png";

function ThemeSwitch() {
  const { isDark, switchTheme } = useTheme();
  const handleChange = (checked) => {
    switchTheme(checked);
  };
  return (
    <Switch
      onChange={handleChange}
      checked={isDark}
      onColor={"#38527E"}
      offColor={"#eb4547"}
      uncheckedIcon={
        <img src={moonLightIcon} alt="light" className={styles.switchIcon} />
      }
      checkedHandleIcon={
        <img src={moonIcon} alt="light" className={styles.switchIcon} />
      }
      uncheckedHandleIcon={
        <img
          src={DocflixDarkIcon}
          alt="dark"
          className={`${styles.switchIcon} ${styles.DIcon}`}
        />
      }
      checkedIcon={
        <img
          src={DocflixLightIcon}
          alt="dark"
          className={`${styles.switchIcon} ${styles.DIcon}`}
        />
      }
      height={38}
      width={80}
      className={styles.customSwitch}
      handleDiameter={32}
      borderRadius={32}
    />
  );
}

export default ThemeSwitch;

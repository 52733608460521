import styles from "./styles.module.scss";
import playBtn from "../../../assets/images/playBtnn.png";
import play from "../../../assets/images/play.png";
import plus from "../../../assets/images/plus.png";
import exclusiveIcon from "../../../assets/images/exclusiveicon.png";
import premiumIcon from "../../../assets/images/premiumicon.png";
import share from "../../../assets/images/share.png";
import shareLight from "../../../assets/images/shareLight.png";
import { useTheme } from "../../../contexts/ThemeContext/ThemeContext";
import { useHistory } from "react-router-dom";
import { ExclusiveType } from "../../../AppConstants/TypeConstants";
import { secondsToHms } from "../../../utils/common.utils";

function EpisodesTab({
  episodes = [],
  handleInviteBtn,
  setCurrentVideoShare,
  tags = [],
}) {
  const { isDark } = useTheme();
  let history = useHistory();

  const changeRoute = (route) => {
    let goRoute = `/video/${route}`;

    history.push(goRoute);
  };

  return (
    <>
      <div className={styles.episodesWrapper}>
        {/* <div className={styles.header}>Episodes({episodes.length})</div> */}
        <div>
          {episodes.map((episode, index) => {
            return (
              <div
                key={index}
                className={styles.episodeCard}
                data-aos="fade-up"
                data-aos-duration="200"
                data-aos-delay={index * 50}
                data-aos-easing="ease-in-sine"
                data-aos-once="true"
              >
                <div
                  className={styles.thumbnail}
                  onClick={() => changeRoute(episode.id)}
                >
                  <img
                    loading="lazy"
                    src={episode.thumbnailUrl}
                    alt="episode"
                    className={styles.episodeImg}
                  />
                  {/* <img
                    loading="lazy"
                    src={playBtn}
                    alt="play"
                    className={`${styles.playBtn} hvr-bounce-in`}
                  /> */}
                  {episode.type && episode.type !== ExclusiveType.FREE && (
                    <img
                      src={
                        episode.type === ExclusiveType.PREMIUM
                          ? premiumIcon
                          : episode.type === ExclusiveType.EXCLUSIVE
                          ? exclusiveIcon
                          : ""
                      }
                      alt={
                        episode.type === ExclusiveType.PREMIUM
                          ? ExclusiveType.PREMIUM
                          : episode.type === ExclusiveType.EXCLUSIVE
                          ? ExclusiveType.EXCLUSIVE
                          : ""
                      }
                      className={`${styles.videoType} hvr-bounce-in`}
                    />
                  )}
                </div>
                <div className={styles.details}>
                  <div>
                    {/* <div className={styles.episode}>{`Episode ${index + 1}`}</div> */}
                    <div className={styles.title}>{episode.title}</div>
                    <div className={styles.duration}>
                      {`${secondsToHms(episode.duration)} mins`}
                    </div>
                  </div>
                  <div className={styles.btnsWrapper}>
                    <div
                      className={`${styles.actionBtn}`}
                      onClick={() => changeRoute(episode.id)}
                    >
                      <img loading="lazy" src={play} alt="play" /> Play
                    </div>
                    {/* <div className={`${styles.actionBtn}`}>
                    <img loading="lazy" src={plus} alt="add" /> Add to list
                  </div> */}
                    {/* <div
                      className={`${styles.actionBtn}`}
                      onClick={() => {
                        handleInviteBtn();
                        setCurrentVideoShare(episode);
                      }}
                    >
                      <img
                        loading="lazy"
                        src={isDark ? share : shareLight}
                        alt="share"
                      />{" "}
                      Share
                    </div> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default EpisodesTab;

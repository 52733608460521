import { useContext, useEffect } from "react";
import Router from "./router";
import AOS from "aos";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// aos
import "aos/dist/aos.css";
import "animate.css";

const App = () => {
  useEffect(() => {
    // getNetworkingData(123);

    AOS.init({});
  }, []);

  return (
    <>
      <Router />
      <ToastContainer />
    </>
  );
};

export default App;

import { useEffect, useMemo } from "react";
import { useWebinarPageContext, WEBINAR_PAGE_ACTIONS } from "../..";
import WebinarManager from "../../../../Managers/webinar";
import { isMobileDevice } from "../../../../utils/getPlatformInfo";
import { Box, Shimmer } from "../../../../components/ShimmerEffect";
import Timeline from "../Timeline";
import { AGENDA } from "./agenda.data";
import styles from "./index.module.scss";

const fetchAgenda = async (webinarID, dispatch) => {
  let _data = await WebinarManager.AgendaManager._get.getWebinarAgenda(
    webinarID
  );
  console.log("-------Agenda Data--------");
  console.log(_data);
  console.log("---------------");
  dispatch({ type: WEBINAR_PAGE_ACTIONS.UPDATE_AGENDA, payload: _data });
};

function Agenda({ events = AGENDA }) {
  const { webinarID, state, dispatch } = useWebinarPageContext();
  useEffect(() => {
    if (!state.agenda && webinarID) {
      fetchAgenda(webinarID, dispatch);
    }
  }, [state.agenda]);

  const activeIndex = useMemo(() => {
    if (state.agenda && state.baseInfo) {
      let activeAgendaId = state.baseInfo.ongoingagendaid;
      if (activeAgendaId) {
        return state.agenda.order.indexOf(activeAgendaId);
      }
    }
    return -1;
  }, [state.agenda, state.baseInfo]);

  return (
    <div className={styles.agendaWrapper}>
      <Shimmer
        loading={!state.agenda}
        loadingComponent={
          <div
            style={{
              padding: "0 10%",
            }}
          >
            <div
              style={{
                display: "flex",
                marginBottom: 50,
              }}
            >
              <Box height={30} width={200} marginRight={100} />
              <Box height={30} width={200} marginRight={100} />
              <Box height={30} width={200} marginRight={100} />
            </div>
            {!isMobileDevice ? (
              <>
                <Box height={480} width={330} marginRight={24} />
                <Box height={480} width={330} />
              </>
            ) : (
              <Box height={200} width={500} />
            )}
          </div>
        }
        renderComponent={
          <Timeline
            events={state.agenda && state.agenda.mainData}
            activeAgendaIndex={activeIndex}
          />
        }
      />
    </div>
  );
}

export default Agenda;

//Imports
import { DETAILS_COLLECTION } from '../../AppConstants/CollectionConstants';

// Firebase
import firebase, { firestore } from '../../firebase/firebase';

const getDetailById = async (detailId) => {
	try {
		const docRef = firestore.collection(DETAILS_COLLECTION).doc(detailId);
		const _data = await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(docRef);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No details found'
				};
				throw err;
			}

			return doc.data();
		});
		return _data;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const getDetailByTargetId = async (targetId) => {
	try {
		const doc = await firestore.collection(DETAILS_COLLECTION).where('targetId', '==', targetId).get();

		if (doc.empty) {
			return;
		}

		return doc.docs[0].data();
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const get = {
	getDetailById,
	getDetailByTargetId
};

export default get;

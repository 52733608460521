//Imports
import { DETAILS_COLLECTION, SPEAKERS_COLLECTION } from '../../AppConstants/CollectionConstants';
import { ContentType } from '../../AppConstants/TypeConstants';
// Firebase
import firebase, { firestore } from '../../firebase/firebase';
const uniqid = require('uniqid');

const createDetail = async ({ targetId, content, type }) => {
	try {
		const detailId = uniqid('detail-');
		const docRef = firestore.collection(DETAILS_COLLECTION).doc(detailId);
		const details = {
			id: detailId,
			targetId,
			content,
			type: type || ContentType.VIDEO,
			timestamp: firebase.firestore.FieldValue.serverTimestamp()
		};

		await firestore.runTransaction(async (transcation) => {
			transcation.set(docRef, details);
		});
		return detailId;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const createSpeakerDetail = async ({ speakerId, content }) => {
	try {
		const detailId = uniqid('detail-');
		const docRef = firestore.collection(DETAILS_COLLECTION).doc(detailId);
		const speakerRef = firestore.collection(SPEAKERS_COLLECTION).doc(speakerId);
		const details = {
			id: detailId,
			targetId: speakerId,
			content,
			type: 'speaker',
			timestamp: firebase.firestore.FieldValue.serverTimestamp()
		};

		await firestore.runTransaction(async (t) => {
			const speakerDoc = await t.get(speakerRef);
			if (!speakerDoc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No speaker found'
				};
				throw err;
			}

			t.set(docRef, details);
			t.update(speakerRef, {
				detailId: detailId
			});
		});
		return detailId;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const post = {
	createDetail,
	createSpeakerDetail
};

export default post;

export const CloudFunctionName = {
  UPDATE_USER_STATUS: "updateUserStatus",
  USER_CREATION_DEV: "userCreationLocation", //userCreation
  USER_CREATION: "userCreation",
  USER_CREATION_EVENT: "userCreationEvent",
  GET_LOCATION_DATA: "getLocationData",
  GET_MUXDATA_ASSETID: "getMuxDataWithAssetId",
  WebinarRegister: "webinarRegister",
  CREATE_WEBINAR: "createWebinar",
  MAIL_SERVICE: "mailService",
  USER_EXISTS: "checkUserExists",
  REALTIME_METRICS: "getRealtimeWebinarMetrics",
  CREATE_LIVESTREAM: "createLivestream",
  USER_TOKEN_UPDATE: "userTokenUpdate",
  ADD_NOTIFICATION_TO_REALTIME_DB: "addNotificationToRealTimeDB",
  USER_PROFILE_UPDATE: "userUpdateLocation",
  VERIFY_OTP: "verifyOTPV3",
  SEND_OTP: "sendOTPV3",
};


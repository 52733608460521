import { useContext, useEffect, useReducer, useRef } from "react";
import { CloudFunctionName } from "../../../AppConstants/CloudFunctionName";
import { ExclusiveType } from "../../../AppConstants/TypeConstants";
import { AREA_OF_INTEREST } from "../../../AppData/AreaOfInterest";
import { Profession_DATA } from "../../../AppData/Profession";
import { SPECIALITY_DATA } from "../../../AppData/Speciality";
import closeBtn from "../../../assets/images/close.png";
import LoaderImg from "../../../assets/images/Loader.gif";
import { UserContext } from "../../../contexts/UserContext/UserContextProvider";
import { cloudFunction_as } from "../../../firebase/firebase";
import { showToast } from "../../../utils/common.utils";
import styles from "./EditProfile.module.scss";
let autoComplete;
const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const inputs = [
  {
    label: "First Name",
    name: "firstName",
    className: "form-control",
    type: "text",
    placeholder: "Enter First Name",
    required: true,
    disabled: false,
    readOnly: false,
  },
  {
    label: "Last Name",
    name: "lastName",
    className: "form-control",
    type: "text",
    placeholder: "Enter Last Name",
    required: true,
    disabled: false,
    readOnly: false,
  },

  {
    label: "Speciality",
    name: "speciality",
    className: "form-control",
    type: "select",
    placeholder: "Select your Speciality",
    data: SPECIALITY_DATA,
    required: true,
    disabled: false,
    readOnly: false,
  },
  {
    label: "Profession",
    name: "profession",
    className: "form-control",
    type: "select",
    placeholder: "Select your Profession",
    data: Profession_DATA,
    required: true,
    disabled: false,
    readOnly: false,
  },
  // {
  //   label: "Area Of Interest",
  //   name: "areaOfInterest",
  //   className: "form-control",
  //   type: "select",
  //   placeholder: "Select your Area Of Interest",
  //   data: AREA_OF_INTEREST,
  //   required: true,
  //   disabled: false,
  //   readOnly: false,
  // },
  {
    label: "City",
    name: "city",
    className: "form-control",
    type: "text",
    placeholder: "Enter city",
    required: true,
    disabled: false,
    readOnly: false,
  },
  {
    label: "Email",
    name: "email",
    className: "form-control",
    type: "text",
    placeholder: "Enter email",
    required: true,
    disabled: true,
    readOnly: true,
  },
  {
    label: "Phone Number",
    name: "phoneNumber",
    className: "form-control",
    type: "text",
    placeholder: "Enter phone number",
    required: true,
    disabled: true,
    readOnly: true,
  },
];

const defaultErr = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  speciality: "",
  profession: "",
  description: "",
  city: "",
};

const initialState = {
  isLoading: false,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  speciality: "",
  profession: "",
  locationResult: {
    state: "",
    city: "",
    country: "",
    pincode: null,
    location: {
      lat: 20.5937,
      lng: 78.9629,
    },
  },
  errors: {
    ...defaultErr,
  },
};

const Action_Type = {
  handleInputChange: "handleInputChange",
  handleInitialInput: "handleInitialInput",
  handleCityInput: "handleCityInput",
  handleError: "handleError",
  loading: "loading",
};

const reducer = (state, action) => {
  let { type, payload } = action;

  switch (type) {
    case Action_Type.handleInitialInput:
      return { ...state, ...payload };
    case Action_Type.handleInputChange:
      return { ...state, ...payload, [payload.name]: payload.value };
    case Action_Type.handleError:
      return { ...state, ...payload };
    case Action_Type.handleCityInput: {
      return {
        ...state,
        locationResult: {
          ...state.locationResult,
          [payload.name]: payload.value,
        },
      };
    }
    case Action_Type.loading:
      return { ...state, ...payload };
    default:
      return { ...state };
  }
};

const EditProfile = ({ handleEditProfile }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { userBasicDetails, user } = useContext(UserContext);
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    if (userBasicDetails) {
      let obj = {
        ...userBasicDetails,
      };
      if (userBasicDetails.city) {
        let locationResult = {
          city: userBasicDetails.city,
        };
        Object.assign(obj, { locationResult: locationResult });
      }
      dispatch({
        type: Action_Type.handleInitialInput,
        payload: obj,
      });
    }
  }, [userBasicDetails]);

  useEffect(() => {
    if (autoCompleteRef) {
      autoCompleteRef.current.addEventListener("focus", () => {
        // AIzaSyArOoD3H8IiCZNK87pZ9JH3WcQ1oyMqWbg  old
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=AIzaSyBd1dhvUFsSXLntV6og1jljFxEl1S_6oBA&libraries=places`,
          () => handleScriptLoad(autoCompleteRef)
        );
      });
    }
  }, [autoCompleteRef]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "city") {
      dispatch({
        type: Action_Type.handleCityInput,
        payload: { name: name, value },
      });
    } else {
      dispatch({
        type: Action_Type.handleInputChange,
        payload: { name, value },
      });
    }
  };

  const handleSubmit = async () => {
    try {
      dispatch({
        type: Action_Type.loading,
        payload: { isLoading: true, errors: defaultErr },
      });
      validateForm();
      if (!isValidForm(state.errors)) {
        dispatch({
          type: Action_Type.loading,
          payload: { isLoading: false },
        });
        return;
      }

      let obj = {
        userId: user.uid,
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        phoneNumber: state.phoneNumber,
        speciality: state.speciality,
        profession: state.profession,

        ...state.locationResult,
      };
      // if (state.speciality && state.profession && state.areaOfInterest) {
      //   obj.current_plan = ExclusiveType.PREMIUM;
      // }

      const cloudRef = cloudFunction_as.httpsCallable(
        CloudFunctionName.USER_PROFILE_UPDATE
      );
      await cloudRef(JSON.stringify(obj));

      showToast("Data updated successfully.");
      handleEditProfile(false);

      dispatch({
        type: Action_Type.loading,
        payload: { isLoading: false },
      });
    } catch (error) {
      let errors = state.errors;
      if (error.code) {
        errors.description = error.message;
      }
      showToast("Internal Server Error. Please try again later", {
        type: "error",
      });
      dispatch({
        type: Action_Type.loading,
        payload: { isLoading: false, errors: errors },
      });
    }
  };

  function handleScriptLoad(autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["(cities)"] }
    );
    // autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () => handlePlaceSelect());
  }

  async function handlePlaceSelect() {
    const addressObject = autoComplete.getPlace();
    const value = addressObject.formatted_address;

    let lat = addressObject.geometry?.location.lat();
    let lng = addressObject.geometry?.location.lng();
    if (!lat) {
      lat = 20.5937;
    }
    if (!lng) {
      lng = 78.9629;
    }

    // let address_components = res.data.results[0].address_components;
    let locationResult = {
      state: "",
      city: "",
      country: "",
      pincode: null,
      location: {
        lat,
        lng,
      },
    };

    addressObject.address_components.forEach((element) => {
      if (element.types.indexOf("locality") !== -1) {
        locationResult.city = element.long_name;
      } else if (element.types.indexOf("administrative_area_level_1") !== -1) {
        locationResult.state = element.long_name;
      } else if (element.types.indexOf("country") !== -1) {
        locationResult.country = element.long_name;
      } else if (element.types.indexOf("postal_code") !== -1) {
        locationResult.pincode = element.long_name;
      }
    });
    let obj = {
      // city: value,
      locationResult: locationResult,
    };

    if (!locationResult.city) {
      locationResult.city = locationResult.state;
    }
    dispatch({
      type: Action_Type.handleInitialInput,
      payload: obj,
    });
  }

  const validateForm = () => {
    let errors = state.errors;

    errors.firstName =
      state.firstName.trim().length > 0 ? "" : "Please enter first name.";
    errors.lastName =
      state.lastName.trim().length > 0 ? "" : "Please enter last name.";
    errors.email = state.email.trim().length > 0 ? "" : "Please enter email.";
    errors.phoneNumber =
      state.phoneNumber.trim().length > 0 ? "" : "Please enter phone number.";
    errors.speciality =
      state.speciality.trim().length > 0 ? "" : "Please select speciality.";
    errors.profession =
      state.profession.trim().length > 0 ? "" : "Please select profession.";
    // errors.areaOfInterest =
    //   state.areaOfInterest.trim().length > 0
    //     ? ""
    //     : "Please select Area Of Interest.";

    errors.city =
      state.locationResult["city"].trim().length > 0
        ? ""
        : "Please enter city.";

    dispatch({
      type: Action_Type.handleError,
      payload: { errors: { ...errors } },
    });
  };

  const isValidForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };
  return (
    <div className={styles.container}>
      <div className={styles.profileHeader}>
        <div className={styles.heading}>My Profile</div>
        <div
          className={styles.closeBtn}
          onClick={() => handleEditProfile(false)}
        >
          <img src={closeBtn} alt="close" />
        </div>
      </div>
      <div className={styles.inputsContainer} onSubmit={handleSubmit}>
        {inputs.map((input) =>
          input.type === "select" ? (
            <div key={input.name} className={styles.inputContainer}>
              <label htmlFor={input.name}>{input.label}</label>
              <select
                id={input.name}
                name={input.name}
                className={input.className}
                type={input.type}
                placeholder={input.placeholder}
                required={input.required}
                value={state[input.name]}
                onChange={(e) => handleInputChange(e)}
              >
                {input.data.map((val) => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </select>
            </div>
          ) : input.type === "text" ? (
            <div key={input.name} className={styles.inputContainer}>
              <label htmlFor={input.name}>{input.label}</label>
              <input
                id={input.name}
                name={input.name}
                className={input.className}
                type={input.type}
                placeholder={input.placeholder}
                value={
                  input.name === "city"
                    ? state.locationResult[input.name]
                    : state[input.name]
                }
                required={input.required}
                disabled={input.disabled}
                readOnly={input.readOnly}
                ref={input.name === "city" ? autoCompleteRef : null}
                onChange={(e) => handleInputChange(e)}
              />
              {state.errors[input.name] && (
                <div className="error">{state.errors[input.name]}</div>
              )}
            </div>
          ) : null
        )}
      </div>
      <div className="">
        <button
          onClick={handleSubmit}
          type="submit"
          className="btn"
          disabled={state.isLoading ? true : false}
        >
          {state.isLoading ? (
            <img src={LoaderImg} alt="loading..." height="100%" />
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
};

export default EditProfile;

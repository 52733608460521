//Imports
import { USER_MEDIA_METADATA_COLLECTION } from "../../../AppConstants/CollectionConstants";
import { ContentType } from "../../../AppConstants/TypeConstants";
// Firebase
import firebase, { firestore } from "../../../firebase/firebase";

const getUserMediaMetadata = async (userId, targetId = null) => {
  try {
    const docRef = firestore
      .collection(USER_MEDIA_METADATA_COLLECTION)
      .where("userId", "==", userId);
    if (targetId) docRef = docRef.where("targetId", "==", targetId);
    const query = await docRef.get();
    // console.log(query)

    if (query.empty) {
      return [];
    }

    return query.docs[0].data();
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const getUserAllMediaMetadata = async (userId) => {
  try {
    const docRef = firestore
      .collection(USER_MEDIA_METADATA_COLLECTION)
      .where("userId", "==", userId);

    const query = await docRef.get();
    let res = {};
    if (!query.empty) {
      query.docs.forEach((d) => {
        let _data = d.data();
        let _metaData = {
          targetId: _data.targetId,
          lastWatchTimestamp: _data.lastWatchTimestamp,
          totalDuration: _data.totalDuration,
          targetType: _data.targetType,
        };
        if (_data.targetType === ContentType.SERIES) {
          const getData = (key) => {
            if (!res.hasOwnProperty(key)) {
              res[key] = _metaData;
            } else if (
              new Date(_data.updatedTimestamp).getTime() >
              new Date(res[key].updatedTimestamp).getTime()
            ) {
              res[key] = _metaData;
            }
          };
          getData(_data.seriesId);
          getData(_data.seasonId);
        }
        res[_data.targetId] = _metaData;
      });
    }

    return res;
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const get = {
  getUserMediaMetadata,
  getUserAllMediaMetadata,
};

export default get;

import React, { useContext } from "react";
import { instituteValues, years } from "../../../../AppConstants/Constants";
import LoaderImg from "../../../../assets/images/Loader.gif";
import lockIconImg from "../../../../assets/images/lockIcon.png";

import { newAuthContext } from "../../../../contexts/AuthContext/NewAuthContextProvider";
import styles from "./ExclusiveForm.module.scss";

const ExclusiveForm = ({ old }) => {
  const { state, handleFormDetails, validateDoctor } =
    useContext(newAuthContext);

  const { errors, formDetails, loading } = state;
  const { institute, year, regId } = formDetails;

  const handleSubmit = () => {
    validateDoctor();
  };

  return (
    <div className={styles["personalDetail-form"]}>
      {/* {old && (
        <>
          <h1>Almost There</h1>
          <p>
            Hey {firstName}, please share a few more professional details about
            you to register on Docflix.
          </p>
        </>
      )} */}
      <div className={styles["profiledetails"]}>
        <div className={styles["input-group"]}>
          <label htmlFor="year">Year of registration</label>
          <select
            id="year"
            name="year"
            className="form-control"
            defaultValue="Select your year of registration"
            value={year}
            onChange={(e) => handleFormDetails({ year: e.target.value })}
            required
          >
            <option value="" hidden>
              Select your year of registration
            </option>
            {years.map((p) => (
              <option key={p} value={p}>
                {p}
              </option>
            ))}
          </select>
          {errors.year && <div className="error">{errors.year}</div>}
        </div>

        <div className={styles["input-group"]}>
          <label htmlFor="institute">State medical council</label>
          <select
            id="institute"
            name="institute"
            className="form-control"
            defaultValue="Select your state medical council"
            value={institute}
            onChange={(e) => {
              handleFormDetails({ institute: e.target.value });
            }}
            required
          >
            <option value="" hidden>
              Select your state medical council
            </option>
            {Object.keys(instituteValues).map((p) => (
              <option
                key={instituteValues[p].smcId}
                value={instituteValues[p].name}
              >
                {instituteValues[p].name}
              </option>
            ))}
          </select>
          {errors.institute && <div className="error">{errors.institute}</div>}
        </div>

        <div className={styles["input-group"]}>
          <label htmlFor="regId">Registration number</label>
          <input
            id="regId"
            name="regId"
            style={{ color: "#888888" }}
            className="form-control"
            onChange={(e) => handleFormDetails({ regId: e.target.value })}
            placeholder="Enter your registration number"
            value={regId}
          />
          {errors.regId && <div className="error">{errors.regId}</div>}
        </div>

        <div className={styles.premiumBtn}>
          <div>
            <img src={lockIconImg} alt="" />
            <div>
              Feel safe with us, your information is not shared with anyone. For
              more read our&nbsp;
              <span>Privacy Policy</span>
            </div>
          </div>
          <button
            onClick={handleSubmit}
            disabled={loading}
            className={`btn ${styles["register-btn"]}`}
            style={{ marginTop: "5px" }}
          >
            {loading ? <img src={LoaderImg} alt="" height="100%" /> : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExclusiveForm;

import { createContext, useContext, useRef, useState } from "react";
import WebinarManager from "../../Managers/webinar";
import ResourcesManager from "../../Managers/Resources";

import { Webinar_status } from "../../AppConstants/TypeConstants";
import { DESCENDING, TIMESTAMP } from "../../AppConstants/SortConstants";
import { useSpeakerContext } from "../SpeakerContext";

import { USER_FEEDBACK_COLLECTION } from "../../AppConstants/CollectionConstants";

// Firebase
import firebase, { database, firestore } from "../../firebase/firebase";
import { UserContext } from "../UserContext/UserContextProvider";
import { getSystemID } from "../../utils/getPlatformInfo";
import ScoreManager from "../../Managers/ScoringManager/post";
import { useEffect } from "react";
import { static_page_event_id } from "../../AppConstants/Constants";
const uniqid = require("uniqid");

const webinar_limit = 20;
export const webinarContext = createContext([]);

export default function WebinarContextProvider(props) {
  const { getMultipleSpeakersData } = useSpeakerContext();
  const { user } = useContext(UserContext);
  const [webinars, setAllWebinars] = useState({ data: [], lastDocRef: null });
  const [static_webinarVideoId, setStatic_webinarVideoId] = useState(null);

  const disconnectRef = useRef(null);
  useEffect(() => {
    if (static_page_event_id) {
      get_Static_video_Data();
    }
    return () => {
      WebinarManager._get.removeStaticWebinarVideoListener();
    };
  }, []);

  const get_Static_video_Data = async () => {
    WebinarManager._get.attachStaticWebinarVideoListener((data, err) => {
      if (err) {
        return;
      }
      if (data) {
        setStatic_webinarVideoId(data.videoID);
      }
    });
  };

  const getResources = async (resourcesArray) => {
    try {
      let resources = [];
      await resourcesArray.map(async (resourceId) => {
        let res = await ResourcesManager._get.getResourceById(resourceId);
        resources.push(res);
      });
      return resources;
    } catch (error) {
      console.log("Error :: ", error);
      throw error;
    }
  };

  const getAllWebinar = async (fetchParameters) => {
    try {
      let _data = [];
      let res = await WebinarManager._get.getSortedWebinars(
        [],
        TIMESTAMP,
        DESCENDING,
        null,
        webinar_limit,
        false
      );
      //   get all speakers
      _data = await Promise.all(
        res.data
          .filter((webinar) => webinar.status !== Webinar_status.DISABLED)
          .map(async (webinar) => {
            let speakersList = [];
            if (webinar.speakers.length !== 0) {
              speakersList = await getMultipleSpeakersData(webinar.speakers);
            }
            return {
              ...webinar,
              speakersList: speakersList,
            };
          })
      );
      let newObj = { data: _data, lastDocRef: res.lastDocRef };
      setAllWebinars(newObj);
    } catch (error) {}
  };

  const addWebinarTimespent = async (
    userID,
    webinarID,
    agendaID,
    status,
    nextAgendaItemTime,
    logout = false
  ) => {
    try {
      if (
        (!disconnectRef || !disconnectRef.current) &&
        status === Webinar_status.ENDED
      ) {
        return;
      }
      await WebinarManager._post.webinarTimeSpentAnalytics(
        userID,
        webinarID,
        agendaID,
        nextAgendaItemTime
      );

      await onDisconnectListener(userID, webinarID, agendaID, logout);
    } catch (error) {
      console.error(error);
    }
  };

  const onDisconnectListener = async (
    userID,
    webinarID,
    agendaID,
    logout = false
  ) => {
    try {
      if (!userID || !webinarID || !agendaID) {
        throw new Error({
          code: "UserIDWasNull",
          message: "Please try again later",
        });
      }

      if (logout) {
        if (disconnectRef.current) {
          await disconnectRef.current.cancel();
          disconnectRef.current = null;
        }
        return;
      } else {
        if (disconnectRef.current) {
          await disconnectRef.current.cancel();
          disconnectRef.current = null;
        }
      }
      let systemID = getSystemID();

      let obj = {
        webinarID: webinarID,
        agendaID: agendaID,
        lastChange: firebase.database.ServerValue.TIMESTAMP,
        userID: userID,
        systemID: systemID,
      };

      let databaseRef = database.ref(
        "analytics/" + webinarID + "/" + userID + "_" + systemID
      );

      disconnectRef.current = databaseRef.onDisconnect();
      await disconnectRef.current.set({
        state: "offline",
        ...obj,
      });

      return;
    } catch (error) {
      throw error;
    }
  };

  const createUserFeedback = async (webinarID, selectedResponses) => {
    try {
      if (!user || !webinarID) {
        return;
      }
      const feedbackId = uniqid("feedback-");

      const docId = `${webinarID}_${feedbackId}`;
      const docRef = await firestore
        .collection(USER_FEEDBACK_COLLECTION)
        .doc(docId);

      const feedback = {
        userId: user.uid,
        userName: user.displayName,
        eventId: webinarID,
        responses: [...selectedResponses],
        feedbackId: docId,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await firestore.runTransaction(async (transaction) => {
        transaction.set(docRef, feedback);
      });
      return feedbackId;
    } catch (error) {
      console.log("Error :: ", error);
      throw error;
    }
  };

  return (
    <webinarContext.Provider
      value={{
        webinars,
        static_webinarVideoId,
        getAllWebinar,
        createUserFeedback,
        getResources,
        addWebinarTimespent,
        onDisconnectListener,
      }}
    >
      {props.children}
    </webinarContext.Provider>
  );
}

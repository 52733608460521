import React, { useContext } from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import { newAuthContext } from "../../../../../contexts/AuthContext/NewAuthContextProvider";
import closeImg from "../../../../../assets/images/close2.png";
import styles from "./PremiumProgress.module.scss";

const filledColor = "#EB4548";
const unFilledColor = "#EB454896";

const PremiumProgress = () => {
  const { state, handleCloseAllForms } = useContext(newAuthContext);
  const {
    premiumFormModalStart,
    exclusiveFormModalStart,
    premiumFormModal,
    exclusiveFormModal,
    premiumFormModalComplete,
    exclusiveFormModalComplete,
  } = state;

  return (
    <div className={styles["progress"]}>
      <ProgressBar
        percent={
          premiumFormModalStart
            ? 0
            : premiumFormModal || premiumFormModalComplete
            ? 34
            : exclusiveFormModalStart
            ? 68
            : exclusiveFormModal || exclusiveFormModalComplete
            ? 102
            : 0
        }
        filledBackground={filledColor}
        unfilledBackground={unFilledColor}
        height={3}
      >
        <Step transition="scale">
          {({ accomplished }) => (
            <div className={styles["step"]}>
              <div
                className={`${styles["stepName"]} ${
                  accomplished && styles["stepNameVerified"]
                }`}
              >
                Premium
              </div>
              <div
                className={styles.dot}
                //   style={{
                //     backgroundColor: accomplished
                //       ? filledColor
                //       : unFilledColor,
                //   }}
              >
                {accomplished && <div className={styles.selectedDot}></div>}
              </div>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div className={styles["step"]}>
              <div
                className={styles["stepDash"]}
                style={{
                  backgroundColor: accomplished ? filledColor : unFilledColor,
                }}
              ></div>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div className={styles["step"]}>
              <div
                className={`${styles["stepName"]} ${
                  accomplished && styles["stepNameVerified"]
                }`}
              >
                Exclusive
              </div>
              <div
                className={styles.dot}
                //   style={{
                //     backgroundColor: accomplished
                //       ? filledColor
                //       : unFilledColor,
                //   }}
              >
                {accomplished && <div className={styles.selectedDot}></div>}
              </div>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div className={styles["step"]}>
              <div
                className={styles["stepDash"]}
                style={{
                  backgroundColor: accomplished ? filledColor : unFilledColor,
                }}
              ></div>
            </div>
          )}
        </Step>
      </ProgressBar>

      <div onClick={handleCloseAllForms} className={styles.closeBtn}>
        <img src={closeImg} alt="close" />
      </div>
    </div>
  );
};

export default PremiumProgress;

import { createContext, useContext, useEffect, useState } from "react";
import { ContentType } from "../../AppConstants/TypeConstants";
import VideoRatingManager from "../../Managers/Video/ratings";
import { UserContext } from "../UserContext/UserContextProvider";

export const RatingContext = createContext([]);

export default function RatingContextProvider(props) {
  const [data, setData] = useState({});
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      return;
    }
    const getRating = async () => {
      let __data = await VideoRatingManager._get.getRating(user.uid);
      setData(__data);
    };
    getRating();
  }, []);

  const giveRating = async (targetType, targetId, rating) => {
    try {
      if (!targetType || !targetId) {
        return;
      }
      if (!user) {
        return;
      }
      let _targetType =
        targetType === ContentType.SERIES ? ContentType.VIDEO : targetType;

      console.log(targetType, targetId);
      switch (_targetType) {
        case ContentType.VIDEO:
          if (data.hasOwnProperty(targetId)) {
            await VideoRatingManager._put.updateRating(
              targetId,
              user.uid,
              rating
            );
          } else {
            await VideoRatingManager._post.createRating(
              targetId,
              user.uid,
              rating
            );
          }
          break;
        case ContentType.AGENDA:
          if (data.hasOwnProperty(targetId)) {
            await VideoRatingManager._put.updateRating(
              targetId,
              user.uid,
              rating,
              ContentType.AGENDA
            );
          } else {
            await VideoRatingManager._post.createRating(
              targetId,
              user.uid,
              rating,
              ContentType.AGENDA
            );
          }
          break;
        case ContentType.SEASON:
          if (data.hasOwnProperty(targetId)) {
            await VideoRatingManager._put.updateRating(
              targetId,
              user.uid,
              rating,
              ContentType.SEASON
            );
          } else {
            await VideoRatingManager._post.createRating(
              targetId,
              user.uid,
              rating,
              ContentType.SEASON
            );
          }
          break;
        default:
          console.error("No targetType passed");
          break;
      }
      setData((prev) => {
        let i = { ...prev };
        i[targetId] = rating;
        return i;
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RatingContext.Provider value={{ ratingData: data, giveRating }}>
      {props.children}
    </RatingContext.Provider>
  );
}

import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { NAV_LINKS } from "./constants";
import right from "../../assets/images/right.png";
import userIcon from "../../assets/images/userIcon.png";
import userIconLight from "../../assets/images/userIconLight.png";
import brandLogo from "../../assets/images/brandLogo.png";
import menuIcon from "../../assets/images/menu.svg";
import exclusiveIcon from "../../assets/images/exclusiveicon.png";
import premiumIcon from "../../assets/images/premiumicon.png";
import Login from "../Login/Login.js";
import { isMobileDevice } from "../../utils/getPlatformInfo";
import { getActivePage, trimString } from "../../utils/common.utils";
import { useHistory } from "react-router";
import { useTheme } from "../../contexts/ThemeContext/ThemeContext";
import Signup from "../Signup/Signup";
import { UserContext } from "../../contexts/UserContext/UserContextProvider";
import { AuthManager } from "../../firebase/firebase";
import { ThemeSwitch } from "..";
import { Link } from "react-router-dom";
import { useScrollHandler } from "../../hooks/useScroll";
import { VideoPlayerContext } from "../../contexts/VideoPlayerContext/VideoPlayerContextProvider";
import { webinarContext } from "../../contexts/WebinarContext/WebinarContextProvider";
import { useWebinarPageContext } from "../../scenes/WebinarDetails";
import firebase from "../../firebase/firebase";
import {
  ExclusiveType,
  URLADMIN,
  Webinar_status,
} from "../../AppConstants/TypeConstants";
import useQuery from "../../utils/useQuery";
import { ASSETS_BASE_URL } from "../../AppConstants/Constants";

import closeBtn from "../../assets/images/close.png";
import { AnalyticsContext } from "../../contexts/Analytics/AnalyticsContextProvider";
import { SEARCH_BTN_CLICK } from "../../AppConstants/AnalyticsEventName";
import SearchModal from "./SearchModal";
import EditProfile from "./EditProfile/EditProfile";
import { scroller, ScrollLink } from "react-scroll";
import AnimatedHeader from "../AnimatedHeader";
import { newAuthContext } from "../../contexts/AuthContext/NewAuthContextProvider";
import JoinModal from "../../scenes/LandingPage/partials/JoinModal/JoinModal";

const routeNames = [
  {
    name: "Home",
    id: "home",
    url: `${ASSETS_BASE_URL}/images/dashboardHome.png`,
  },
  {
    name: `Videos`,
    id: "videos",
    url: `${ASSETS_BASE_URL}/images/dashboardVideos.png`,
  },
  {
    name: `Webinar`,
    id: "webinar",
    url: `${ASSETS_BASE_URL}/images/dashboardWebinar.png`,
  },
  {
    name: "Latest Shows",
    id: "latestShows",
    url: `${ASSETS_BASE_URL}/images/dashboardLatestShows.png`,
    offset: -100,
  },
  {
    name: "Recently Added",
    id: "recentlyAdded",
    url: `${ASSETS_BASE_URL}/images/dashboardRecentlyAdded.png`,
    offset: -200,
  },
  {
    name: `My Notes`,
    id: "notes",
    url: `${ASSETS_BASE_URL}/images/dashboardNotes.png`,
  },
];

const BadgeList = [
  {
    name: "badge1",
    img: `${ASSETS_BASE_URL}/images/badge1.png`,
  },
  {
    name: "badge2",
    img: `${ASSETS_BASE_URL}/images/badge2.png`,
  },
  {
    name: "badge3",
    img: `${ASSETS_BASE_URL}/images/badge3.png`,
  },
];

const routeIcons = {
  Home: `${ASSETS_BASE_URL}/images/dashboardHome.png`,
  LatestShows: `${ASSETS_BASE_URL}/images/dashboardLatestShows.png`,
  RecentlyAdded: `${ASSETS_BASE_URL}/images/dashboardRecentlyAdded.png`,
  Videos: `${ASSETS_BASE_URL}/images/dashboardVideos.png`,
  Webinar: `${ASSETS_BASE_URL}/images/dashboardWebinar.png`,
  MyNotes: `${ASSETS_BASE_URL}/images/dashboardNotes.png`,
};

const NavHeader = ({
  scrollValue,
  defaultBg,
  themeConstant = false,
  showNav = true,
  showBreadcrum = false,
  eventDetails = null,
}) => {
  const { addGAWithUserInfo } = useContext(AnalyticsContext);
  const { user, userBasicDetails, userMetadata } = useContext(UserContext);
  const { addWebinarTimespent } = useContext(webinarContext);
  const scrollValu = useScrollHandler(0);
  const { isFullScreen } = useContext(VideoPlayerContext);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const { state } = useWebinarPageContext();
  const query = useQuery();
  let admin = query.get("admin");

  const { toggleSignInbtn, state: newAuthState } = useContext(newAuthContext);
  const { showSignUpModal, showSignInModal, showJoinModal } = newAuthState;
  const [showUserDropDown, setShowUserDropDown] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const scrollValueUpdated = isMobileDevice ? scrollValue + 240 : scrollValue;
  let decrementFactor = 0;
  let paddingDecrementFactor = 0;

  const animationEnd = isMobileDevice
    ? scrollValueUpdated > 10
    : scrollValueUpdated > 120 && scrollValueUpdated > 10;

  const pathname = getActivePage();
  const { isDark } = useTheme();
  const history = useHistory();

  if (animationEnd) {
    decrementFactor = 100;
    paddingDecrementFactor = 60;
    defaultBg = "rgba(0,0,0,0.9)";
  } else {
    paddingDecrementFactor = scrollValueUpdated / 3;
    decrementFactor = scrollValueUpdated / 1;
  }

  const minHeight = isMobileDevice ? 75 : 100;

  useEffect(() => {
    if (showSearch) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.position = "unset";
    }
  }, [showSearch]);

  useEffect(() => {
    if (showSignUpModal || showSignInModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showSignUpModal, showSignInModal]);

  const handleSigninbtn = () => {
    setShowUserDropDown(false);
    toggleSignInbtn("showSignInModal", true);
  };

  const handleLogout = async () => {
    setShowUserDropDown(false);
    if (window.location.pathname.includes("/webinar/")) {
      if (!admin || admin !== URLADMIN.admin) {
        AuthManager.logout();
        return;
      }
      await logoutFromEventPage();
    }
    AuthManager.logout();
  };

  const scrollTarget = (target, offset) =>
    scroller.scrollTo(target, {
      smooth: true,
      offset: offset,
      duration: 700,
      delay: 100,
    });

  const scrollToPage = async (target, offset) => {
    setShowUserDropDown(false);
    if (history.location.pathname !== "/") {
      await history.push("/");
    }
    scrollTarget(target, offset);
  };

  const logoutFromEventPage = async () => {
    let nextAgendaItemTime = null;
    if (!state.baseInfo.ongoingagendaid) return;
    let index = state.agenda.order.indexOf(state.baseInfo.ongoingagendaid);

    // find next agenda time
    if (index >= 0 && index < state.agenda.order.length - 1) {
      let nextItemID = state.agenda.order[index + 1];
      nextAgendaItemTime = state.agenda.mainData.filter(
        (d) => d.id === nextItemID
      )[0].startTime;
    }
    // if last agenda add webinar end time
    if (!nextAgendaItemTime) {
      nextAgendaItemTime = state.baseInfo.endTime;
    }
    let firebaseTimeStamp = firebase.firestore.Timestamp.now();
    if (nextAgendaItemTime.seconds < firebaseTimeStamp.seconds) {
      const millis = (firebaseTimeStamp.seconds + 900) * 1000;
      nextAgendaItemTime = firebase.firestore.Timestamp.fromMillis(millis);
    }
    if (
      user &&
      state.baseInfo &&
      state.baseInfo.status === Webinar_status.ONGOING
    ) {
      await addWebinarTimespent(
        user.uid,
        state.baseInfo.id,
        state.baseInfo.ongoingagendaid,
        state.baseInfo.status,
        nextAgendaItemTime,
        true
      );
    }
  };

  const handleSearch = () => {
    addGAWithUserInfo(SEARCH_BTN_CLICK);
    setShowSearch(true);
  };

  const handleCloseSearch = () => {
    setShowSearch(false);
  };

  const handleRoute = (value) => {
    setShowUserDropDown(false);
    if (value === window.location.pathname) {
      scroller.scrollToTop();
    } else {
      if (value === "home") {
        return history.push("/");
      }
      return history.push(`/${value}`);
    }
  };

  const handleEditProfile = (bool) => {
    return setShowEditProfile(bool);
  };
  let fullscreenTopReached =
    scrollValu >= window.innerHeight - (59 + minHeight - decrementFactor);

  const topReached = scrollValue > (isMobileDevice ? 250 : 850);
  return (
    <>
      <div
        className={`${styles.wrapper} ${animationEnd ? styles.fixedHeader : null
          }`}
        style={{
          paddingTop: `${10 - paddingDecrementFactor}px`,
          paddingBottom:
            paddingDecrementFactor <= 0
              ? 0
              : `${10 - paddingDecrementFactor}px`,
          paddingLeft: `${90 - paddingDecrementFactor}px`,
          paddingRight: `${90 - paddingDecrementFactor}px`,
          minHeight: `${minHeight - decrementFactor}px`,
          backgroundColor: defaultBg,
          zIndex: isFullScreen && fullscreenTopReached && "10",
        }}
      >
        <div
          onClick={() => history.push("/")}
          className={styles.brandName}
          style={{
            color: themeConstant && "#fff",
          }}
        >
          <img src={brandLogo} alt="Docflix" />
          <span>Docflix</span>
          {showBreadcrum && (
            <div className={styles.breadcrum}>
              {/* {pathname.split("/")[1]}
              <img src={right} alt=">" /> */}
              {eventDetails && trimString(eventDetails.title, 200)}
            </div>
          )}
        </div>
        {!showBreadcrum && (
          <div className={styles.navSection}>
            <ul className={styles.navLinks}>
              {React.Children.toArray(
                NAV_LINKS.map((link) => {
                  return (
                    <Link
                      to={link.url}
                      className={
                        link.url === pathname ? styles.active : styles.link
                      }
                      style={{
                        color: themeConstant && "#fff",
                      }}
                    >
                      <li>{link.name}</li>
                    </Link>
                  );
                })
              )}
            </ul>
          </div>
        )}
        <div className={styles.userLinks}>
          {/* @TODO: Akash add theming for icon */}
          {/* <img
            src={
              themeConstant ? searchIcon : isDark ? searchIcon : searchLightIcon
            }
            alt="search"
            className={styles.searchIcon}
          /> */}
          {/* @TODO: Akash User Session linking */}
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleSearch}
            style={{ cursor: "pointer" }}
          >
            <path
              d="M8.21031 15.9379C12.1871 15.9379 15.4108 12.7142 15.4108 8.7374C15.4108 4.76066 12.1871 1.53687 8.21031 1.53687C4.23356 1.53687 1.00977 4.76066 1.00977 8.7374C1.00977 12.7142 4.23356 15.9379 8.21031 15.9379Z"
              stroke="#fff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.2107 17.7378L13.2954 13.8225"
              stroke="#fff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {showSearch && <SearchModal handleCloseSearch={handleCloseSearch} />}
          <div
            onClick={() => {
              setShowUserDropDown((prev) => !prev);
            }}
            className={styles.signInIcon}
          >
            {isMobileDevice ? null : (
              <>
                {/* {!user ? (
                  <span
                    style={{
                      color: themeConstant && "#fff",
                    }}
                  >
                    signin
                    <img
                      src={
                        themeConstant
                          ? userIcon
                          : isDark
                          ? userIcon
                          : userIconLight
                      }
                      alt="signin"
                    />
                  </span>
                ) : (
                  // <span
                  //   style={{
                  //     color: themeConstant && "#fff",
                  //     fontSize: "1rem",
                  //   }}
                  // >
                  //   {user.displayName}
                  // </span>
                  <>
                    {userBasicDetails && (
                      <>
                        <div
                          className={styles.namedProfileIcon}
                        >{`${userBasicDetails.firstName[0].toUpperCase()} ${userBasicDetails.lastName[0].toUpperCase()}`}</div>

                        <div
                          style={{
                            color: themeConstant && "#fff",
                            fontSize: "1rem",
                            marginLeft: "1rem",
                          }}
                        >
                          {`${userBasicDetails.firstName} ${userBasicDetails.lastName}`}
                        </div>
                      </>
                    )}
                  </>
                )} */}
              </>
            )}
          </div>
          {showUserDropDown && (
            <>
              <div
                onClick={() => setShowUserDropDown(false)}
                className={"modalOverlay"}
              />
              <div className={styles.drawerWrapper}>
                <div className={styles.header}>
                  {/* <div className={styles.logo}>
                    <img src={brandLogo} alt="Docflix" />
                    <span>Docflix</span>
                  </div> */}
                  {isDark ? "Dark Mode" : "Docflix Mode"}
                  <ThemeSwitch />
                  <img
                    onClick={() => setShowUserDropDown(false)}
                    src={closeBtn}
                    alt="close"
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className={styles.userProfile}>
                    <img
                      src={`${ASSETS_BASE_URL}/images/profile.png`}
                      alt="profile"
                    />
                    <div className={styles.userDesc}>
                      <div className={styles.username}>
                        {userBasicDetails
                          ? `Dr. ${userBasicDetails.firstName} ${userBasicDetails.lastName}`
                          : "Hi There!"}
                        {userMetadata &&
                          userMetadata.current_plan &&
                          userMetadata.current_plan !== ExclusiveType.FREE && (
                            <img
                              src={
                                userMetadata.current_plan ===
                                  ExclusiveType.PREMIUM
                                  ? premiumIcon
                                  : userMetadata.current_plan ===
                                    ExclusiveType.EXCLUSIVE
                                    ? exclusiveIcon
                                    : ""
                              }
                              alt={
                                userMetadata.current_plan ===
                                  ExclusiveType.PREMIUM
                                  ? ExclusiveType.PREMIUM
                                  : userMetadata.current_plan ===
                                    ExclusiveType.EXCLUSIVE
                                    ? exclusiveIcon
                                    : ""
                              }
                              style={{ height: "2rem", width: "2rem" }}
                            />
                          )}
                      </div>
                      <div className={styles.profile}>
                        {user && userBasicDetails
                          ? userBasicDetails.profession
                          : null}
                      </div>
                      {user && (
                        <button
                          className={`btn ${styles.editBtn}`}
                          onClick={() => handleEditProfile(true)}
                        >
                          Edit Profile
                        </button>
                      )}
                    </div>
                    <div style={{ position: "absolute", right: "-30%" }}>
                      <img
                        src={`${ASSETS_BASE_URL}/images/ProfileBanner.png`}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className={styles.routesSection}>
                    {routeNames.map((route) => {
                      if (route.id === "notes" && !user) {
                        return null;
                      }
                      if (
                        route.id === "latestShows" ||
                        route.id === "recentlyAdded"
                      ) {
                        return (
                          <div
                            key={route.id}
                            onClick={() => scrollToPage(route.id, route.offset)}
                            className={styles.routeBox}
                          >
                            <div className={styles.route}>
                              <img
                                className={styles.routeIcon}
                                src={route.url}
                                alt="route"
                              />
                              {route.name}
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div
                          key={route.id}
                          onClick={() => handleRoute(route.id)}
                          style={{
                            backgroundImage: `${window.location.pathname === `/${route.id}`
                                ? "linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) )"
                                : "none"
                              }`,
                            boxShadow: `${window.location.pathname === `/${route.id}`
                                ? "0px 6px 6px 2px rgb(0 0 0 / 58%)"
                                : "none"
                              }`,
                          }}
                          className={styles.routeBox}
                        >
                          <div className={styles.route}>
                            <img
                              className={styles.routeIcon}
                              src={route.url}
                              alt="route"
                            />
                            {route.name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {user && (
                  <div className={styles.badgesWrappper}>
                    <div className={styles.header}>Badges</div>
                    <div style={{ position: "absolute", right: "-30%" }}>
                      <img
                        src={`${ASSETS_BASE_URL}/images/BadgesBanner.png`}
                        alt=""
                      />
                    </div>
                    <div className={styles.badgesList}>
                      {BadgeList.map((badge) => {
                        return (
                          <div key={badge.name} className={styles.badge}>
                            <img src={badge.img} alt="badgeImg" />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                <div
                  onClick={user ? handleLogout : handleSigninbtn}
                  className={styles.loginBtn}
                >
                  {user ? "Log out" : "Login"}
                </div>
                {showEditProfile && (
                  <EditProfile handleEditProfile={handleEditProfile} />
                )}
              </div>
              {/* <div
                className={styles.userDropDown}
                style={{ display: showUserDropDown && "block" }}
              >
                {!user && (
                  <div
                    className={styles.userDropDownList}
                    onClick={handleSigninbtn}
                  >
                    Sign in / Register
                  </div>
                )}

                <div
                  className={styles.userDropDownList}
                  style={{ alignItems: "center" }}
                >
                  {isDark ? "Dark Mode" : "Docflix Mode"}
                  <ThemeSwitch />
                </div>
                {user && (
                  <div
                    className={styles.userDropDownList}
                    style={{ marginTop: "-0.7rem" }}
                    onClick={handleNoteButton}
                  >
                    Notes
                  </div>
                )}
                {user && (
                  <div
                    className={styles.userDropDownList}
                    onClick={handleLogout}
                  >
                    Logout
                  </div>
                )}
              </div> */}
            </>
          )}

          <div
            style={{ cursor: "pointer" }}
            className={styles.menuIcon}
            onClick={() => setShowUserDropDown((prev) => !prev)}
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 3.125H13.5C13.75 3.125 14 2.90625 14 2.625V1.375C14 1.125 13.75 0.875 13.5 0.875H0.5C0.21875 0.875 0 1.125 0 1.375V2.625C0 2.90625 0.21875 3.125 0.5 3.125ZM0.5 8.125H13.5C13.75 8.125 14 7.90625 14 7.625V6.375C14 6.125 13.75 5.875 13.5 5.875H0.5C0.21875 5.875 0 6.125 0 6.375V7.625C0 7.90625 0.21875 8.125 0.5 8.125ZM0.5 13.125H13.5C13.75 13.125 14 12.9062 14 12.625V11.375C14 11.125 13.75 10.875 13.5 10.875H0.5C0.21875 10.875 0 11.125 0 11.375V12.625C0 12.9062 0.21875 13.125 0.5 13.125Z"
                fill="#fff"
              />
            </svg>
          </div>
        </div>
      </div>
      {showSignInModal && <Login />}
      {showJoinModal && <JoinModal />}
      {/* {showSignInModal && <Login old />} */}
      {showSignUpModal && <Signup />}
    </>
  );
};

export default NavHeader;

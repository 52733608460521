import { useContext, useEffect, useReducer, useRef, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import styles from "./Login.module.scss";
import closebtnImg from "../../assets/images/close.png";
import clipBoardBtnImg from "../../assets/images/clipb.png";
import LoaderImg from "../../assets/images/Loader.gif";
import { newAuthContext } from "../../contexts/AuthContext/NewAuthContextProvider";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { webinarContext } from "../../contexts/WebinarContext/WebinarContextProvider";
import {
  static_page_event_id,
  whiteListCountries,
  CITY_DEBOUNCE,
  CITY_FETCH_MIN_CHAR,
} from "../../AppConstants/Constants";
import { AnalyticsContext } from "../../contexts/Analytics/AnalyticsContextProvider";
import { UserContext } from "../../contexts/UserContext/UserContextProvider";
import { cloudFunction_as } from "../../firebase/firebase";
import { firestore } from "../../firebase/firebase";
import OTPForm from "../Signup/partials/OTPForm/OTPForm";
import thankYouImg from "../../assets/images/thankyouImg.png";
const initialState = {
  isLoading: false,
  // firstName: "",
  // lastName: "",
  // email: "",
  // phoneNumber: "",
  isRegistered: false,
  isUserVerified: false,
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
  teamId: null,
};
const Action_Type = {
  handleInputChange: "handleInputChange",
  handleError: "handleError",
  loading: "loading",
  handleUTM: "handleUTM",
};

const reducer = (state, action) => {
  let { type, payload } = action;

  switch (type) {
    case Action_Type.handleInputChange:
      return { ...state, [payload.name]: payload.value };
    case Action_Type.handleError:
    case Action_Type.loading:
    case Action_Type.handleUTM:
    case Action_Type.updateCity:
      return { ...state, ...payload };

    default:
      return { ...state };
  }
};

const Login = ({ teamId, old }) => {
  const { pathname, search = "", hash = "" } = useLocation();
  const { webinarID } = useParams();

  const {
    toggleSignInbtn,
    handlePhoneNumber,
    handleLoginBtn,
    state,
    dispatchData,
    updateMultipleState,
    toggleSignUpBtn,
    handleLoading,
  } = useContext(newAuthContext);
  const { static_webinarVideoId } = useContext(webinarContext);
  const { addGANoUser } = useContext(AnalyticsContext);
  const { user, checkUserIsOTPVerifiedV2 } = useContext(UserContext);
  const { error, errors, formDetails, loading } = state;
  const { phoneNumber } = formDetails;
  const [returnURL, setReturnURL] = useState(null);
  let history = useHistory();

  useEffect(() => {
    let obj = {
      return: new URLSearchParams(search).get("return"),
    };
    setReturnURL(obj.return);
  }, [search]);

  const handleRegisterBtn = () => {
    let obj = {
      showJoinModal: true,
      showSignInModal: false,
    };

    updateMultipleState(obj);
  };

  const closeSignInModal = () => {
    toggleSignInbtn("showSignInModal", false);
    window.reload();
  };

  const webinarRegistration = (userId) => {
    const WebinarCloudRef = firestore
      .collection("webinarRegister")
      .doc(static_page_event_id)
      .collection("webinrRegisteredUsers")
      .doc(userId);
    WebinarCloudRef.get()
      .then(async (res) => {
        try {
          let data = {
            ...state,
            isLoading: false,
            errors: { defaultError: "" },
            isRegistered: true,
            isUserVerified: false,
          };
          if (!res.exists) {
            let verifiedUser = await checkUserIsOTPVerifiedV2(
              userId,
              window?.clientId?.toString().replaceAll(".", "")
            );
            if (!verifiedUser) {
              dispatchData(Action_Type.loading, {
                ...state,
                isLoading: false,
                errors: { defaultError: "" },
                isRegistered: true,
                isUserVerified: true,
              });
              return;
            }

            // have to register webinar cloud

            const WebinarCloudRegistration = cloudFunction_as.httpsCallable(
              "webinarRegisterExternal"
            );
            await WebinarCloudRegistration(
              JSON.stringify({
                eventId: static_page_event_id,
                mankind: {
                  teamId: teamId || null,
                  empID: state.utm_source || null,
                },
                utm_medium: state.utm_medium || null,
                utm_campaign: state.utm_campaign || null,
                isExternalEvent: true,
              })
            );
          } else {
            data.alreadyRegistered = true;
            data.isRegistered = false;
            data.isUserVerified = false;
            data.errors = `You have already been registered for this event.`;
          }
          dispatchData(Action_Type.loading, data);

          // have to show the thankyou page
        } catch (error) {
          console.log(error);
          dispatchData(Action_Type.loading, {
            ...state,
            isLoading: false,
            errors: { defaultError: "" },
            isRegistered: true,
            isUserVerified: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatchData(Action_Type.loading, {
          ...state,
          isLoading: false,
          errors: { defaultError: "" },
          isRegistered: true,
          isUserVerified: false,
        });
      });

    handleLoading(false);
  };

  const handleSignBtn = async (e) => {
    e.preventDefault();
    await handleLoginBtn(null, async (res) => {
      if (res) {
        addGANoUser(static_page_event_id, {
          teamId: teamId || null,
          utm_medium: state.utm_medium || null,
          utm_campaign: state.utm_campaign || null,
          ...res,
        });
      }
      if (res) {
        // check otp verified
        if (res.userId && window?.clientId) {
          webinarRegistration(res.userId);
        }
      }
    });
  };

  const handleThankYouClose = () => {
    dispatchData(Action_Type.loading, {
      ...state,
      isLoading: false,
      errors: { defaultError: "" },
      isRegistered: false,
      isUserVerified: false,
      showSignInModal: false,
    });
    window.location.reload();

    if (state.return) {
      window.open(state.return, "_self");
    }
  };

  return (
    <div className={`modalOverlayWithBlur`}>
      {!state.isRegistered && !state.isUserVerified ? (
        <div className={` ${styles["container"]}`}>
          <div className={styles["box"]}>
            <h1>Start Watching</h1>
            <form onSubmit={handleSignBtn} className={styles["signin-form"]}>
              <div className={styles["input-group"]}>
                <label htmlFor="phone">Mobile Number</label>
                <PhoneInput
                  defaultCountry={whiteListCountries[0]}
                  international
                  countries={whiteListCountries}
                  countryCallingCodeEditable={false}
                  // country="IN"
                  // international
                  withCountryCallingCode
                  className="form-control"
                  name="phoneNumber"
                  value={phoneNumber}
                  id="phone"
                  placeholder="Enter Mobile Number"
                  onChange={handlePhoneNumber}
                />
              </div>
              <div className={styles["terms"]}>
                By proceeding you agree to the <span>Terms of use</span> and{" "}
                <span>Privacy Policy</span>
              </div>
              {errors && <div className="error">{errors}</div>}
              <button
                disabled={loading}
                className={`btn ${styles["submit-btn"]}`}
              >
                {loading ? (
                  <img src={LoaderImg} alt="" height="100%" />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
            <div onClick={closeSignInModal} className={styles["closeBtn"]}>
              <img src={closebtnImg} alt="close" />
            </div>
          </div>
          <div className={styles["sinupbtn-container"]}>
            Don’t have an account?{" "}
            <span onClick={handleRegisterBtn}>Register now.</span>
          </div>
        </div>
      ) : state.isUserVerified ? (
        <div className={`${styles["container"]}`}>
          <div className={styles["box"]}>
            <OTPForm
              data={{ phoneNumber: phoneNumber }}
              verifyCB={webinarRegistration}
              showBack={false}
            />
          </div>
        </div>
      ) : (
        <div className={`${styles["container"]}`}>
          <div
            className={`${styles["box"]} ${styles["registeredContainer"]}`}
            style={{
              padding: "9rem 2rem",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <div
              className={`${styles["text1"]}`}
              style={{ fontSize: "1.5rem" }}
            >
              Dear Dr.{" "}
              <span style={{ textTransform: "capitalize" }}>
                {user?.displayName}
                {/* lgsdfuihsdiuf iusdoifi  */}
              </span>
              <br />
              <br />
              <span style={{ color: "#eb4547" }}>Thank you</span>
            </div>
            <div className={`${styles["textThankYou"]}`}>
              <img
                src={thankYouImg}
                alt="Thank You"
                height="100%"
                width="100%"
              />
            </div>
            <div onClick={handleThankYouClose} className={styles["closeBtn"]}>
              <img src={closebtnImg} alt="close" />
            </div>
          </div>
        </div>
      )}
    </div>

    // ) : (
    // <></>
    // <div className={`modalOverlayWithBlur ${styles["container"]}`}>
    //   <div className={`text-align-center ${styles["box"]}`}>
    //     <h1 className={styles["not-registered-heading"]}>Don’t be missed</h1>
    //     <div className={styles["signin-form"]} style={{ alignItems: "center" }}>
    //       <div className={styles["not-registered"]}>
    //         It seems that{" "}
    //         <sapn className={styles["not-registered-number"]}>
    //           {phoneNumber}
    //         </sapn>
    //         <br />
    //         is not registered with us.
    //       </div>
    //       <div className={styles["not-registered-img"]}>
    //         <img src={clipBoardBtnImg} alt="" />
    //       </div>
    //       <div>Would you like to register it with Docflix?</div>
    //       <div className={styles["formbtns"]}>
    //         <button
    //           onClick={handleRegisterBtn}
    //           className={`btn ${styles["submit-btn"]}`}
    //         >
    //           Register Now
    //         </button>
    //         <button
    //           className={`btn ${styles["notnow-btn"]}`}
    //           onClick={closeSignInModal}
    //         >
    //           Not Now
    //         </button>
    //       </div>
    //     </div>
    //     <div onClick={closeSignInModal} className={styles["closeBtn"]}>
    //       <img src={closebtnImg} alt="close" />
    //     </div>
    //   </div>
    // </div>
  );
};

export default Login;

import moment from "moment";
import { useContext, useEffect, useState } from "react";
import TabComponent from "../../TabsComponent";
import styles from "./styles.module.scss";
import NotesManager from "../../../Managers/Users/Notes";
import LoaderImg from "../../../assets/images/Loader.gif";
import trashImg from "../../../assets/images/trash.png";
import { UserContext } from "../../../contexts/UserContext/UserContextProvider";
import { scrollToBottom } from "../../../utils/common.utils";

const Notes = ({ allNotes, videoId, muxVideoRef, user }) => {
  const [noteText, setNoteText] = useState("");

  useEffect(() => {
    scrollToBottom("notes-Container");
  }, []);

  const handleInput = async (e) => {
    e.preventDefault();

    if (!muxVideoRef || !noteText.trim()) {
      return;
    }

    let pos = {
      targetId: videoId,
      noteContent: noteText,
      userId: user.uid,
      startTime: muxVideoRef.current.currentTime,
      endTime: muxVideoRef.current.currentTime,
    };
    await NotesManager._post.createNote(pos);
    scrollToBottom("notes-Container");
    setNoteText("");
  };

  const handleTimeFrameClick = (startTime) => {
    if (muxVideoRef) {
      muxVideoRef.current.currentTime = startTime;
      muxVideoRef.current.paused && muxVideoRef.current.play();
    }
  };

  const handleDeleteBtn = async (index) => {
    let obj = { targetId: videoId, userId: user.uid, index: index };

    await NotesManager._delete.deleteNote(obj);
  };

  return (
    <div className={styles.notesContainer}>
      <div id="notes-Container" className={styles.notesWrapper}>
        {!allNotes && (
          <div className={styles.loader}>
            <img src={LoaderImg} alt="loading..." />
          </div>
        )}
        {/* {allNotes &&
          allNotes.content && allNotes.content.length === 0 && (
            <div className={styles.noVisiblePoll}>
              As soon as a poll is posted <br></br>you will be able to express
              your opinion.
            </div>
          )} */}
        {allNotes &&
          allNotes.content &&
          allNotes.content.length !== 0 &&
          allNotes.content.map(({ id, note, startTime }, index) => {
            return (
              <div key={id} className={styles.noteBox}>
                <div
                  onClick={() => handleTimeFrameClick(startTime)}
                  className={styles.timeStamp}
                >
                  {startTime &&
                    moment("2015-01-01")
                      .startOf("day")
                      .seconds(startTime)
                      .format("H:mm:ss")}
                </div>
                <div>{note}</div>
                <div className={styles.actionBtns}>
                  <div
                    className={styles.deleteBtn}
                    onClick={() => handleDeleteBtn(index)}
                  >
                    <img src={trashImg} alt="Delete" />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <form className={styles.input} onSubmit={handleInput}>
        <input
          id="inputBox"
          type="text"
          placeholder="Add your note"
          value={noteText}
          onChange={(e) => {
            setNoteText(e.target.value);
          }}
          autoComplete="off"
        />
        <button disabled={!noteText.length}>Add</button>
      </form>
    </div>
  );
};

const Transcript = ({ videoId, muxVideoRef }) => {
  const TRANSCRIPTS = [
    {
      videoId: 1,
      timeStamp: "02:00",
      note: `If there's one constant in medical oncology, it's 
that new cancer therapies are hitting the market 
at a dizzying pace. The U.S. Food & Drug 
Administration (FDA) issued a total of 59 oncology 
drug approvals in 2020.`,
    },
    {
      videoId: 2,
      timeStamp: "02:15",
      note: `If there's one constant in medical oncology, it's 
that new cancer therapies are hitting the market 
at a dizzying pace. The U.S. Food & Drug 
Administration (FDA) issued a total of 59 oncology 
drug approvals in 2020.`,
    },
    {
      videoId: 1,
      timeStamp: "02:00",
      note: `The Fundamentals of Nutrition Science and Applications 
      in details you will learn about a lots of diseases,
      Medical conditions and disorders and their natural
       treatments,  so I will define each disorder, disease and 
      after that I will mention the signs and symptoms and the
      causative factors in order to be able to diagnose the 
      conditions and their natural treatments. so this diploma
       is really valuable and effective for people who works in
       the medical field such as Doctors, Nurses and pharmacist,
       or for  people who are interested to become a Naturopath
       or Doctors of alternative and complementary medicine,`,
    },
    {
      videoId: 2,
      timeStamp: "02:15",
      note: `The Fundamentals of Nutrition Science and Applications 
      in details you will learn about a lots of diseases,
      Medical conditions and disorders and their natural
       treatments,  so I will define each disorder, disease and 
      after that I will mention the signs and symptoms and the
      causative factors in order to be able to diagnose the 
      conditions and their natural treatments. so this diploma
       is really valuable and effective for people who works in
       the medical field such as Doctors, Nurses and pharmacist,
       or for  people who are interested to become a Naturopath
       or Doctors of alternative and complementary medicine,`,
    },
    {
      videoId: 1,
      timeStamp: "02:45",
      note: `If there's one constant in medical oncology, it's 
      that new cancer therapies are hitting the market 
      at a dizzying pace. The U.S. Food & Drug 
      Administration (FDA) issued a total of 59 oncology 
      drug approvals in 2020.`,
    },
  ];

  const videoTranscripts = TRANSCRIPTS.filter(
    (note) => note.videoId === videoId
  );

  return (
    <div className={styles.notesWrapper}>
      <div>
        {videoTranscripts.map((note, index) => {
          return (
            <div key={index} className={styles.transcriptBox}>
              <div className={styles.timeStamp}>{note.timeStamp}</div>
              <div>{note.note}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

function NewsAndTranscript({ allNotes, videoId, muxVideoRef }) {
  const { user } = useContext(UserContext);

  const TABS = [
    {
      label: "My Notes",
      childComponent: () => {
        return (
          <Notes
            allNotes={allNotes}
            videoId={videoId}
            muxVideoRef={muxVideoRef}
            user={user}
          />
        );
      },
      hideTab: false,
    },
    {
      label: "Transcript",
      childComponent: () => {
        return (
          <Transcript videoId={videoId} muxVideoRef={muxVideoRef} user={user} />
        );
      },
      hideTab: true,
    },
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <TabComponent
      tabs={TABS}
      onChangeTab={(index) => {
        setSelectedTab(index);
      }}
      activeTab={selectedTab}
      customHeaderClass={styles.customTabHeader}
      customTabClass={styles.customTabClass}
      customTabContent={styles.customTabContent}
    />
  );
}

export default NewsAndTranscript;

import { BACKSTAGE_COLLECTION } from "../../AppConstants/CollectionConstants";
import { ALLTAGS, SEARCH_PAGE } from "../../AppConstants/FirestoreDocumentConstant";
import { firestore } from "../../firebase/firebase";

const getTagsData = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const docRef = firestore.collection(BACKSTAGE_COLLECTION).doc(ALLTAGS);
            let doc = await docRef.get();
            if (doc.exists) {
                resolve(doc.data().data);
            } else {
                resolve(null);
            }
        } catch (error) {
            reject(error);
        }
    });
}

const getSpeakerPageData = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const docRef = firestore.collection(BACKSTAGE_COLLECTION).doc(SEARCH_PAGE);
            let doc = await docRef.get();
            if (doc.exists) {
                resolve(doc.data());
            } else {
                resolve(null);
            }
        } catch (error) {
            reject(error);
        }
    });
}

export const BackstageManager = {
    getTagsData,
    getSpeakerPageData
}
import React, { useEffect, useState } from "react";
import { OPTIONS } from "./Options";
import styles from "./Dropdown.module.scss";
import tickImg from "../../assets/images/tick.png";
import { useTheme } from "../../contexts/ThemeContext/ThemeContext";
import { isMobileDevice } from "../../utils/getPlatformInfo";

function MyDropdown({
  options = OPTIONS,
  selectedIndex = 0,
  onSelect = () => {},
  topReached = false,
  mappingKey = "label",
  customWrapperStyles = {},
  canGoInactive = false,
  isActive = true,
  defaultOpen = false,
  openTitle = "Select Option",
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { isDark } = useTheme();

  useEffect(() => {
    if (canGoInactive) {
      setIsOpen(isActive && defaultOpen ? true : false);
    }
  }, [canGoInactive, defaultOpen, isActive]);

  const labelColor =
    canGoInactive && isActive
      ? isDark
        ? "#ffffff"
        : "#2c4067"
      : isDark
      ? "#ffffff4d"
      : "#0A2347";

  const navHeight = 74;
  return (
    <div
      className={styles.dropdownWrapper}
      style={{
        // backgroundColor: topReached ? "#0A2347" : "transparent",
        ...customWrapperStyles,
        top: navHeight,
      }}
    >
      <div className={styles.dropdownContainer}>
        <div
          className={styles.activeCategory}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            style={{
              color: labelColor,
            }}
          >
            {isOpen ? openTitle : options[selectedIndex][mappingKey]}
          </div>
          <div
            className={styles.arrow}
            style={{
              transform: `rotate(45deg) translate(${
                !isOpen ? "0px, 5px" : "5px,10px"
              })`,
              borderBottom: `3px solid ${!isOpen ? labelColor : "transparent"}`,
              borderRight: `3px solid ${!isOpen ? labelColor : "transparent"}`,
              borderLeft: `3px solid ${isOpen ? labelColor : "transparent"}`,
              borderTop: `3px solid ${isOpen ? labelColor : "transparent"}`,
              transition: "all .2s ease-in",
            }}
          />
        </div>
        {isOpen ? (
          <ul className={styles.listItems} style={{}}>
            {options.map((option, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    onSelect(index);
                    setIsOpen(false);
                  }}
                  style={{
                    display: "flex",
                  }}
                >
                  <div className={styles.tickImg}>
                    {index === selectedIndex ? (
                      <img src={tickImg} alt="tick" />
                    ) : null}
                  </div>
                  <div>{option[mappingKey]}</div>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );
}

export default MyDropdown;

import { useState, useRef } from "react";
import styles from "./styles.module.scss";
import closeImg from "../../../assets/images/close.png";
import { isMobileDevice } from "../../../utils/getPlatformInfo";
import parse from "html-react-parser";
import { trimString } from "../../../utils/common.utils";


function SpeakersCards({ speakersList = [] }) {
  const speakerInfo = useRef(null);
  const [infoShown, setInfoShown] = useState(false);
  const [infoIndex, setInfoIndex] = useState(null);

  const hasDetails =
    infoIndex !== null
      ? speakersList[infoIndex].designation &&
        speakersList[infoIndex].designation.length
      : false;

  return (
    <div className={styles.speakersWrapper}>
      {speakersList.map((speaker, index) => {
        const {
          designation = [],
          profileLine = null,
          therapy = null,
          degree = null,
        } = speaker;
        return (
          <div
            className={styles.speakerAndInfo}
            key={index}
            // style={{
            //   marginBottom:
            //     infoShown && index === infoIndex
            //       ? isMobileDevice
            //         ? "17rem"
            //         : "15rem"
            //       : !isMobileDevice
            //       ? "15rem"
            //       : 0,
            // }}
          >
            <div
              className={styles.speakerCard}
              // data-aos="zoom-in"
              // data-aos-duration="200"
              // data-aos-delay={index * 100}
              // data-aos-easing="ease-in-sine"
              // data-aos-once="true"
              style={{
                backgroundColor: infoShown && index === infoIndex && "#eb4547",
              }}
            >
              <img
                src={speaker.photoUrl}
                alt={speaker.name}
                className={styles.speakerImg}
              />
              <div className={styles.speakerDetails}>
                <div className={styles.name}>{speaker.name}</div>
                <ul className={styles.infoList}>
                  
                  {profileLine && (
                    <li className={styles.infoItem}>{trimString(profileLine, 70)}</li>
                  )}
                  {/* {therapy && <li className={styles.infoItem}>{therapy}</li>} */}
                  {/* {degree && <li className={styles.infoItem}>{degree}</li>} */}
                </ul>
                <div
                  className={styles.viewInfoBtn}
                  style={{
                    visibility: designation.length ? "visible" : "hidden",
                  }}
                  onClick={() => {
                    if (index === infoIndex) {
                      setInfoShown(!infoShown);
                    } else {
                      setInfoShown(true);
                    }
                    setInfoIndex(index);
                    setTimeout(() => {
                      const element = document.getElementById("speakerInfo");
                      if (element) {
                        element.scrollIntoView({
                          behavior: "smooth",
                          block: "end",
                        });
                      }
                    }, 500);
                  }}
                >
                  <div
                    className={styles.arrow}
                    style={{
                      transform: `rotate(${
                        infoShown && index === infoIndex ? "45deg" : "225deg"
                      })`,
                      transition: "all .2s ease-in",
                    }}
                  />
                </div>
              </div>
              <div
                className={styles.mobArrow}
                onClick={() => {
                  if (index === infoIndex) {
                    setInfoShown(!infoShown);
                  } else {
                    setInfoShown(true);
                  }
                  setInfoIndex(index);
                  setTimeout(() => {
                    const element = document.getElementById("speakerInfo");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                      });
                    }
                  }, 500);
                }}
              >
                <div
                  className={styles.arrow}
                  style={{
                    transform: `rotate(${
                      infoShown && index === infoIndex ? "45deg" : "225deg"
                    })`,
                    visibility: designation.length ? "visible" : "hidden",
                    transition: "all .2s ease-in",
                  }}
                />
              </div>
            </div>
            {hasDetails && infoShown && index === infoIndex ? (
              <div
                ref={speakerInfo}
                id="speakerInfo"
                className={styles.speakerInfo}
                style={{
                  transform: `translateX(${
                    isMobileDevice ? (0, 0) : `${-10 - 20 * (index % 4)}`
                  }%)`,
                }}
              >
                <div className={styles.header}>
                  <div>About</div>
                  <img
                    src={closeImg}
                    alt="close"
                    className={styles.closeIcon}
                    onClick={() => setInfoShown(false)}
                  />
                </div>
                <ul className={styles.detailPoints}>
                  {/* {speakersList[infoIndex].details.map((detail, index) => {
                    return (
                      <li
                        key={index}
                        className="animate__animated animate__fadeIn"
                      >
                        {detail}
                      </li>
                    );
                  })} */}
                  {parse(speaker.details)}
                  {/* {therapy && (
                    <li
                      key={"therapy"}
                      className="animate__animated animate__fadeIn"
                    >
                      {therapy}
                    </li>
                  )}
                  <li
                    key={"designation"}
                    className="animate__animated animate__fadeIn"
                  >
                    {designation}
                  </li> */}
                </ul>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

export default SpeakersCards;

import { createContext, useContext, useEffect, useState } from "react";

export const ThemeContext = createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
  const [isDark, setDark] = useState(true);
  function switchTheme(switchTheme) {
    setDark(switchTheme);
    if (switchTheme) {
      document.documentElement.setAttribute("data-theme", "dark");
      window.localStorage.setItem("themeSelected", "dark");
    } else {
      window.localStorage.setItem("themeSelected", "light");
      document.documentElement.setAttribute("data-theme", "light");
    }
  }

  useEffect(() => {
    const themeStored = window.localStorage.getItem("themeSelected");
    if (themeStored) {
      setDark(themeStored === "dark");
      document.documentElement.setAttribute("data-theme", themeStored);
    } else {
      switchTheme(true);
    }
  }, []);

  const value = {
    isDark,
    switchTheme,
  };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}

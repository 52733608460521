import { useState } from "react";
import { useTheme } from "../../contexts/ThemeContext/ThemeContext";
import closeImg from "../../assets/images/close.png";
import styles from "./index.module.scss";
import { isMobileDevice } from "../../utils/getPlatformInfo";

function TabComponent({
  overflow,
  tabs = [],
  onChangeTab = () => {},
  activeTab = 0,
  tabFontSize = "1.5rem",
  customHeaderClass = {},
  customTabClass = {},
  customTabsWrapper = {},
  customTabContent = {},
  closeComponent = false,
}) {
  const [currentTabIndex, setCurrentTabIndex] = useState(activeTab);
  const { isDark } = useTheme();
  return (
    <div
      style={{ overflow: `${overflow && "visible"}` }}
      className={`${styles.tabsWrapper} ${customTabsWrapper}`}
    >
      <div
        style={{
          justifyContent: `${overflow && isMobileDevice && "space-evenly"}`,
        }}
        className={`${styles.headerTabs} ${customHeaderClass}`}
      >
        {tabs.map((tab, index) => {
          const {
            labelComponent = () => {},
            showLabelComponent = false,
            hideTab = false,
          } = tab;
          const isActive = index === currentTabIndex;
          if (hideTab) {
            return null;
          }
          return (
            <div
              id={tab.label.toLowerCase()}
              className={`${styles.tab} ${
                isActive ? styles.activeTab : null
              } ${customTabClass}`}
              style={{
                color: isActive
                  ? isDark
                    ? "#ffffff"
                    : "#fff"
                  : isDark
                  ? "#ffffff4d"
                  : "#ffffff4d",
                fontSize: tabFontSize,
                lineHeight: "3rem",
              }}
              key={index}
              onClick={() => {
                onChangeTab(index);
                setCurrentTabIndex(index);
              }}
            >
              {labelComponent && showLabelComponent
                ? labelComponent()
                : tab.label}
            </div>
          );
        })}
        {closeComponent && (
          <div
            className={styles.closeBtn}
            onClick={() => closeComponent(false)}
          >
            <img src={closeImg} alt="" />
          </div>
        )}
      </div>
      <div className={`${styles.tabContent} ${customTabContent}`}>
        {!tabs[currentTabIndex].hideTab &&
          tabs[currentTabIndex].childComponent()}
      </div>
    </div>
  );
}

export default TabComponent;

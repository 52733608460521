import { createRef, useEffect } from "react";
import style from "./index.module.css";

export default function AddToCalendarDropDown({ children }) {
  const dropdownStyles = createRef(null);
  useEffect(() => {
    let dropdownStylesCurrentRef = null;
    if (dropdownStyles.current) {
      dropdownStylesCurrentRef = dropdownStyles.current;
      dropdownStylesCurrentRef.childNodes.forEach((node) => {
        node.addEventListener("click", handleClick);
      });
    }
    return () => {
      if (dropdownStylesCurrentRef) {
        dropdownStylesCurrentRef.childNodes.forEach((node) => {
          node.removeEventListener("click", handleClick);
        });
      }
    };
  }, [dropdownStyles]);

  const handleClick = (e) => {
    if (e.target.innerHTML && window.AddToCalendarAnalytic) {
      window.AddToCalendarAnalytic(e.target.innerHTML);
    }
  };

  return (
    <div className={style.dropdownStyles} ref={dropdownStyles}>
      {children}
    </div>
  );
}

//Imports
import {
  VIDEO_COLLECTION,
  LIKES_COLLECTION,
  SPEAKERS_COLLECTION,
  TRANSCIPT_COLLECTION,
  BACKSTAGE_COLLECTION,
  RECENTLY_ADDED_DOCUMENT,
} from "../../AppConstants/CollectionConstants";
import { DESCENDING, TIMESTAMP } from "../../AppConstants/SortConstants";
import { VIDEO_FETCH_LIMIT } from "../../AppConstants/Constants";
import { SourceType, VideoType } from "../../AppConstants/TypeConstants";

// Firebase
import firebase, { firestore } from "../../firebase/firebase";
const uniqid = require("uniqid");

const getVideos = async (limit = VIDEO_FETCH_LIMIT) => {
  try {
    let docRef = firestore.collection(VIDEO_COLLECTION).limit(limit);
    const query = await docRef.get();
    // console.log(query)

    if (query.empty) {
      return [];
    }

    let _data = [];
    query.docs.forEach((doc) => {
      _data.push(doc.data());
    });

    return _data;
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const getVideoById = async (videoId) => {
  try {
    const ref = firestore.collection(VIDEO_COLLECTION).doc(videoId);
    const _data = await firestore.runTransaction(async (transcation) => {
      const doc = await transcation.get(ref);
      if (!doc.exists) {
        const error = {
          code: "NotValidId",
          message: "No VideoId Found",
        };
        throw error;
      }
      let _data = doc.data();
      if (_data.transcriptId) {
        try {
          const transcriptRef = firestore
            .collection(TRANSCIPT_COLLECTION)
            .doc(_data.transcriptId);
          const doc = await transcation.get(transcriptRef);
          if (!doc.exists) {
            const error = {
              code: "NotValidId",
              message: "No TranscodeId Found",
            };
            throw error;
          }
          const transciptData = doc.data();
          return { transcript: transciptData, ..._data };
        } catch (error) {
          throw new Error("Error fetching Transcode data");
        }
      } else {
        return _data;
      }
    });
    return _data;
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const getSortedVideos = async (
  tags = [],
  based_on = TIMESTAMP,
  order = DESCENDING,
  lastDocRef = null,
  limit = VIDEO_FETCH_LIMIT,
  ignoreLimit = false
) => {
  try {
    let docRef = firestore.collection(VIDEO_COLLECTION);
    if (tags && tags.length) {
      tags = tags.map((t) => t.toLowerCase()); // tags to lowercase
      docRef = docRef.where("tags", "array-contains-any", tags);
    }

    docRef = docRef.orderBy(based_on, order);

    if (!ignoreLimit) docRef = docRef.limit(limit);

    // Pagination
    if (lastDocRef) docRef = docRef.startAfter(lastDocRef);
    const query = await docRef.get();

    // console.log(query)

    if (query.empty) {
      return [];
    }

    // Updating last docRef
    lastDocRef = query.docs[query.docs.length - 1];

    let _data = [];
    query.docs.forEach((doc) => {
      _data.push(doc.data());
    });
    return { data: _data, lastDocRef };
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const getRecentlyAddedVideos = async () => {
  try {
    let docRef = firestore
      .collection(BACKSTAGE_COLLECTION)
      .doc(RECENTLY_ADDED_DOCUMENT);
    const query = await docRef.get();
    if (!query.exists) {
      return null;
    }
    let _data = query.data().data;
    let newArr = Object.values(_data);

    return newArr;
  } catch (err) {
    console.error(err);
  }
};
const getVideosBySpeakerId = async (
  speakers = [],
  based_on = TIMESTAMP,
  order = DESCENDING,
  lastDocRef = null,
  limit = VIDEO_FETCH_LIMIT,
  ignoreLimit = false
) => {
  try {
    let docRef = firestore.collection(VIDEO_COLLECTION);
    if (speakers && speakers.length) {
      docRef = docRef.where("speakers", "array-contains-any", speakers);
    }

    docRef = docRef.orderBy(based_on, order);

    if (!ignoreLimit) docRef = docRef.limit(limit);

    // Pagination
    if (lastDocRef) docRef = docRef.startAfter(lastDocRef);
    const query = await docRef.get();

    // console.log(query)

    if (query.empty) {
      return [];
    }

    // Updating last docRef
    lastDocRef = query.docs[query.docs.length - 1];

    let _data = [];
    query.docs.forEach((doc) => {
      _data.push(doc.data());
    });
    return { data: _data, lastDocRef };
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};
const get = {
  getVideos,
  getVideoById,
  getSortedVideos,
  getVideosBySpeakerId,
  getRecentlyAddedVideos,
};

export default get;

import { BannerType } from "../../../AppConstants/TypeConstants";
import parse from "html-react-parser";
import styles from "./styles.module.scss";

function DetailsTab({ detailsData = [] }) {
  return (
    <div className={styles.detailsWrapper}>
      {/* <h1>{`About the ${
        detailsData.type === BannerType.VIDEO ? `video` : `series`
      }`}</h1> */}
      <div
        className={styles.details}
        data-aos="fade-in"
        data-aos-duration="400"
        data-aos-delay={100}
        data-aos-easing="ease-in-sine"
        data-aos-once="true"
      >
        <div>
          <p>{parse(detailsData.content)}</p>
        </div>
      </div>
      {/* {detailsData.map((detail, index) => {
        const { content = [], heading = "" } = detail;
        return (
          <div
            className={styles.details}
            key={index}
            data-aos="fade-in"
            data-aos-duration="400"
            data-aos-delay={100}
            data-aos-easing="ease-in-sine"
            data-aos-once="true"
          >
            {!!heading ? <h2 className={styles.heading}>{heading}</h2> : null}
            {content.map((entry, key) => {
              const { paragraph = "", points = [] } = entry;
              return (
                <div key={key}>
                  <p>{paragraph}</p>
                  <ul>
                    {points.map((point, listIndex) => {
                      return <li key={listIndex}>{point}</li>;
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        );
      })} */}
    </div>
  );
}

export default DetailsTab;
